import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default class Model {
    object = null;
    data = null;

    constructor(onModelLoaded = null, data = null) {
        this.onModelLoaded = onModelLoaded;
        this.data = data;
    }

	load = (url, scale = 1, position = null, rotation = null, material = null) => {
        const ts = Date.now();

        const isFbx = (url.toLowerCase().indexOf(".fbx") > 0);
		const loader = isFbx ? new FBXLoader() : new GLTFLoader();

        loader.load(url, (object) => {
            // console.log(url, isFbx, object)
            if(!isFbx)
                object = object.scene;

            object.scale.set(scale,scale,scale);

            if(position)
                object.position.copy(position);

            if(rotation) {
                object.rotation.x = rotation.x;
                object.rotation.y = rotation.y;
                object.rotation.z = rotation.z;
            }
            
            // object.castShadow = true;
            // object.receiveShadow = true;

			object.traverse(function (child) {
				if (child.isMesh) {
					child.castShadow = true;
					child.receiveShadow = false;
                    if(material)
                        child.material = material;
    
                    if(child.material && child.material.specular) {
                        //console.log(child.material)
                        //child.material.shininess = 1;
                        const s = child.material.specular;
                        const f = 15;
                        child.material.specular = { r: s.r/f, g: s.g/f, b: s.b/f };
                    }
				}
			});

            this.object = object;

            console.log(Date.now() - ts + "ms to load " + url);

            if(this.onModelLoaded)
                this.onModelLoaded(this);
        }, function (e) {
            // console.log(e);
            // Progressing
        }, function (e) {
            console.log(e);
        });
  	}
}