import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ImageIcon from '@material-ui/icons/Image';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import AppContext from "app/AppContext";

export default class Legend extends React.Component {

    constructor(props) {
        super(props);

        this.categorizedList = []; // The JSX list

        let standsPerCategory = [];

        for(let stand of props.stands) {
            if(stand.category) {
                let categoryBlock = standsPerCategory.find(x => x.category.id === stand.category.id);

                if(!categoryBlock) {
                    standsPerCategory.push({
                        stands: [],
                        category: stand.category
                    });
                }

                const categoryBlockIndex = standsPerCategory.findIndex(x => x.category.id === stand.category.id);

                standsPerCategory[categoryBlockIndex].stands.push(stand);
            }
        }

        standsPerCategory = standsPerCategory.sort(function(a, b) { return a.category.sort_index - b.category.sort_index });

        for(var block of standsPerCategory) {
            this.categorizedList.push(this.ul(block));
        }
    }

    ul = (value) => {
        let categoryStands = value.stands.filter(stand => stand.active);

        if(value.category && value.category.sort_alphabetically)
            categoryStands = categoryStands.sort(function(a, b) { return a.name.localeCompare(b.name); });

        return (
            <>
                <ListSubheader>{value.category.title}</ListSubheader>

                <ul>
                    {categoryStands.map(stand => 
                        <ListItem button key={"Stand-"+stand.id} className="list-item"
                            onClick={() => this.onClick(stand)}
                            onMouseOver={() => this.onOver(stand.id)} onMouseLeave={() => this.onOver(null)}>
                            <ListItemIcon>
                                {stand.image ? 
                                    <div className="list-item-icon"
                                        style={{backgroundImage: "url("+AppContext.s["api-url"] + "/stores/" + stand.id + "/thumb"+")"}}>
                                        {/* <img src={AppContext.s["api-url"] + "/stores/" + stand.id + "/thumb"} alt={stand.name} /> */}
                                    </div> : <ImageIcon /> }
                            </ListItemIcon>
                            <ListItemText primary={stand.name} />
                        </ListItem>)}
                </ul>

                <Divider />
            </>
        );
    }

    onClick = (stand) => {
        if(this.props.onClick)
            this.props.onClick(stand);
    }

    hlStand = null;

    onOver = (i) => {
        if(i !== this.hlStand) {
            this.hlStand = i;
            //console.log(i);
            if(this.props.onOver)
                this.props.onOver(i);
        }
    }

    render () {
        return (
            <>
                { (window.location.pathname !== "/") &&
                    <Button className="back-to-expo" startIcon={<ArrowBackIosIcon />}
                        onClick={() => this.onClick(null)}>Back to Expo</Button> }

                <List className="legend" component="nav"
                    aria-labelledby="nested-list-subheader">
                    {this.categorizedList}
                </List>
            </>
        );
    }
}