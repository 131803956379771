import Axios from 'axios';

import AppContext from '../app/AppContext'
import CoreApi from './Core';

export default class WoolSafe {
    static async Stands() {
        return await Axios.get(AppContext.s["api-url"] + "/stores")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async Stand(id) {
        return await Axios.get(AppContext.s["api-url"] + "/stores/" + id)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }
    
    static async Products(standId) {
        return await Axios.get(AppContext.s["api-url"] + "/stores/" + standId + "/products")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async Stand(id) {
        return await Axios.get(AppContext.s["api-url"] + "/stores/" + id)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async ShopBySubDomain(subdomain) {
        return await Axios.get(AppContext.s["api-url"] + "/stores/bysubdomain/"+subdomain)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async Events(storeId, upcoming = 0, typeId = null) {
        const url = AppContext.s["api-url"] + "/events?storeId=" + storeId + "&upcoming=" + upcoming
            + (typeId ? "&typeId="+typeId : "");

        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async Event(id) {
        return await Axios.get(AppContext.s["api-url"] + "/events/" + id)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async MyTickets(upcoming = 0) {
        return await Axios.get(AppContext.s["api-url"] + "/mytickets?upcoming="+upcoming)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async AttendEvent(event, status_id) {
        const request = {
            status_id: status_id
        };

        return await Axios.post(AppContext.s["api-url"] + "/events/" + event.id + "/attend", request)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async ClaimFreeTicket(attendance, code) {
        const request = {
            code: code
        };

        return await Axios.post(AppContext.s["api-url"] + "/event/attendances/"+attendance.id+"/claim", request)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }

    static async ApplyDiscountCode(attendance, code) {
        const request = {
            code: code
        };

        return await Axios.post(AppContext.s["api-url"] + "/event/attendances/"+attendance.id+"/voucher", request)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }
}