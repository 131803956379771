import React from 'react';
import { Modal, Button, Container, Form, Col, Row } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';

import AppContext from 'app/AppContext';
import Core from 'api/Core';
import RemoteSelect from './RemoteSelect';

export default class Contacts extends React.Component {
    state = {
        contacts: null,
        showInsertForm: false,
        showFormOverlay: false,
        showModal: false,
        contactId: null
    };

    getSelectedId () {
        return this.state.contactId;
    }

    async componentDidMount() {
        await this.fetchContacts();

        // this.countryOptions = await Core.FetchCountryOptions();
    }

    fetchContacts = async () => {
        return "";
        
        // Try to autologin
        if(AppContext.Auth.user === null)
            await AppContext.Auth.autologin();

        const response = await Core.Contacts();
        // console.log(response);

        const contacts = (response && response.data) ? response.data.data : null;

        this.setState({
            contacts: contacts,
            contactId: (contacts && contacts.length > 0 && this.state.contactId === null) ? contacts[0].id : this.state.contactId
        });
    }

    onInsertNewAddress = () => {
        this.setState({
            showInsertForm: true
        });

        if(this.props.onInsertNewAddress)
            this.props.onInsertNewAddress();
    }

    onClose = () => {
        this.setState({
            showInsertForm: false
        });

        if(this.props.onNewAddressCancelled)
            this.props.onNewAddressCancelled();
    }

    delete = async (id) => {
        const wasSelected = this.state.contactId === id;

        this.setState({
            showFormOverlay: true,
            showModal: false
        });

        await Axios.delete(AppContext.s['api-url'] +  "/contacts/" + id, { headers: { Authorization: AppContext.Auth.bearer } });

        this.fetchContacts();

        this.setState({
            showFormOverlay: false,
            contactId: wasSelected ? null : this.state.contactId
        });
    }

    onDelete = async (id) => {
        // Ask for confirmation
        this.modalView = (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>{AppContext.r["confirm-delete-address-heading"]}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{AppContext.r["confirm-delete-address"]}</Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onCloseModal}>
                        {AppContext.r["cancel"]}
                    </Button>
                    <Button variant="danger" onClick={() => this.delete(id)}>
                        {AppContext.r["delete"]}
                    </Button>
                </Modal.Footer>
            </div>
        );

        this.setState({
            showModal: true
        });
    }

    onCloseModal = () => {
        this.setState({
            showModal: false,
        });
    }
    
    modal = () => {
        const overlayStyle = (this.state.modalBusy || this.state.modalMessage != null) ? { display: "block" } : { display: "none" };

        return (
            <Modal show={this.state.showModal} onHide={this.onCloseModal} center>
                {this.modalView}

                <div className="modal-overlay" style={overlayStyle}>
                    <div style={this.state.modalBusy === true ? { display: "block" } : { display: "none" }}>{AppContext.r['preloader']}</div>
                    <div className="centered">
                        {this.state.modalMessage}
                    </div>
                </div>
            </Modal>);
    }

    storeContact = async (fields) => {
        this.setState({
            showFormOverlay: true
        });

        await Axios.post(AppContext.s['api-url'] + "/contacts", fields, { headers: { Authorization: AppContext.Auth.bearer } });

        await this.fetchContacts();
        
        const { contacts } = this.state;

        this.setState({
            showFormOverlay: false,
            showInsertForm: false,
            contactId: (contacts && contacts.length > 0) ? contacts[0].id : this.state.contactId
        });
        
        if(this.props.onNewAddressInserted)
            this.props.onNewAddressInserted();
    }

    formatAddress(c) {
        let a = c.address1;
        if(c.address2 && c.address2.length > 0) a += ", " + c.address2;
        a += " - " + c.post_code;
        a += " - " + c.city;
        return a;
    }

    handleAddressChange = (event) => {
        this.setState({
          contactId: parseInt(event.target.value)
        });
    }

    render() {
        return "";

        if(AppContext.Auth.user === null)
            return <p className="text-center">{AppContext.r["must-be-logged"]}</p>;

        if(this.state.contacts === null)
            return AppContext.r["preloader"];

        const validationSchema = Yup.object().shape({
            alias: Yup.string().required(AppContext.r["required-field"]),
            //name: Yup.string().required(AppContext.r["required-field"]),
            address1: Yup.string().required(AppContext.r["required-field"]),
            city: Yup.string().required(AppContext.r["required-field"]),
            post_code: Yup.string().required(AppContext.r["required-field"]),
        });

        const contactsMap = this.state.contacts.length === 0 ?
            <p className="text-center">{AppContext.r["no-addresses"]}</p> :
                this.state.contacts.map(c => 
                    <div key={c.id}>
                        {this.props.showSelect ? <input type="radio" value={c.id} checked={this.state.contactId === c.id} onChange={this.handleAddressChange} /> : ""}
                        <h3>{c.alias}</h3>
                        <Button className="delete-button" variant="link" onClick={() => this.onDelete(c.id)}>
                            <i className="fas fa-trash-alt"></i> {AppContext.r["delete"]}
                        </Button>
                        <p>{this.formatAddress(c)}</p>
                    </div>);

        const f = (!this.state.showInsertForm) ? "" :
            <div className="address-form">
                <Button className="insert-new-address-button" variant="link" onClick={this.onClose}>
                    {AppContext.r['close']}
                </Button>

                <h2>{AppContext.r["insert-new-address"]}</h2>

                <Formik
                    initialValues={{
                        alias: AppContext.r["default-address"],
                        name: AppContext.Auth.user.name + " " + AppContext.Auth.user.surname,
                        description: "",
                        address1: "",
                        address2: "",
                        post_code: "",
                        city: "",
                        country_id: 102,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={fields => {
                        this.storeContact(fields);
                    }}
                    render={({ errors, status, touched, initialValues }) => (
                        <FormikForm>
                            <div className="form-group">
                                <label htmlFor="alias">{AppContext.r["address-alias"]}</label>
                                <Field name="alias" type="text" className={'form-control' + (errors.alias && touched.alias ? ' is-invalid' : '')} />
                                <ErrorMessage name="alias" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="name">{AppContext.r["name"]}</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="address1">{AppContext.r["address"]}</label>
                                <Field name="address1" type="text" className={'form-control' + (errors.address1 && touched.address1 ? ' is-invalid' : '')} />
                                <ErrorMessage name="address1" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <Field name="address2" type="text" className={'form-control' + (errors.address2 && touched.address2 ? ' is-invalid' : '')} />
                                <ErrorMessage name="address2" component="div" className="invalid-feedback" />
                            </div>

                            <Row>
                                <Form.Group as={Col} md="3">
                                    <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                                    <Field name="post_code" type="text" placeholder={AppContext.r["post-code"]} className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')} />
                                    <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
                                </Form.Group>

                                <Form.Group as={Col} md="5">
                                    <Form.Label>{AppContext.r["city"]}</Form.Label>
                                    <Field name="city" type="text" placeholder={AppContext.r["city"]} className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')} />
                                    <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                </Form.Group>
                                
                                <Form.Group as={Col} md="4">
                                    <Form.Label>{AppContext.r["country"]}</Form.Label>

                                    <RemoteSelect name="country_id" value={102}
                                                options={this.countryOptions} />
                                </Form.Group>
                            </Row>

                            <div className="form-group">
                                <Button variant="link" onClick={this.onClose}>{AppContext.r["cancel"]}</Button>
                                <button type="submit" className="btn btn-primary mr-2">{AppContext.r["save"]}</button>
                            </div>

                            <div className="overlay" style={{display: this.state.showFormOverlay ? "block" : "none"}}>
                                {AppContext.r["preloader"]}
                            </div>
                        </FormikForm>
                    )} />
            </div>;
        
        return (
            <Container>
                {this.props.showTitle === false ? "" : <h1 className="page-title contacts-title">{AppContext.r["your-addresses"]}</h1>}

                <div className="contacts">
                    {contactsMap}

                    <hr />
                </div>

                <Button className="insert-new-address-button" variant="link" onClick={this.onInsertNewAddress}
                    style={{display: this.state.showInsertForm ? "none" : "block"}}>
                    <i className="fas fa-plus-circle"></i> {AppContext.r['insert-new-address']}
                </Button>

                {f}

                { this.modal() }
            </Container>);
    }
}