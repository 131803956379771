import React from 'react';
import { connect } from "react-redux";
import Slider from "react-slick";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';

import AppContext from 'app/AppContext.js';
import {withModal} from 'framework/Modal';
import {updateProducts} from "app/modules/ECommerce/_redux/products/productsActions.js";
import Stand from './Stand';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "15px", zIndex: 1 }}
            onClick={onClick}>
            <ChevronRightIcon />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "0px", zIndex: 1200 }}
            onClick={onClick}>
            <ChevronLeftIcon />
        </div>
    );
}
  
class Stands extends React.Component {
    state = {
        stands: this.props.standsData,
        currentSlide: null,
        currentStand: null // Stores info about the currently displaying stand
    };

    sliderSettings = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    async componentDidMount() {
        window.scrollTo(0, 0);

        const {subdomain} = this.props;
        const {stands} = this.state;

        // Select initial slide/stand
        const index = !subdomain ? 0 : stands.findIndex((stand) => stand.subdomain === subdomain || stand.name === subdomain);
        this.sliderSettings["initialSlide"] = index;
        // this.slider.slickGoTo(index);

        this.setState({
            currentSlide: index
        });
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.stand !== this.props.stand) {
            const {stands} = this.state;
            const index = stands.findIndex((stand) => stand.id === nextProps.stand.id);

            this.setState({
                currentStand: nextProps.stand,
                currentSlide: index,
            });

            this.slider.slickGoTo(index);
        }
    }

    afterChangeHandler = (currentSlide) => {
        const {stands} = this.state;

        const route = "/" + (stands[currentSlide].subdomain ? stands[currentSlide].subdomain : stands[currentSlide].name);

        AppContext.history.replace({ pathname: route, search: '', state: { } });

        this.props.updateProducts(null, null);

        this.setState({
            currentSlide: currentSlide,
            currentStand: stands[currentSlide]
        });
    }

    render() {
        const { stands, currentSlide } = this.state;
        const { user } = this.props.auth;
        
        return (!stands || currentSlide === null) ? AppContext.r["preloader"] :
            <div className="stands-gallery">
                {!user && (
                    <div className="must-login-message">
                        <p>{AppContext.r["must-be-logged-event"]}</p>

                        <div>
                            <Button onClick={this.props.authActions.onRegister}>{AppContext.r["signup"]}</Button>{AppContext.r["or"]}<Button onClick={this.props.authActions.onLogin}>{AppContext.r["login"]}</Button>
                        </div>
                    </div>)}

                <Slider ref={slider => (this.slider = slider)} {...this.sliderSettings}
                    afterChange={this.afterChangeHandler}>
                    {stands.map(stand => <Stand key={"stand"+stand.id} stand={stand} currentStand={stands[currentSlide]} />)}
                </Slider>
            </div>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProducts: (totalCount, products) => {
            dispatch(updateProducts(totalCount, products));
        }
    }
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(withModal(Stands));