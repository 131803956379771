import React from 'react';

import AppContext from 'app/AppContext';
import WoolSafeApi from 'api/WoolSafe';
import Event from './Event';

export default class Stage extends React.Component {
    state = {
        event: null
    };
    
    async componentDidMount() {
        // Retrieve Next Seminar Event
        const response = await WoolSafeApi.Events(1, 1, this.props.typeId);

        if(response && response.status === 200 && response.data.data) {
            this.setState({
                event: (response.data.data.length > 0) ? response.data.data[0] : {}
            })
        }
    }

    render() {
        const { event } = this.state;

        if(!event)
            return AppContext.r["preloader"];

        return (
            <div className="page stage">
                { event.id ?
                    <Event id={event.id} authActions={this.props.authActions} /> 
                    : <p>{AppContext.r["no-upcoming-events"]}</p> }
            </div>
        );
    }
}