import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useLocation } from 'react-router-dom'

import Layout from "./Layout";
import Home from "app/views/Home";
import MyAccount from './modules/Auth/pages/MyAccount';
import ResetPassword from 'app/modules/Auth/pages/ResetPassword';
import Tickets from 'app/views/Tickets';
import Events from 'app/views/Events';
import Stands from 'app/views/Stands';
import Stage from 'app/views/Stage';
import Event from 'app/views/Event';
import PrivacyPolicy from 'app/views/PrivacyPolicy';
import Terms from 'app/views/Terms';
import Refunds from 'app/views/Refunds';
import Registration from 'app/views/Registration';
import ComingSoon from 'app/views/ComingSoon';

import AppContext from 'app/AppContext';
import WoolSafeApi from "api/WoolSafe";
import ZoomWidget from "framework/ZoomWidget";

export default function BasePage(props) {
    const [hlStandId, setHlStandId] = useState(null);

    const [standsData, setStandsData] = useState(null);
    const [stand, setStand] = useState(null);

    const fetchStandsData = async () => {
        const response = await WoolSafeApi.Stands();
        console.log(response)

        setStandsData((response && response.data) ? response.data.data : null);
    }

    const location = useLocation();
    
    AppContext.history = useHistory();
    
    useEffect(async () => {
        AppContext.history.listen(() => {
            if(AppContext.zoomEvent) {
                console.log(AppContext.zoomEvent);

                if(!AppContext.zoomEvent.minimized) {
                    ZoomWidget.minimize();
                }

                if(!AppContext.zoomEvent.movedInDom) {
                    ZoomWidget.moveInDomAfter();
                    ZoomWidget.displayWidget();
                }
            }
        });

        await fetchStandsData();
    }, []);

    // Remove FB Messenger Plugin if loaded
    if(location.pathname !== "/") {
        const fbMessenger = document.getElementById("fb-root");

        if(fbMessenger)
            fbMessenger.remove();
    }

    const setHighlightedStand = (id) => {
        if(hlStandId !== id) {
            setHlStandId(id);
        }
    }

    const home = (
        <Layout standsData={standsData} setHighlightedStand={setHighlightedStand} hideFooter>
            <Home standsData={standsData} hlStandId={hlStandId} setNotification={props.setNotification} />
        </Layout>);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            {standsData && 
                <Switch>
                    <ContentRoute path="/verify/email/:token" render={({ match }) => 
                        <ComingSoon token={match.params.token} setNotification={props.setNotification} /> } />

                    {/* <ContentRoute exact path="/" render={({ match }) => 
                        <Layout hideHeader disableEventsNotification>
                            <ComingSoon token={match.params.token} setNotification={props.setNotification} />
                        </Layout> } /> */}

                    {/* <ContentRoute path="/verify/email/:token" render={({ match }) => 
                        <Layout standsData={standsData}>
                            <Home token={match.params.token} setNotification={props.setNotification} />
                        </Layout>}/> */}

                    {/* <ContentRoute exact path="/preview" render={() => home } /> */}

                    <ContentRoute exact path="/" render={({ match }) => home } />

                    <ContentRoute path="/myaccount">
                        <Layout standsData={standsData}><MyAccount /></Layout>
                    </ContentRoute>

                    <ContentRoute path="/tickets">
                        <Layout standsData={standsData}><Tickets /></Layout>
                    </ContentRoute>

                    <ContentRoute path="/events">
                        <Layout standsData={standsData} disableEventsNotification><Events /></Layout>
                    </ContentRoute>

                    <ContentRoute path="/resetpassword">
                        <Layout standsData={standsData}><ResetPassword /></Layout>
                    </ContentRoute>

                    <ContentRoute path="/terms">
                        <Layout standsData={standsData}><Terms /></Layout>
                    </ContentRoute>
                    
                    <ContentRoute path="/privacy">
                        <Layout standsData={standsData}><PrivacyPolicy /></Layout>
                    </ContentRoute>

                    <ContentRoute path="/refunds">
                        <Layout standsData={standsData}><Refunds /></Layout>
                    </ContentRoute>
                    
                    <ContentRoute exact path="/stage">
                        <Layout standsData={standsData}><Stage typeId={2} /></Layout>
                    </ContentRoute>
                    
                    <ContentRoute exact path="/registration">
                        <Registration />
                    </ContentRoute>

                    <ContentRoute exact path='/event/:id' 
                        render={({ match }) => (
                            <Layout standsData={standsData}>
                                <Event id={match.params.id} setNotification={props.setNotification} />
                            </Layout>)} />

                    <ContentRoute exact path='/:stand' 
                        render={({ match }) => (
                            <Layout standsData={standsData} onStandClick={(s) => setStand(s)} hideFooter>
                                <Stands standsData={standsData} stand={stand} subdomain={match.params.stand} 
                                    setNotification={props.setNotification} />
                            </Layout>)} />

                    <ContentRoute path="/error">
                        <Layout standsData={standsData}>
                            <h2>Page not found.</h2>
                        </Layout>
                    </ContentRoute>
                    
                    <Redirect to="error"/>
                </Switch> }
        </Suspense>
    );
}