import settings from "../settings";
import resources from "../resources/resources";

export default class AppContext {
    static get s() { return settings; }
    static get r() { return resources; }

    static history = null;

    static enableCart = true;

    static yupAddPhoneTest = (yup) => {
        yup.addMethod(yup.string, "phone", function(messageError = AppContext.r['phone-not-valid']) {
            const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
            
            return this.test('phone', messageError, value => {//return false;
                if(value && value.substr(0,1) === "+")
                    value = value.substr(1, value.length-1);
        
                if (value && value.length > 0)
                    return phoneRegExp.test(value);
        
                return true;
            })
        })
    }

    static get isLocalhost () {
        return window.location.hostname === "localhost";
    }
}