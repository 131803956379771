import React from 'react';

export default class Review extends React.Component {

    render() {
        const {className, rating} = this.props;
        const stars = [];

        for(var i=0; i<5; i++)
            if((i+1) <= rating)
                stars.push(<i className="fas fa-star"></i>);
            else if((i+0.25) <= rating)
                stars.push(<i className="fas fa-star-half-alt"></i>);
            else
                stars.push(<i className="far fa-star"></i>);

        return (
            <div className={className + " stars-review"}>
                {stars}
            </div>
        );
    }
}