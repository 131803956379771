import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { connect } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import VideoFileIcon from '@material-ui/icons/VideoLibrary';
import { Link } from "react-router-dom";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Icon from '@material-ui/core/Icon';
// import { FacebookProvider, Like } from 'react-facebook';

import AppContext from 'app/AppContext';
import * as auth from "app/modules/Auth/_redux/authRedux";
import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import Logo from "components/Logo";
import Socials from "framework/Socials";

class Header extends React.Component {
    state = {
        drawerOpen: false,
        legalDocsToggled: false,
        showLogo: true
    }
    
    componentDidMount() {
        window.addEventListener('scroll', event => {
            const scrollTop = window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop || -1;

            const showLogo = scrollTop < 30;

            if(this.state.showLogo != showLogo)
                this.setState({
                    showLogo: showLogo
                })
          }, true)
    }

    toggleDrawer = (v = null) => {
        this.setState({
            drawerOpen: v != null ? v : !this.state.drawerOpen
        })
    }

    logout = () => {
        this.props.logout();
        this.toggleDrawer(false);
    }

    handleLegalDocsClick = () => {
        this.setState({
            legalDocsToggled: !this.state.legalDocsToggled
        })
    }

    render () {
        const {user} = this.props.auth;

        const avatar = 
            user ?
                <Avatar className="glow" onClick={() => this.toggleDrawer(true)}
                    src={AppContext.s['api-url'] + "/users/" + user.id + "/image"}>{user.name && user.name.substr(0,1)}</Avatar>
                : <IconButton className="login-icon" aria-label="access" onClick={this.props.authActions.onLogin}>
                    <PersonIcon />
                </IconButton>;

        return (
            <header>
                <SwipeableDrawer anchor={"left"} open={this.state.drawerOpen} 
                    onClose={() => this.toggleDrawer(false)} onOpen={() => this.toggleDrawer(true)}>
                    {user && 
                        <div className="drawer-content">
                            <div className="user-area">
                                <Avatar src={AppContext.s['api-url'] + "/users/" + user.id + "/image"}>{user.name && user.name.substr(0,1)}</Avatar>
                                <h1>{user.name}</h1>
                            </div>

                            <List className="drawer-list" component="nav">
                                <Link to="/" onClick={() => this.toggleDrawer(false)}>
                                    <ListItem button className="list-item">
                                        <ListItemIcon>
                                            <HomeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Home" />
                                    </ListItem>
                                </Link>
                                
                                <ListItem button onClick={() => {
                                        this.toggleDrawer(false);
                                        this.props.onWelcomeVideoClick();
                                    }}>
                                    <ListItemIcon>
                                        <VideoFileIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Welcome to GrandExpos" />
                                </ListItem>
                                
                                <ListItem button onClick={this.handleLegalDocsClick}>
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Info" />
                                    {this.state.legalDocsToggled ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>

                                <Collapse in={this.state.legalDocsToggled} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className="sublist">
                                        <Link to="/privacy" onClick={() => this.toggleDrawer(false)}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <FiberManualRecordIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={AppContext.r['privacy-policy']} />
                                            </ListItem>
                                        </Link>

                                        <Link to="/terms" onClick={() => this.toggleDrawer(false)}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <FiberManualRecordIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={AppContext.r['terms']} />
                                            </ListItem>
                                        </Link>

                                        <Link to="/refunds" onClick={() => this.toggleDrawer(false)}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <FiberManualRecordIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={AppContext.r['refunds']} />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>
                            </List>

                            <List className="drawer-list" component="nav" subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    {AppContext.r['user-area']}
                                </ListSubheader>
                            }>
                                <Link to="/myaccount" onClick={() => this.toggleDrawer(false)}>
                                    <ListItem button className="list-item">
                                        <ListItemIcon>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={AppContext.r['my-account']} />
                                    </ListItem>
                                </Link>

                                <Link to="/tickets" onClick={() => this.toggleDrawer(false)}>
                                    <ListItem button className="list-item">
                                        <ListItemIcon>
                                            <Icon className="fas fa-ticket-alt" />
                                        </ListItemIcon>
                                        <ListItemText primary={AppContext.r['your-tickets']} />
                                    </ListItem>
                                </Link>

                                {user.roles[0] && 
                                    <a href={AppContext.s["portal-url"]} onClick={() => this.toggleDrawer(false)}>
                                        <ListItem button className="list-item">
                                            <ListItemIcon>
                                                <Icon className="fas fa-user-shield" />
                                            </ListItemIcon>
                                            <ListItemText primary={AppContext.r['admin-portal']} />
                                        </ListItem>
                                    </a>}

                                <ListItem button className="list-item" onClick={this.logout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={AppContext.r['logout']} />
                                </ListItem>
                            </List>

                            <div className="socials">
                                <p className="text-center">{AppContext.r["like-us-desc"]}</p>

                                <Socials />
                            </div>

                            <div className="drawer-footer">
                                <a href={AppContext.s["provider"].websiteUrl} target="_blank" rel="noopener noreferrer">
                                    <p className="powered-by">{AppContext.r["powered-by"]}</p>
                                    <img className="provider-logo" src={AppContext.s["provider"].logoPath} alt="" />
                                </a>

                                {AppContext.s["provider"].facebookPageUrl &&
                                    <a href={AppContext.s["provider"].facebookPageUrl} target="_blank" rel="noopener noreferrer">
                                        <img className="provider-social" src="/assets/images/facebook-icon-32.png" alt="" />
                                    </a>}

                                {AppContext.s["provider"].linkedinPageUrl &&
                                    <a href={AppContext.s["provider"].linkedinPageUrl} target="_blank" rel="noopener noreferrer">
                                        <img className="provider-social" src="/assets/images/linkedin-icon-32.png" alt="" />
                                    </a>}
                            </div>
                        </div> }
                </SwipeableDrawer>
                
                <div className="avatar">
                    {avatar}
                </div>

                {this.state.showLogo && 
                    <Link to="/">
                        <Logo light />
                    </Link> }
            </header>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { ...auth.actions, ...cart.actions })(Header);