import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { connect } from "react-redux";
import * as Yup from 'yup';

import * as auth from "../_redux/authRedux";
import AppContext from 'app/AppContext';
import AuthApi from 'api/Auth';
import withOverlay from "framework/withOverlay";

class DeleteAccount extends React.Component {
    deleteAccount = async (password) => {
        this.props.overlay.show();
        this.props.overlay.setBusy();

        const response = await AuthApi.deleteAccount(password);
    
        let message = "";

        if(response) {
            if (response.data === "ok" && response.status === 200) {
                this.props.overlay.setBusy(false);

                message = 
                    <div>
                        <i className="far fa-frown icon"></i>
                        {AppContext.r['account-deleted']}

                        <Button onClick={this.close}>{AppContext.r['close']}</Button>
                    </div>;

                this.props.logout();

                if(this.props.onAccountDeleted)
                    this.props.onAccountDeleted(response);
            } else {
                // Authorization Failed

                if(response.status === 401) {
                    message = <div>
                        <p><i className="far fa-frown"></i> {AppContext.r["wrong-password"]}</p>
                        <Button onClick={this.setFree}>{AppContext.r['close']}</Button>
                    </div>;
                }

                if(response.status === 400 || response.status === 500) // Bad Request or Internal Server Error
                    message = response.data;
            }
        } else {
            message = AppContext.r['server-not-working'];
        }

        this.props.overlay.setView(message);
    }

    close = () => {
        this.props.overlay.hide();
        this.props.modal.hide();
    }

    setFree = () => {
        this.props.overlay.hide();
    }

    render() {
        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required'])
        });

        const t = this;

        return (
            <div className="auth-module delete-account">
                <p>{AppContext.r["delete-account-desc"]}</p>

                <Formik
                    initialValues={{
                        password: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={fields => {
                        console.log(fields)

                        t.deleteAccount(fields.password);
                    }}
                    render={({ errors, status, touched }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <Button type="submit" variant="danger" className="btn btn-primary mr-2">{AppContext.r["delete-account"]}</Button>
                            </div>
                        </Form>
                    )} />
            </div>);
    }
}

export default connect(null, auth.actions) (withOverlay(DeleteAccount));