import * as THREE from "three";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function hsl(h, s, l) {
    return (new THREE.Color()).setHSL(h, s, l);
}

export class ModelEventsEnum {
    static get Loaded () { return "loaded"; }
}

export class ModelTypesEnum {
    static get Model () { return "model"; }
    static get Banner () { return "banner"; }
    static get Video () { return "video"; }
}

export default class HiModel {
    _events = [];
    object = null;
    group = null;
    transform = null; // Initial Transform settings
    data = null;
    boundingBox = null;
    type = null;
    overlay = null;

    constructor(data = null) {
        this.data = data;
    }

    addEventListener (event, onEvent) {
        this._events.push({
            event: event,
            onEvent: onEvent
        });
    }

    click = () => {
        if(this.onClick)
            this.onClick(this);
    }

    over = () => {
        // console.log("onOver");

        if(this.onOver)
            this.onOver(this);

        if(this.overlay)
            this.overlay.visible = true;
    }

    out = () => {
        // console.log("onOut");

        if(this.onOut)
            this.onOut(this);

        if(this.overlay)
            this.overlay.visible = false;
    }

    setBoundingBox = (g = null, transform = null) => {
        const t = transform ? transform : this.transform;

        if(t) {
            const geometry = g ? g : new THREE.CylinderGeometry( 5, 5, 6, 16 );

            const shape = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({
                    color: new THREE.Color(0x0000dd),// hsl(0 / 8, 1, .2),
                    opacity: 0.003,
                    transparent: true,
                })
            );
    
            shape.scale.x = 1;
            shape.scale.y = 1;
            shape.scale.z = 1;

            shape.position.x = t.position.x;
            shape.position.y = t.position.y;
            shape.position.z = t.position.z;
    
            this.boundingBox = shape;
        }
    }

	load = (url, transform = null/* material = null*/) => {
        // const ts = Date.now();

        this.type = ModelTypesEnum.Model;

        this.transform = transform;

        const isFbx = (url.toLowerCase().indexOf(".fbx") > 0);
		const loader = isFbx ? new FBXLoader() : new GLTFLoader();

        loader.load(url, (object) => {
            if(!isFbx)
                object = object.scene;

            if(transform && transform.scale)
                object.scale.set(transform.scale, transform.scale, transform.scale);

            if(transform && transform.position)
                object.position.copy(transform.position);

            if(transform && transform.rotation) {
                object.rotation.x = transform.rotation.x;
                object.rotation.y = transform.rotation.y;
                object.rotation.z = transform.rotation.z;
            }
            
			object.traverse(function (child) {
				if (child.isMesh) {
					//child.castShadow = true;
					child.receiveShadow = true;
                    //if(material)
                    //    child.material = material;
				}
			});

            this.object = object;

            for(var e of this._events)
                if(e.event === ModelEventsEnum.Loaded && e.onEvent)
                    e.onEvent(this);

            // if(this.onModelLoaded)
            //     this.onModelLoaded(this);

            // console.log(Date.now() - ts + "ms to load " + url);
        }, function (e) {
            //console.log(e);
            // Progressing
        }, function (e) {
            console.log(e);
        });
  	}

    banner = (transform = null, image = null) => {
        this.type = ModelTypesEnum.Banner;

        const defaultMaterial = new THREE.MeshBasicMaterial({ color: "#000000" });
        const defaultPosition = { x: 0, y: 0, z: 0 };
        const defaultRotation = { x: 0, y: 0, z: 0 };
        const defaultScale = { x: 32, y: 24, z: 1 };

        const placeholder = '/assets/textures/banner.png';
        const imageUrl = (image) ? image : placeholder;

        const t = {
            position: (transform && transform.position) ? transform.position : defaultPosition,
            rotation: (transform && transform.rotation) ? transform.rotation : defaultRotation,
            scale: (transform && transform.scale) ? transform.scale : defaultScale,
        };
        this.transform = t;

        const texture = new THREE.TextureLoader().load(imageUrl);

        const materials = [
            defaultMaterial,
            defaultMaterial,
            defaultMaterial,
            defaultMaterial,
            new THREE.MeshBasicMaterial({ color: "#ffffff", map: texture }),
            defaultMaterial,
        ];
        
        const shape = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 0.5, 1, 1, 1), materials);

        shape.scale.x = t.scale.x;
        shape.scale.y = t.scale.y;
        shape.scale.z = t.scale.z;

        shape.position.x = t.position.x;
        shape.position.y = t.position.y;
        shape.position.z = t.position.z;

        shape.rotation.x = t.rotation.x;
        shape.rotation.y = t.rotation.y;
        shape.rotation.z = t.rotation.z;

        this.group = new THREE.Group();

        this.object = this.boundingBox = shape;

        this.group.add(shape);
    }

    video = (v, addOverlay = 0) => {
        this.type = ModelTypesEnum.Video;

        // create the video element
        this.video = document.createElement( 'video' );
        // video.id = 'video';
        // video.type = ' video/ogg; codecs="theora, vorbis" ';
        this.video.src = v.src;
        this.video.muted = true;
        this.video.autoplay = true;
        this.video.loop = true;
        this.video.load(); // must call after setting/changing source
        this.video.play();
        
        // alternative method -- 
        // create DIV in HTML:
        // <video id="myVideo" autoplay style="display:none">
        //		<source src="videos/sintel.ogv" type='video/ogg; codecs="theora, vorbis"'>
        // </video>
        // and set JS variable:
        // video = document.getElementById( 'myVideo' );
        
        this.videoImage = document.createElement( 'canvas' );
        this.videoImage.width = v.width//480;
        this.videoImage.height = v.height//204;
    
        this.videoImageContext = this.videoImage.getContext( '2d' );
        // background color if no video present
        this.videoImageContext.fillStyle = '#000000';
        this.videoImageContext.fillRect( 0, 0, this.videoImage.width, this.videoImage.height );
    
        this.videoTexture = new THREE.VideoTexture( this.videoImage, THREE.EquirectangularReflectionMapping );//new THREE.Texture( this.videoImage );
        // this.videoTexture.minFilter = THREE.LinearFilter;
        // this.videoTexture.magFilter = THREE.LinearFilter;
        this.videoTexture.wrapS = THREE.RepeatWrapping;
        this.videoTexture.wrapT = THREE.RepeatWrapping;
        this.videoTexture.rotation = 0;
        this.videoTexture.repeat.set( 1, 1 );
        
        const movieMaterial = new THREE.MeshBasicMaterial( { map: this.videoTexture, overdraw: true, side:THREE.DoubleSide } );
        // the geometry on which the movie will be displayed;
        // movie image will be scaled to fit these dimensions.
        const movieGeometry = new THREE.PlaneGeometry(v.scale.x, v.scale.y, 2, 1 );
        const movieScreen = new THREE.Mesh(movieGeometry, movieMaterial);
        movieScreen.position.set(v.position.x, v.position.y, v.position.z);

        this.object = this.boundingBox = movieScreen;

        this.group = new THREE.Group();
        this.group.add(movieScreen);

        // Add Overlay

        if(addOverlay !== 0) {
            this.overlay = new THREE.Mesh(movieGeometry, new THREE.MeshPhongMaterial({
                color: new THREE.Color(0x0000dd),
                opacity: 0.5,
                transparent: true })
            );

            this.overlay.position.set(v.position.x, v.position.y, v.position.z + ((addOverlay > 0) ? 0.03 : -0.03));

            this.overlay.visible = false;

            this.group.add(this.overlay);
        }
    }
}
