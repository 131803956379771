import React from 'react';

export default function loadClientResources (resources) {
    resources["remove"] = "Rimuovi";
    resources["add-to-cart"] = "Aggiungi al Carrello";
    resources["checkout"] = "Ordina";
    resources["empty-cart"] = "Il carrello è vuoto.";
    resources["my-shopping-cart"] = "Carrello";
    resources["shop"] = "Negozio";
    resources["shops"] = "Negozi";
    resources["shop-item-categories"] = "Categorie";
    resources["shop-item-category"] = "Categoria";
    resources["shop-item-sub-category"] = "Sottocategoria";
    resources["shop-item-sub-categories"] = "Sottocategorie";
    resources["highlighted"] = "In Evidenza";
    resources["percent-discount"] = "Sconto %";
    resources["placed-at"] = "Piazzato Il";
    resources["requested-date"] = "Data Richiesta";
    resources["requested-date-time"] = "Data e Ora Richiesta";
    resources["order"] = "Ordine";
    resources["orders"] = "Ordini";
    resources["order-details"] = "Dettagli Ordine";
    resources["brand"] = "Brand";
    resources["shop-item-brand"] = "Marca";
    resources["shop-item-brands"] = "Marche";
    resources["customer"] = "Cliente";
    resources["customers"] = "Clienti";
    resources["pending-orders"] = "Nuovi Ordini";
    resources["no-pending-orders"] = "Non hai nuovi ordini.";
    resources["payment"] = "Pagamento";
    resources["order-to-table"] = "Ordine al Tavolo";
    resources["delivery"] = "Delivery";
    resources["takeaway"] = "Take Away";
    resources["order-placed"] = <><p>Grazie! Il tuo ordine è stato piazzato.</p><p>Buona fortuna!</p></>
    resources["your-address"] = "Il tuo indirizzo";
    resources["your-addresses"] = "I Tuoi Indirizzi";
    resources["no-addresses"] = "Non hai inserito nessun indirizzo.";
    resources["your-orders"] = "I Tuoi Ordini";
    resources["no-orders"] = "Non ci sono ordini :(.";
    resources["deliver-to"] = "Spedisci a";
    resources["confirm-delete-order"] = "Sei sicuro di volere eliminare questo ordine?";
    resources["confirm-delete-order-heading"] = "Elimina Ordine";
    resources["cannot-delete-order"] = (
        <div>
            <p>Il tuo ordine è già stato processato e non può essere cancellato automaticamente.</p>
            <p>Prova a contattare il gestore tramite telefono o e-mail.</p>
        </div>);
    resources["specify-order-type"] = "Specifica il tipo ordine.";
    resources["specify-requested-date-time"] = "Specifica data e ora di raccolta.";
    resources["store-closed-message"] = (
        <>
            <h3>Oggi siamo chiusi.</h3>
            <p className="text-center">Controlla il calendario per selezionare la prossima data disponibile.</p>
        </>
    );
    resources["allow-newsletter"] = "Vuoi essere informato riguardo aggiornamenti e promozioni nostre o da parte dei nostri partner?"
    resources["account-verified"] = "Perfetto! Il tuo account è stato verificato.";
    resources["my-orders"] = "I Tuoi Ordini";

    resources["payment-types"] = [

    ]
    resources['min-order-is'] = "L'ordine minimo è ";
    resources["no-results"] = "Nessun risultato.";
    resources["reviews"] = "Recensioni";
    resources["pay"] = "Paga";
    resources["pay-by-card"] = "Paga con carta";
    resources["delete-payment-method-confirm"] = "Vuoi eliminare questo metodo di pagamento?";
    resources["pay-by-new-card"] = "Paga con un'altra carta";
}