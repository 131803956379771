// ZoomWidget v1.1

import React from 'react';
import Hammer from "hammerjs";
import IconButton from '@material-ui/core/IconButton';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import AppContext from "app/AppContext";

export default class ZoomWidget extends React.Component {
    state = {
        active: true
    };

    lastPosX = 0;
    lastPosY = 0;
    isDragging = false;

    handleDrag = (ev) => {
        var elem = document.getElementById('zoomWidget'); // ev.target;
        
        // DRAG STARTED
        // here, let's snag the current position
        // and keep track of the fact that we're dragging
        if (!this.isDragging) {
            this.isDragging = true;
            this.lastPosX = elem.offsetLeft;
            this.lastPosY = elem.offsetTop;
        }
        
        // we simply need to determine where the x,y of this
        // object is relative to where it's "last" known position is
        // NOTE: 
        //    deltaX and deltaY are cumulative
        // Thus we need to always calculate 'real x and y' relative
        // to the "lastPosX/Y"
        var posX = ev.deltaX + this.lastPosX;
        var posY = ev.deltaY + this.lastPosY;
    
        // move our element to that position
        elem.style.left = posX + "px";
        elem.style.top = posY + "px";
    
        // this is where we simply forget we are dragging
        if (ev.isFinal) {
            this.isDragging = false;
        }
    }

    componentDidMount() {
        const mc = new Hammer(document.getElementById('zoomWidget'));

        mc.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }) );
        mc.on("pan", this.handleDrag);

        window.addEventListener("resize", this.onResize);
    }

    onResize = (e) => {
        // const t = 20;
        // const w = window.innerWidth - t;
        // const h = window.innerHeight - t;
    
        // console.log(initialX, initialY, currentX, currentY);
    
        // if(currentX > w || currentY > h) {
        //     if(currentX > w)
        //         currentX = w;
        //     if(currentY > h)
        //         currentY = h;
    
        //     setTranslate(currentX, currentY, dragItem);
    
        //     initialX = currentX = xOffset;
        //     initialY = currentY = yOffset;
        // }
    }

    static displayWidget = (id = "zoomWidget", display = "block") => {
        const widgetElement = document.getElementById(id);
        widgetElement.style.display = display;
    }

    static moveZoomInDomAfter = (fromId = "zmmtg-root", afterId = "zoomActions") => {
        const div1 = document.getElementById(fromId);
        const div2 = document.getElementById(afterId);
        
        div2.after(div1);

        AppContext.zoomEvent.movedInDom = true;
    }

    static moveInDomAfter = (fromId = "zmmtg-root", afterId = "zoomActions") => {
        const div1 = document.getElementById(fromId);
        const div2 = document.getElementById(afterId);
        
        div2.after(div1);
    }

    static minimize = () => {
        const element = document.getElementById("zmmtg-root");
        element.classList.add("as-widget");

        AppContext.zoomEvent.minimized = true;
    }

    maximize = () => {
        console.log("maximize");
        
        if(AppContext.zoomEvent) {
            const element = document.getElementById("zmmtg-root");
            element.classList.remove("as-widget");

            AppContext.zoomEvent.minimized = false;

            this.setState({
                active: false
            });
        }
    }

    render () {
        return (
            <div id="zoomWidget" className="zoom-widget-container">
                <div id="zoomActions">
                    {(AppContext.zoomEvent && AppContext.zoomEvent.minimized && !this.props.disableMaximize) &&
                        <IconButton color="primary" aria-label="Maximize" className="maximize-button"
                            onClick={this.maximize}>
                            <AspectRatioIcon />
                        </IconButton> }
                </div>

                {/* Zoom will be appended here */}
            </div>
        );
    }
}