// RemoteSelect 1.0

import React, {Component} from 'react'
import { Form } from 'react-bootstrap'

export default class RemoteSelect extends Component {
    constructor(props) {
        super (props);

        this.state = {
            options: this.props.options,
            value: this.props.value
        }

        this.fieldToMap = this.props.fieldToMap ? this.props.fieldToMap : "name";
    }

    componentDidMount() {
        // if(this.props.options !== undefined) {
        //     this.setState({
        //         options: this.props.options,
        //         value: ""
        //     });
        // } else if(this.props.url !== undefined) {
        //     console.log(this.props.url);
        //     const t = this;

        //     axios.get(this.props.url, { headers: { Authorization: Auth.bearer } })
        //         .then(response => {
        //             //console.log(response);
        //             console.log(this.props.value);

        //             t.setState({
        //                 options: response.data.data,
        //                 value: this.props.value
        //             });
        //         })
        //         .catch((error) => {
        //             console.log('error ' + error);
        //         });
        // } else {
        //     this.setState({
        //         value: this.props.value
        //     });
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            console.log(this.props.options);
                
            this.setState({
                options: this.props.options
            });
        }
      }

    handleChange = (e) => {
//        e.target.value = parseInt(e.target.value, 10);
        // const value = parseInt(e.target.value, 10);
        const value = e.target.value;

        this.setState({
            value: value
        });

        if(this.props.onChange !== undefined)
            this.props.onChange(e, value);

        if(this.props.onChange2 !== undefined)
            this.props.onChange2(e, value);
    }

    render() {
        const options = (this.state.options === null || this.state.options === undefined) ? [] : this.state.options.map(o => <option key={o.id} value={o.id}>{o[this.fieldToMap]}</option>);
        const isDisabled = this.state.options === null || this.props.disabled;
        const nullOption = (this.props.nullOption === true) ? <option value={null}></option> : "";
        const c = (this.props.className) ? this.props.className : "form-control";

        return (
            <Form.Control as="select" name={this.props.name} value={this.state.value} disabled={isDisabled} className={c}
                          onChange={this.handleChange}>
                {nullOption}
                {options}
            </Form.Control>);
    }
}