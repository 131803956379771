import loadClientSettings from 'settings/WoolSafe';

const settings = [];

settings['provider'] = {
    name: 'Uderly',
    website: 'www.uderly.com',
    websiteUrl: 'https://www.uderly.com',
    contactFormUrl: 'https://www.uderly.com/#contact',
    logoPath: "/assets/images/uderly-32.png",
    facebookPageUrl: "https://www.facebook.com/uderly",
    linkedinPageUrl: "https://www.linkedin.com/company/uderly/"
};
settings["project-name"] = "WoolSafe Expo";
settings["project-website"] = "www.grandexpos.com";
settings["domain"] = "grandexpos.com";
settings["sub-title"] = "";
settings["language"] = "en";
settings["currency"] = "£";

settings["logo-grey"] = "/assets/images/uderly-payoff.png";
settings["logo-dark"] = "/assets/images/uderly-payoff.png";
settings["logo-light"] = "/assets/images/uderly-payoff-light.png";
settings["mini-logo-dark"] = "/assets/images/uderly-32.png";
settings["mini-logo-light"] = "/assets/images/uderly-payoff-light.png";
settings["placeholder"] = "/assets/images/uderly-payoff.png";
settings["password-min-length"] = 8;

// settings['facebook-page-id'] = '104751018345289';
settings['facebook-app-id'] = null;// '243499687795662';

settings["client-id"] = "2";
settings["client-secret"] = "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu";
settings["api-url"] = "https://woolsafe-api.uderly.com/api/v1";
settings["api-base-url"] = "https://woolsafe-api.uderly.com";
settings["portal-url"] = "https://woolsafe.uderly.com";

settings["facebook-page-url"] = "https://www.facebook.com/woolsafe";
settings['linkedin-page-url'] = 'https://www.linkedin.com/company/woolsafe';

settings["google-maps-key"] = "AIzaSyBBduueLb6PfFIa5Z811RxdusHYeP3-u-I";

settings['phone-regex'] = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

settings["redux-auth-key"] = "woolsafe-auth-key";
settings["redux-cart-key"] = "woolsafe-cart-key";

settings['phone'] = "";
settings['email'] = "info@grandexpos.cm";

settings['primary-color'] = "#008170";
settings['secondary-color'] = "#f5bd4a";

settings['test-stripe-key'] = "pk_test_51KMGKqHYMVx93hqospS1M8MZ3zk6VOjvYytID1UNjmX3UCVpzEWtK4wQfHzjv3NnNWIJQCGDdfseQATv3sPa1UNm00ysjor083";
settings['stripe-key'] = "pk_live_51KMGKqHYMVx93hqoT7j3k5WyemCzj61sykg7sirRPjecW5EkxgpHWCSHO4mhHtOhvnIHn5zvgn68TB0zuiD4Gi7X00r1TI4BJE";

settings["splashscreen-image"] = "/assets/images/uderly-payoff.png";
settings["splashscreen-text"] = settings["project-name"];

loadClientSettings(settings);

export default settings;