import React, {useEffect, useState} from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./Routes";
import { LayoutSplashScreen } from "../_metronic/layout";

import {loadResources} from "../resources/resources";

import AppContext from './AppContext';

var hist = AppContext.history = createBrowserHistory();

// Select language according to the domain
const host = window.location.hostname;
AppContext.s["language"] = host.indexOf(".it") > 0 ? "it" : "en";
// AppContext.s["project-website"] = "www.iqspace." + (AppContext.s["language"] === "it" ? "it" : "co.uk");
// AppContext.s["domain"] = "iqspace." + (AppContext.s["language"] === "it" ? "it" : "co.uk");
loadResources();
// Check browser language
// const userLang = navigator.language || navigator.userLanguage; 
// console.log("The browser language is: " + userLang);

export default function App({ store, persistor, basename }) {
    const [loaded, setLoaded] = useState(false);

    const scriptSources = [
        // "/scripts/jquery-3.4.1.min.js",
        // "/scripts/jquery.signalR.min.js",
        // "/scripts/hub.js",
        // "/scripts/SquizzerHub.js",
    ];

    const scripts = [];

    useEffect(() => {
        const loadScripts = () => {
            for(let src of scriptSources) {
                const script = document.createElement('script');
                script.src = src;
                script.async = false;
                document.body.appendChild(script);
    
                scripts.push(script);
            }

            setLoaded(true);

            return () => {
                for(let script of scripts) {
                    document.body.removeChild(script);
                }
            }
        }

        loadScripts();
    });

    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen />}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename} history={hist}>
                        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                        {/* Provide `react-intl` context synchronized with Redux state.  */}
                        {/* Render routes with provided `Layout`. */}
                        {loaded && 
                            <Routes /> }
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}