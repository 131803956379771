import React from 'react';
import { Link } from "react-router-dom";

import Settings from '../../settings';

export default function loadClientResources(resources) {
    resources['tag-line'] = <>IQ Space è il nuovo <b>live quiz</b> che manderà in orbita il tuo business di intrattenimento.</>;

    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connessione...";
    resources["rts-connected"] = "Connesso. :) Registrazione al server...";
    resources["rts-disconnected"] = "Disconnesso. :( Riconnessione in corso...";
    resources["rts-couldnt-connect"] = "Il gioco non è disponibile al momento per via di un problema di connessione al server. Assicurati di avere una connessione a internet affidabile e prova a ricaricare la pagina tra un po'.";
    resources["rts-subscription-failed"] = "Registrazione al server fallita. Errore: ";
    
    resources['access-the-game'] = "Accedi al gioco";
    resources['access-the-game-desc'] = "Vuoi accedere al gioco con il nome di {name}?";
    resources['access-the-game-change-name'] = <><p>Fa attenzione, perchè il nome di gioco non sarà modificabile una volta effettuato l'accesso.</p><p>Se vuoi modificarlo, vai alla sezione <Link to="/myaccount">{resources["my-account"]}</Link></p></>;
    resources['last-events'] = "Eventi Passati";
    resources['upcoming-events'] = "PROSSIMI EVENTI";
    resources['upcoming-event'] = "PROSSIMO EVENTO";
    resources['game-started'] = "GIOCO INIZIATO";
    resources['game-completed'] = "GIOCO COMPLETATO";
    resources['new-game-starting-soon'] = "Un nuovo gioco inizierà a momenti.";
    resources['congrats-winners'] = "Congratulazioni ai vincitori";
    resources['join-the-game'] = "ACCEDI AL GIOCO";
    resources['game-not-existing'] = "Il gioco selezionato non esiste o non è stato ancora avviato.";
    resources['event-not-existing'] = "Questo evento non esiste. :/";
    resources['game-expired'] = "Il gioco selezionato è scaduto.";
    resources['game-stopped'] = "Il gioco selezionato è stato fermato dall'intrattenitore.";
    resources['game-stopped'] = "";
    resources['ranking-position'] = "Sei arrivato in posizione {0} in classifica con un punteggio di {1} punti.";
    resources['click-here'] = "clicca qui";
    resources['browse-to-event-page'] = " per vedere la classifica finale e la pagina dell'evento.";
    resources['show-leaderboard'] = "Mostra Classifica";

    resources["keep-up-to-date"] = "Tieni aggiornato il tuo profilo pubblico";
    resources["upcoming-events"] = "I tuoi eventi in programma";
    resources["no-upcoming-events"] = "Nessun evento in programma";

    resources["events"] = "Eventi";
    resources["event"] = "Evento";
    resources["title"] = "Titolo";
    resources["published"] = "Pubblico";
    resources["datetime"] = "Data & Ora";
    resources["timezone"] = "Fuso Orario";
    resources["youtube-url"] = "Link YouTube";
    resources["facebook-url"] = "Link Facebook";
    resources["instagram-url"] = "Link Instagram";
    resources["twitter-url"] = "Link Twitter";
    resources["event-link"] = "Link Evento";
    
    resources["packages"] = "Contenuti";
    resources["package"] = "Contenuto";

    resources["games"] = "Giochi";
    resources["game"] = "Gioco";
    resources["event-id"] = "Id Evento";
    resources["started"] = "Iniziato";
    resources["stopped"] = "Fermato";
    resources["duration"] = "Durata";
    resources["paused"] = "In Pausa";
    resources["status"] = "Stato";

    resources["categories"] = "Categorie";
    resources["category"] = "Categoria";
    resources["color"] = "Colore";
    resources["points"] = "Punti";

    resources["questions"] = "Domande";
    resources["question"] = "Domanda";
    resources["text"] = "Testo";
    resources["note"] = "Note";
    resources["answers"] = "Risposte";
    resources["answer"] = "Risposta";
    resources["image-duration"] = "Durata Immagine";
    resources["collection-not-editable"] = "Questa collezione non è modificabile.";

    resources['exact'] = "Esatte";
    resources['wrong'] = "Errate";
    resources['not-answered'] = "Astenuti";
    resources['players'] = "Giocatori";
    resources['partial-ranking'] = "Classifica Parziale Ultima Domanda";
    resources['ranking'] = "Classifica Generale";
    resources['final-ranking'] = "Classifica Finale";
    resources['sorry'] = "Spiacente!";
    resources['invite-players'] = "Hey! Per giocare accedi a ";
    resources['game-started-full'] = (
        <div>
            <h1>Mettiti Comodo</h1>
            <p>Che la sfida abbia inizio</p>
        </div>);

    resources['play'] = "Gioca";
    resources['join-the-game'] = "ACCEDI AL GIOCO";
    resources['cookie-terms'] = "Accedendo accetti l'utilizzo dei cookies, necessari al funzionamento del gioco.";
    resources['quit'] = "Abbandona il gioco";
    resources['insert-name'] = "Inserisci il tuo nome";

    resources['welcome-enabled'] = (<div>
        <p>Benvenuto, sei entrato nel gioco.</p>
        <p>Inizierai con la prossima domanda.</p>
        <p className='internet-connection-box'>Per partecipare hai bisogno di una connessione internet affidabile. Per ridurre l'uso di dati, potresti connetterti alla Wi-Fi del locale.</p>
        <p>Buon divertimento!</p>
    </div>);
    resources['welcome-pending'] = "<p>Hey <span className=\"teamName\">&nbsp;</span>, ci siamo quasi!</p><p>Informa l'intrattenitore che desideri partecipare al gioco per approvare la tua registrazione.</p>";
    resources['name-taken'] = "Non è possibile scegliere il nome che hai specificato perchè è stato già utilizzato da qualcun altro.";
    resources['max-players-count'] = "Non è possibile accedere al gioco in quanto il numero massimo di giocatori è stato raggiunto.";
    resources['name-error'] = "Per accedere al gioco dovresti specificare un nome che ti rappresenti o che rappresenti la tua squadra.";
    resources['you-scored'] = "Hai totalizzato ";
    resources['confirm-quit-game'] = "Uscendo dal gioco perderai i progressi e il punteggio guadagnati. Sei sicuro di voler continuare?";

    resources['logged-on-other-device'] = "TriviaPro è aperto in un'altra finestra. Clicca \"gioca\" per ri-accedere al gioco in questa finestra.";
    resources['kicked-out'] = "Sei stato rimosso dal gioco dall'amministrazione.";
    resources['quiz-rules'] = "Regolamento Quiz";
    resources['on-air-in'] = "In onda tra...";
    
    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connessione...";
    resources["rts-connected"] = "Connesso. :) Registrazione al server...";
    resources["rts-disconnected"] = "Disconnesso. :( Riconnessione in corso...";
    resources["rts-couldnt-connect"] = "Il gioco non è disponibile al momento per via di un problema di connessione al server. Assicurati di avere una connessione a internet affidabile e prova a ricaricare la pagina tra un po'.";
    resources["rts-subscription-failed"] = "Registrazione al server fallita. Errore: ";
    resources["user-area"] = "Area Utente";
    resources["quizmaster-portal"] = "Portale Intrattenitori";

    resources["already-joined"] = <>
        <p>Sembra che tu faccia già parte della nostra community. Vai al <a href="https://portal.iqspace.co.uk">{resources["quizmaster-portal"]}</a> per gestire i tuoi eventi di gioco.</p></>
    resources["wants-to-join-community"] = "Vuoi far parte della nostra community?";
    resources["fill-the-form-to-join"] = "Compila il form per diventare una stella di " + Settings["project-name"] + ".";
    resources["joined-congrats"] = "Congratulazioni";
    resources["joined-description"] = <>
        <p>Perfetto!</p>
        <p>La tua richiesta di affiliazione è andata a buon fine.</p>
        <p>Vai al <a href="https://portal.iqspace.co.uk">{resources["quizmaster-portal"]}</a> per creare il tuo primo evento di gioco.</p></>
    resources["joined-failed"] = "Richiesta Fallita";
    resources["joined-failed-description"] = <><p>Qualcosa è andato storto e la tua richiesta non è andata a buon fine.</p><p><Link to="/contact">Contattaci</Link> e saremo lieti di aiutarti a impostare il tuo account con noi.</p></>;
    resources["submit-request"] = "Invia Richiesta";
    resources["pagename"] = "Nome Pagina";
    resources["promo-code"] = "Codice Promozionale";
    resources["your-space"] = "Il Tuo Spazio";
    resources["your-space-desc"] = <><small>Da nostro intrattenitore affiliato, avrai la tua pagina su IQ Space, che visualizzerà gli eventi e altre informazioni su di te.</small>
        <br /><small>Grande, no? 😎</small></>;
    resources["your-events-will-be"] = "I tuoi eventi saranno presentati sulla pagina";
    resources["nickname-validation"] = "Il nome della tua pagina deve essere lungo almeno 6 caratteri e può contenere soltanto lettere minuscole, numeri e trattini.";
    resources["organization-name"] = "Nome della tua organizzazione";
    resources["must-reconnect"] = "Si è verificato un problema e non è stato possibile avviare il gioco.";
    resources["reload-game"] = "Ricarica";
    resources["login-to-play"] = "per giocare.";
    resources["game-option-quit-tooltip"] = "Abbandona Il Gioco";
    resources["game-option-sounds-on-tooltip"] = "Abilita Suoni";
    resources["game-option-sounds-off-tooltip"] = "Disabilita Suoni";
    resources["game-option-players-tooltip"] = "Mostra Giocatori";

    resources["past-events"] = "Eventi Passati";
    resources["no-past-events"] = "Nessun evento passato";
    resources["upcoming-events"] = "Eventi in Programmazione";
    resources["no-upcoming-events"] = "Nessun evento in programma";
    resources["enable-audio"] = "Abilita Audio";
    resources["disable-audio"] = "Disabilita Audio";
    resources["powered-by"] = "powered by";
    resources["like-us-desc"] = <>Segui le nostre pagine per tenerti<br/>informato sui nostri prossimi eventi.</>;
    resources["like-this-game"] = <><p>Ti è piaciuto questo gioco?</p><p>{resources["like-us-desc"]}</p></>;
    resources["no-chat-messages"] = "Nessuno ha ancora scritto in chat. Sii il primo a scrivere qualcosa.";
    resources["access-to-chat"] = "Accedi al gioco per chattare";
    resources["game-name"] = "Nome di Gioco";
    resources["game-name-desc"] = "Questo è il tuo nome pubblico per giocare.";

    resources["home-banner"] = 
    (<>
        <h1>IQ Space sta arrivando</h1>
        <p>IQ Space è il nuovo <b>live quiz</b> per mandare in orbita 🚀 il tuo business di intrattenimento.</p>
        <p>IQ Space integra <b>Zoom</b> per coinvolgere in real-time i giocatori e una <b>chat social</b> per interagire tra di loro.</p>
        {/* <p>Start to sell your tickets directly from the IQ Space Portal ticketing system.</p> */}
        <p><Link to="workwithus">Inizia adesso a <s>lavorare</s> giocare con noi.</Link></p>
    </>);
    resources["buy-ticket"] = "Acquista Ticket";
    resources["your-tickets"] = "I Tuoi Ticket";
    resources["purchased-on"] = "Acquistato Il";
    resources["ticket-id"] = "Id Ticket";
    resources["voucher-code"] = "Codice Voucher";
    resources["paid"] = "Acquistato";
    resources["acquired"] = "Acquisito";
    resources["acquired-with"] = "Acquisito con ";
    resources["refunded"] = "Rimborsato";
    resources["refunds"] = "Rimborsi";
    resources["start-button"] = <span>Inizia (gratis) a <s>lavorare</s> giocare con noi</span>;
    resources["not-found"] = <>Pagina non trovata.<br/>Verifica se l'indirizzo url è corretto.</>;
    resources["voucher"] = "Voucher";
    resources["have-voucher"] = "Hai un voucher?";
    resources["apply"] = "Applica";
    resources["voucher-used"] = "Questo codice è già stato utilizzato.";
    resources["voucher-not-valid"] = "Questo codice non è valido.";
    resources["got-free-ticket"] = "Congratulazioni! Hai ottenuto un ticket gratuito!";
    resources["following"] = "Segui";
    resources["follow"] = "Segui";
    resources["unfollow"] = "Non seguire più";
    resources["not-following-anyone"] = "Non segui ancora nessuno.";
    resources["work-from-x"] = ["dal pub", "da casa", "dalla pizzeria", "da una spiaggia (perchè no?)", "dal tuo studio televisivo", "da dove preferisci 😍"];
    resources["allow-discoverable"] = "Vuoi essere promosso sul nostro sito o contattato da IQ Space e partner per opportunità professionali?";
    resources["go-to-event"] = "Vai a evento";
}