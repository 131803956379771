import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
// import { format } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz';
import ReactPlayer from 'react-player';

import AppContext from 'app/AppContext';
import WoolSafeApi from 'api/WoolSafe';
import { withModal } from '../../framework/Modal';
import BuyTicket, { EventAttendanceStatusesEnum } from 'components/BuyTicket';
import Countdown from '../../framework/Countdown';
import Zoom2 from '../../framework/Zoom2';

var HtmlToReactParser = require('html-to-react').Parser;

class Event extends React.Component {
    state = { }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.fetchEvent(nextProps.id);
        }
    }

    fetchAttendance = async () => {
        const response = await WoolSafeApi.AttendEvent(this.state.event, 1);
        // console.log(response);

        if(response && response.status === 200) {
            const attendance = response.data;

            this.setState({
                attendance: attendance
            });
        }
    }

    fetchEvent = async (id) => {
        const response = await WoolSafeApi.Event(id);

        if(response && response.status === 200) {
            const event = response.data.data;

            this.setState({
                event: event
            });

            const htmlToReactParser = new HtmlToReactParser();
            const reactElement = htmlToReactParser.parse(event.description);
    
            ReactDOM.render(
                reactElement,
                document.getElementById('EventDescription')
            );

            if(this.props.auth.user) {
                await this.fetchAttendance();
            }
        }
    }

    buyTicket = (event) => {
        this.props.modal.setView(AppContext.r["buy-ticket"], 
            <BuyTicket modal={this.props.modal} event={event} store={this.state.event.store} authActions={this.props.authActions} />, true);
    }

    async componentDidMount() {
        this.fetchEvent(this.props.id);
    }

    render() {
        const {event, attendance} = this.state;
        const {user} = this.props.auth;

        if(!event)
            return AppContext.r["preloader"];

            console.log(event)

        return (
            <div className={"event " + (this.props.className ? this.props.className : "")}>
                <Row className="justify-content-center">
                    {event.image && 
                        <Col xs={12} md={8} className="image-box">
                            <img src={AppContext.s["api-url"] + "/events/" + event.id + "/image"} alt={event.title} />
                        </Col> }
                </Row>

                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Countdown timespan={event.countdown} />
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col xs={12} md={8}>
                        <h1>{event.title}</h1>
                        {/* <p className="date-time">{format(new Date(event.date_time), "d MMM yyyy - H:mm")}</p> */}
                        <p className="date-time">{event.date_time && <>{formatInTimeZone(new Date(event.date_time), "Europe/London", "d MMM yyyy - H:mm")} GMT</>}</p>
                        {/* <p className="date-time">{event.date_time && format(new Date(event.date_time.substr(0, 19)), "d MMM yyyy - H:mm")}</p> */}
                        
                        {!user && (
                            <div className="must-login-message">
                                <p>{AppContext.r["must-be-logged-event"]}</p>

                                <Button onClick={this.props.authActions.onRegister}>{AppContext.r["signup"]}</Button>{AppContext.r["or"]}<Button onClick={this.props.authActions.onLogin}>{AppContext.r["login"]}</Button>
                            </div>
                        )}

                        {(event.price > 0 && attendance && attendance.status_id === EventAttendanceStatusesEnum.paid) &&
                            <p className="ticket-already-purchased">You purchased a ticket for this event</p> }

                        {(event.price > 0 && (!attendance || (attendance && attendance.status_id !== EventAttendanceStatusesEnum.paid))) &&
                            <div className="buyticket-button">
                                <Button size="small" color="primary" variant="contained"
                                    onClick={(e) => {
                                        this.buyTicket(event);
                                        e.stopPropagation();    
                                    }}>{AppContext.r["buy-ticket"]}</Button>
                            </div> }
                        
                        {(user && !event.has_ended && event.zoom_meeting_id && ((attendance && attendance.status_id === EventAttendanceStatusesEnum.paid) || event.price === 0)) &&
                            <Zoom2 showDirectLink 
                                disableMeetingInApp={event.disable_meeting_in_app}
                                config={{
                                    meetingNumber: event.zoom_meeting_id,
                                    userName: user.name,
                                    passWord: event.zoom_meeting_password,
                                    userEmail: user.email
                                }}
                                onJoin={() => {
                                    // Keep track of the active event
                                    AppContext.zoomEvent = {
                                        event: event,
                                        movedInDom: false,
                                        minimized: false,
                                        started: false
                                    };
                                }} /> }
                                
                        {event.video_url &&
                            <ReactPlayer className="video-player" controls={true} playing={false} muted={false} 
                                width={(window.innerWidth > 800) ? 738 : 369} height={(window.innerWidth > 800) ? 460 : 230}
                                url={event.video_url} light={false} /> }
                                
                        <div id="EventDescription" className="description"></div>
                    </Col>
                </Row>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withModal(connect(mapStateToProps, null)(Event));