import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Settings from '../settings';

import resourcesAuthentication from "./authentication/resources";
import itResourcesAuthentication from "./authentication/resources-it";
import resourcesDocs from "./docs/resources";
import itResourcesDocs from "./docs/resources-it";
import resourcesECommerce from "./e-commerce/resources";
import itResourcesECommerce from "./e-commerce/resources-it";
import resourcesUderlyPro from "./uderlypro/resources";
import itResourcesUderlyPro from "./uderlypro/resources-it";
import resourcesIqSpace from "./IqSpace/resources";
import itResourcesIqSpace from "./IqSpace/resources-it";
import resourcesExpo from "./Expo/resources";
import itResourcesExpo from "./Expo/resources-it";

import itResources from './resources-it';

const resources = [];

// resources['preloader'] = (
//     <div className="animated-preloader">
//       <div className="outer">&nbsp;</div>
//       <div className="inner">&nbsp;</div>
//     </div>);

resources['preloader'] = (
    <div className="mui-preloader">
        <CircularProgress />
    </div>);

function enResources() {
    resources['accept-cookies'] = "This website uses cookies to improve your navigation experience, storing your preferences such as your selected language. If you decide to carry on the navigation you accept the usage of cookies.";
    resources['accept'] = "Agreed";
    resources['months'] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    resources['days'] = "Days";
    resources['hours'] = "Hours";
    resources['minutes'] = "Minutes";
    resources['seconds'] = "Seconds";
    resources['phone'] = "Phone";
    resources['email'] = "e-mail";
    resources['name'] = "Name";
    resources['surname'] = "Surname";
    resources['close'] = "Close";
    resources['update'] = "Update";
    resources['follow-us-fb'] = "Like us on Facebook";
    resources['follow-us-ig'] = "Follow us on Instagram";
    resources['contact-us'] = "Contact Us";
    resources['sponsored-by'] = "Sponsored By";
    resources['hosted-by'] = "Hosted by";
    resources['cookies-policy'] = "Cookies Policy";
    resources['privacy-policy'] = "Privacy Policy";
    resources['terms'] = "Terms & Conditions";
    resources['cancel'] = "Cancel";
    resources['delete'] = "Delete";
    resources['save'] = "Save";
    resources['profile-saved'] = "Your profile has been updated.";
    resources['address'] = "Address";
    resources['insert-new-address'] = "Insert New Address";
    resources['address-alias'] = "Address Alias";
    resources["required-field"] = "Required Field.";
    resources["default-address"] = "Home Address";
    resources["post-code"] = "Post Code";
    resources["city"] = "City";
    resources["country"] = "Country";
    resources["confirm-delete-address"] = "Are you sure you want to delete this address?";
    resources["confirm-delete-address-heading"] = "Delete Address";
    resources["no-address-selected"] = "No address has been selected."
    resources["no-payment-type-selected"] = "No payment method has been selected."
    resources["payment-method"] = "Payment Method"
    resources["purchase-method-order"] = "Order To Table";
    resources["purchase-method-delivery"] = "Delivery";
    resources["purchase-method-collection"] = "Collection";
    resources['mobile-phone'] = "Mobile Phone";
    resources['email'] = "e-mail";
    resources['yes'] = "Yes";
    resources['no'] = "No";
    resources['or'] = "or";
    resources['next'] = "Next";
    resources['back'] = "Back";
    resources['description'] = "Description";
    
    resources["access"] = "Access";
    resources["thank-you"] = "Thank you.";
    resources['join-zoom-event'] = "Join Zoom Meeting In Expo";
    resources['by-zoom-link'] = "Access in Zoom by direct link";
}

export function loadResources() {
    if(Settings["language"] === "it") {
        itResources(resources);

        itResourcesAuthentication(resources);
        itResourcesDocs(resources);
        itResourcesECommerce(resources);
        itResourcesUderlyPro(resources);
        itResourcesIqSpace(resources);
        itResourcesExpo(resources);
    } else {
        enResources();
    
        resourcesAuthentication(resources);
        resourcesDocs(resources);
        resourcesECommerce(resources);
        resourcesUderlyPro(resources);
        resourcesIqSpace(resources);
        resourcesExpo(resources);
    }
}

export default resources;