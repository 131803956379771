import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AppContext from "app/AppContext";
import StripeApi from "api/Stripe";

function CheckoutForm(props) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [saveCard, setSaveCard] = useState(true);
    const [agreed, setAgreed] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    
    let amount = null;

    useEffect(async () => {
        if(props.paymentMethod)
            setDisabled(false);

        // Create PaymentIntent as soon as the page loads
        if(!clientSecret || props.amount !== amount) {
            // If the amount changes, invoke the API again
            amount = props.amount;

            await fetchClientSecret();
        }
    }, [ props.paymentMethod ]);

    const fetchClientSecret = async (saveCardParam = null) => {
        const data = {
            request: props.request,
            save_card: (saveCardParam === null) ? saveCard : saveCardParam
        };

        if(props.code)
            data.code = props.code;

        const url = AppContext.s["api-url"] + "/events/" + props.request.event + "/buy";

        const r = await StripeApi.FetchClientSecret(url, data);

        if(r && r.data) {
            setClientSecret(r.data.clientSecret);
        }
        console.log(r)
/*
        window.fetch(AppContext.s["api-url"] + "/events/" + props.request.event + "/buy", {
            method: "POST",
            headers: { 
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            setClientSecret(data.clientSecret);
            console.log(data)
        }).catch(function(error) {
            console.log("error", error);

            if (error.response) {
                // Request made and server responded
                console.log(error.response);
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        });*/
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const data = {
            receipt_email: props.email,
            payment_method: {
                card: elements.getElement(CardElement)
            }
        }

        if(props.paymentMethod)
            data.payment_method = props.paymentMethod;

        console.log(data);

        const payload = await stripe.confirmCardPayment(clientSecret, data);

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);

            if(props.onSucceeded)
                props.onSucceeded();
        }
    };
    
    const acceptConds = (
        <>
            <p className="accept-conditions">I accept the &nbsp;
                <Link to="/terms" target="_blank">Terms & Conditions</Link> and <Link to="/refunds" target="_blank">Refunds Policy</Link></p>

            <FormControlLabel label="I agree" className="agree-button"
                control={
                    <Checkbox
                        checked={agreed}
                        onChange={(event) => {
                            setAgreed(event.target.checked);
                        }}
                        name="agreed" color="primary" />
                } />
        </>
    );
    
    const acceptSaveCard = (
        <FormControlLabel label="Save card for future use" className="agree-button"
            control={
                <Checkbox
                    checked={saveCard}
                    onChange={async (event) => {
                        console.log(event.target.checked)
                        setSaveCard(event.target.checked);
                        await fetchClientSecret(event.target.checked);
                    }}
                    name="saveCard" color="primary" />
            } />
    );

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {props.paymentMethod === undefined &&
                <>
                    <p>{AppContext.r["pay-by-card"]}</p>
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />

                    {acceptSaveCard}
                </> }

            {acceptConds}

            <button id="submit" className="checkout-button"
                disabled={processing || disabled || succeeded || !agreed}>
                <span id="button-text">
                {processing ? <div className="spinner" id="spinner"></div>
                    : <>{AppContext.r["pay"] + " " + props.amount}</> }
                </span>
            </button>

            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>)}

            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded, see the result in your
                <a href={`https://dashboard.stripe.com/test/payments`}>
                {" "}Stripe dashboard.
                </a> Refresh the page to pay again.
            </p>
        </form>
    );
}

export default CheckoutForm;