import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
 
import AppContext from '../app/AppContext';
import Counter from '../components/Counter';
import Review from "framework/Review";
import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
// import { priceTag } from "./Cart/Item";

class Product extends Component
{
    state = {
        inCart: this.props.inCart
    };

    get cartItem() {
        const { product, cart } = this.props;
        const items = cart.filter(item => item.product.id === product.id);
        
        return (items && items.length === 1) ? items[0] : null;
    }
 
    addToCart = (e) => {
        if(e && e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }
 
        this.props.addToCart(this.props.product, 1, [], "")
    }

    removeFromCart = (e) => {
        const cartItem = this.cartItem;

        if(cartItem)
            this.props.removeFromCart(cartItem)
    }

    onClickProduct = () => {
        if(this.props.onClickProduct)
            this.props.onClickProduct(this.props.product);
    }

    render() {
        const { product, cart } = this.props;

        let quantity = 0;

        for(let item of cart)
            if(item.product.id === product.id)
                quantity += item.quantity;
        
        // const priceLabel = priceTag(this.props);

        const image = !product.image ? 
            <img className="placeholder" src={AppContext.s["placeholder"]} loading="lazy" alt={product.name} />
            : <img src={AppContext.s["api-url"] + "/stores/product/"+product.id+"/image/thumb"} loading="lazy" alt={product.name} />;

        return (
            <figure className="card card-product" onClick={this.onClickProduct}>
                <div className="img-wrap">
                    {image}
                </div>

                <figcaption className="info-wrap">
                    <h4 className="title">{product.title}</h4>

                    {/* {priceLabel} */}
                </figcaption>

                { this.props.enableCart && 
                    <div className="bottom-wrap">
                        <Review rating={product.rating} />

                        { quantity > 0 ?
                            <Counter onIncrease={this.addToCart} onDecrease={this.removeFromCart} value={quantity} />
                            : <Button variant="contained" color="primary" 
                                onClick={this.addToCart} className="add-to-cart-button">
                                    {AppContext.r["add-to-cart"]}
                                </Button> }
                    </div> }
            </figure>
        )
    }
}
 
const mapStateToProps = (state) => {
    return {
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product, quantity, extras, note) => {
            dispatch(cart.actions.addToCart(product, quantity, extras, note));
        },
        removeFromCart: (cartItem) => {
            dispatch(cart.actions.removeFromCart(cartItem.cartItemId));
        }
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(Product);