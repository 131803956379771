import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import AppContext from 'app/AppContext';
import AuthApi from "api/Auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class ComingSoon extends React.Component {
    state = {
        notification: null
    };

    verifyToken = async () => {
        const response = await AuthApi.verify(this.props.token);
        
        if(response && response.status === 200) {
            this.setState({
                notification: AppContext.r["account-verified"]
            });
        }
    }

    async componentDidMount() {
        console.log(this.props.token, this.props)
        if(this.props.token)
            this.verifyToken();
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            notification: null
        })
    };

    render () {
        return (
            <div className="coming-soon">
                <img src="/assets/woolsafe/images/logo.png" alt="WoolSafe" />

                <h2>Coming Soon</h2>

                <Snackbar open={this.state.notification} autoHideDuration={6000} onClose={this.handleCloseNotification}>
                    <Alert onClose={this.handleCloseNotification} severity="success">
                        {this.state.notification}
                    </Alert>
                </Snackbar>
            </div>);
    }
}