import React from 'react';

import AppContext from '../../app/AppContext';
import loadEnResources from './resources';

export default function loadClientResources (resources) {
    loadEnResources(resources);
    
    resources["quiz-rules-doc"] = (
        <div>
            <h1>Regolamento Quiz {AppContext.s["project-name"]}</h1>
        
            <hr class="small" />

            <p>{AppContext.s["project-name"]} è un gioco a quiz "social" che coinvolge i giocatori tramite l’utilizzo del proprio smartphone, tablet o PC.</p> 
                
            <h2>Come Funziona</h2>
            <p>Il gioco è accessibile tramite un indirizzo web, potrebbe richiedere l'acquisto di un ticket di partecipazione, e richiede la registrazione all’evento in corso, con un numero massimo di giocatori partecipanti variabile da evento a evento. Durante l’avanzamento del gioco, il giocatore si troverà a rispondere a diverse domande di cultura generale, che possono presentare contenuti multimediali e che possono essere raggruppate in “manches”.</p>
            
            <h2>Punteggio</h2>
            <p>L’interazione del giocatore comporta l’assegnazione di un punteggio: positivo in caso di risposta corretta o negativo in caso di risposta scorretta.</p>
                
            <h2>Tempistica</h2>
            <p>Il tempo di risposta influisce sull’assegnazione del punteggio, privilegiando chi risponde correttamente e velocemente. Le tempistiche di gioco possono variare di volta in volta, anche a seconda del contenuto. Una risposta fuori tempo comporterà l’assegnazione del punteggio zero. </p>
                
            <h2>Classifica</h2>
            <p>La classifica finale è data dalla sommatoria dei punteggi acquisiti da ogni giocatore durante ogni manches giocata oppure da un sistema ad “eliminazione” che seleziona dei giocatori scelti per la competizione finale. L’amministrazione si riserva a sua discrezione di scegliere l’algoritmo di calcolo dei punteggi finali di volta in volta.</p>
                
            <h2>Personalizzazione Quiz</h2>
            <p>Il social quiz di {AppContext.s["project-name"]} si presta alla personalizzazione delle domande per accrescere la brand awareness dell’attività e l’engagement dei giocatori partecipanti che potranno essere premiati per aver risposto correttamente alle domande inerenti all’attività.</p>
                
            <h2>Proiezione Quiz</h2>
            <p>L’uso facoltativo di uno o più maxi schermi o proiettori offrirà una migliore e più immersiva esperienza di gioco.</p> 
            
            <h2>Chat In Real Time</h2>
            <p>I partecipanti al gioco possono chattare tra di loro tramite la chat di gruppo, costituendo così una <b>virtual room social</b>.</p>

            <hr />

            <p>In un mondo in cui le interazioni sociali sono l'unico vero valore aggiunto, noi di <a href="https://www.uderly.com" target="_blank" rel="noopener noreferrer">Uderly</a> abbiamo la tecnologia per avvicinare le persone e fornire un servizio in modo unico e innovativo.</p>
        </div>
    );
}