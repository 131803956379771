import React from 'react';
import { connect } from 'react-redux';

import ProductList from './ProductList';
import ProductDetails from './ProductDetails';
import {withModal} from 'framework/Modal';

import {updateProducts} from "app/modules/ECommerce/_redux/products/productsActions.js";

class Products extends React.Component {
    state = {
        hasProducts: false
    };

    async componentDidMount() {
        // console.log(this.props.store)
        // this.props.updateProducts(null, []);

        // Update Redux Products
        if(this.props.store && this.props.store.products)
            this.props.updateProducts(null, this.props.store.products);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.store && nextProps.store.products !== this.props.store.products) {
            this.props.updateProducts(null, nextProps.store.products);
        }
    }

    onClickProduct = (product) => {
        const modalView = <ProductDetails product={product} 
            enableCart={false} onAddedToCart={this.onAddedToCart} />

        this.props.modal.setView(this.props.store.name, modalView, true, "product-details-modal");
    }

    render() {
        if(!this.props.products)
            return "";

        return (<ProductList onClickProduct={this.onClickProduct} 
                    enableCart={false} service={null} />);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProducts: (totalCount, products) => {
            // console.log(totalCount, products)
            dispatch(updateProducts(totalCount, products));
        }
    }
};

const mapStateToProps = (state) => {
    return {
        products: state.products.entities,
        cart: state.cart.cart
    }
};

export default withModal(connect(mapStateToProps, mapDispatchToProps)((Products)));