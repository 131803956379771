import React from 'react';
import Slider from "react-slick";

import AppContext from 'app/AppContext';

class BookStand extends React.Component {
    shelfJsx = (b) => {
        return (
            <div className="shelf">
                { b && 
                    <a className="book" href={AppContext.s["api-url"] + "/assets/" + b.id + "/media/download?fileName=" + b.items[0].file_name} 
                        target="_blank" rel="noopener noreferrer">
                        {b.image && 
                            <img src={AppContext.s["api-url"] + "/assets/" + b.id + "/image/thumb"} /> }
                    </a> }
            </div>);
    }

    sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
    };

    render() {
        const {books} = this.props; // to const

        if(!books || books.length === 0)
            return "";

        // books = [
        //     ...books,
        //     // ...books,
        //     // ...books,
        //     // ...books,
        // ];

        let counter = 0;
        const shelvedBooks = [];
        shelvedBooks[0] = [];
        
        let index = 0;
        for(let b of books) {
            if(counter % 4 === 0) {
                index = counter/4;
                shelvedBooks[index] = [];
            }

            shelvedBooks[index].push(b);

            counter++;
        }

        const units = [];
        let i = 0;

        for(let shelfBooks of shelvedBooks) {
            const shelfBooksMap = [];

            for(let i = 0; i < 4; i++)
                shelfBooksMap.push(this.shelfJsx(shelfBooks[i]))

            units.push(
                <div className="unit" key={i++ + "unit-item"}>
                    {shelfBooksMap}
                </div>
            );
        }

        if(this.props.enableSliding)
            return (
                <Slider className="books-stand" {...this.sliderSettings}>
                    {units}
                </Slider>
            );

        return (
            <div className="books-stand">
                {units}
            </div>
        );
    }
}

export default BookStand;