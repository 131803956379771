import React from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import MapIcon from '@material-ui/icons/Map';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import Slider from "react-slick";

import AppContext from 'app/AppContext';

export default class StoreContacts extends React.Component {
    dragging = false;

    sliderSettings = {
        dots: true,
        infinite: false,
        lazyLoad: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        beforeChange: () => this.dragging = true,
        afterChange: () => this.dragging = false
    };

    contactCard = (contact) => {
        return (
            <>
                <h4>{contact.alias}</h4>

                { (contact.address1 || contact.address2 || contact.post_code || contact.city) &&
                    <div className="contact">
                        <div className="icon"><MapIcon /></div>
                        <div className="field">
                            <p>{contact.address1}
                                {contact.address2 ? <><br />{contact.address2}</> : "" }
                                {(contact.post_code || contact.city) ? <><br />{contact.post_code} {contact.city}</> : "" }
                            </p>
                        </div>
                    </div> }

                {/* { contact.address1 && 
                    <div className="contact">
                        <div className="icon"><MapIcon /></div>
                        <div className="field">{contact.address1}</div>
                    </div> }

                { contact.address2 && 
                    <div className="contact">
                        <div className="icon">&nbsp;</div>
                        <div className="field">{contact.address2}</div>
                    </div> }

                { (contact.post_code || contact.city) && 
                    <div className="contact">
                        <div className="icon">&nbsp;</div>
                        <div className="field">{contact.post_code} {contact.city}</div>
                    </div> } */}

                { contact.email && 
                    <div className="contact">
                        <div className="icon"><MailOutlineIcon /></div>
                        <div className="field"><a href={"mailto:"+contact.email}>{contact.email}</a></div>
                    </div> }

                { contact.phone && 
                    <div className="contact">
                        <div className="icon"><PhoneIcon /></div>
                        <div className="field"><a href={"tel:"+contact.phone}>{contact.phone}</a></div>
                    </div> }

                { contact.website_url && 
                    <div className="contact">
                        <div className="icon"><LanguageIcon /></div>
                        <div className="field"><a href={"//" + contact.website_url}
                            target="_blank">{contact.website_url}</a></div>
                    </div> }
            </>);
    }

    render() {
        const { store } = this.props;
        
        return (
            <div className="contacts">
                <h3 style={this.props.companyStyle["h3"]}>{AppContext.r["contact-us"]}</h3>

                { store.contacts &&
                    <div onClick={(e) => e.stopPropagation()}>
                        <Slider ref={slider => (this.slider = slider)} {...this.sliderSettings}>
                            {store.contacts.map(contact => <div onClick={(e)=> this.dragging && e.preventDefault()} 
                                key={"contact-" + contact.id}>
                                {this.contactCard(contact)}
                            </div>)}
                        </Slider>
                    </div> }
            </div>);
    }
}