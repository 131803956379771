import Axios from 'axios';

import AppContext from '../app/AppContext';

export default class Core {
    static errorLog(error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        return (error.response) ? error.response : null;
    }
    
    static async fetchAsync (url, fieldToMap) {
        const res = await Axios.get(url);
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        if(fieldToMap !== undefined && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }

        return data;
    };
    
    static async fetchItem(url, callback, cancelToken = null) {
        console.log("Fetch " + url)
        const config = {
            // cancelToken: this.CancelTokenSource.token,
            //headers: { Authorization: AppContext.Auth.bearer }
        };

        if(cancelToken)
            config.cancelToken = cancelToken;

        Axios.get(url, config)
            .then(response => {
                if(callback)
                    callback(response.data.data);
            })
            .catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log('error ' + error);
                }
            });
    }

    static async createItem(url, values) {
        console.log("POST", values);

        return await Axios.post(url, values)
                    .catch(function (error) {
                        console.log(error);
                        if (error.response) {
                            console.log(error.response.status);
                            return error.response;
                        }
                    });
    }

    static async updateItem(url, values) {
        console.log("UPDATE", values, AppContext.Auth.bearer);

        return await Axios.put(url, values)
                    .catch(function (error) {
                        console.log(error);
                        if (error.response) {
                            console.log(error.response.status);
                            return error.response;
                        }
                    });
    }

    static async deleteItem(url) {
        console.log("DELETE", url);

        await Axios.delete(url)
                  .catch(function (error) {
                      console.log(error);
                      this.errorLog(error);
                  });
    }

    static async uploadFile(url, formData) {
        const config = {
            headers: {
            'Accept': '*/*',
            //'Content-Length': file.size,
            'Content-Type': "multipart/form-data"
            }
        };
            
        return await Axios.post(url, formData, config)
            .catch((error) => {
                console.log(error);
                this.errorLog(error);

                return error.response;
            });
    }

    static async uploadImageFile(file, mediaTransferApiPath, attributeName = 'image') {
        const config = {
            headers: {
                'Accept': '*/*',
                //'Content-Length': file.size,
                'Content-Type': "multipart/form-data"
            }
        }

        const formData = new FormData();
        formData.append('file', file);

        return await Axios.post(mediaTransferApiPath, formData, config)
            .catch((error) => {
                console.log(error);
                this.errorLog(error);
            })
    }

    static async Countries() {
        return await Axios.get(AppContext.s["api-url"] + "/countries")
            .catch((error) => {
                console.log('error ' + error);
                this.errorLog(error);
            });
    }

    static async fetchCountryOptions() {
        return await this.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
    }

    static async Contacts() {
        var config = { headers: { 'Content-Type': 'application/json' } };
        
        return await Axios.get(AppContext.s["api-url"] + "/contacts", config)
            .catch((error) => {
                console.log('error ' + error);
                this.errorLog(error);
            });
    }

    static async EditUser(id, user) {
        var config = { headers: { 'Content-Type': 'application/json' } };
        console.log("UPDATE " + AppContext.s["api-url"] + "/users/" + id, user);
        
        return await Axios.put(AppContext.s["api-url"] + "/users/" + id, user, config)
            .catch((error) => {
                console.log('error ' + error);
                this.errorLog(error);
            });
    }
    
    static async Apply(request) {
        // console.log(AppContext.s["api-url"] + "/providers/apply", request)
        return await Axios.post(AppContext.s["api-url"] + "/apply", {
            r: request
        }).catch((error) => {
                console.log('error ' + error);
                this.errorLog(error);
                return error.response;
            });
    }
}