export default function loadClientSettings(settings) {
    settings["products-tab-icon"] = "/assets/woolsafe/images/products-logo.png";
    settings["placeholder"] = "/assets/woolsafe/images/logo.png";
    settings["logo-grey"] = "/assets/woolsafe/images/expo-v13.png";
    settings["logo-dark"] = "/assets/woolsafe/images/expo-v13.png";
    settings["logo-light"] = "/assets/woolsafe/images/expo-v13.png";
    settings["mini-logo-dark"] = "/assets/woolsafe/images/expo-v13.png";
    settings["mini-logo-light"] = "/assets/woolsafe/images/expo-v13.png";
    settings["zoom-api-key"] = "dLxvzR5BS8W0ZsBv-7HVbA";
    settings["zoom-api-secret"] = "8CxKUVxrAMQHTOMHHV29wBrbNKNtk6I4PWNv";
}
