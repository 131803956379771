import React from 'react';
import { Container } from 'react-bootstrap';
import MessengerCustomerChat from 'react-messenger-customer-chat';
// import Cookie from 'react-cookies';

import AppContext from 'app/AppContext';
import AuthApi from '../../api/Auth';
import withOverlay from 'framework/withOverlay';
import World from "../3ds/Expo";
import { withModal } from 'framework/Modal';
// import StartupInfo from 'components/StartupInfo';

class Home extends React.Component {
    state = {
        products: null,
        w: window.innerWidth,
        h: window.innerHeight,
        open: (window.innerWidth > 500),
        standsData: this.props.standsData,
        hlStandId: this.props.hlStandId
    };

    // hideInfoAtStartup = () => {
    //     this.props.modal.hide();

    //     const settings = {
    //         hideInfoAtStartup: true
    //     };

    //     Cookie.save('settings', settings, { path: '/' });
    // }

    async componentDidMount() {
        window.addEventListener("resize", this.onResize);

        // this.props.setNotification(AppContext.r["account-verified"]);

        if(this.props.token) {
            const response = await AuthApi.verify(this.props.token);
            
            if(response && response.status === 200) {
                this.props.setNotification(AppContext.r["account-verified"]);
                AppContext.history.push("/");
            }
        }
        
        // // Cookie.remove('settings');
        // const settings = Cookie.load("settings");

        // if(!settings || !settings.hideInfoAtStartup) { 
        //     this.props.modal.setView("Welcome to WoolSafe Grand Expo", <StartupInfo onComplete={this.hideInfoAtStartup} />, true, "wide");
        // }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        this.setState({
            w: window.innerWidth,
            h: window.innerHeight,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.hlStandId !== this.props.hlStandId) {
            this.setState({
                hlStandId: nextProps.hlStandId
            });
        }
    }

    onStandClick = (stand) => {
        const n = stand.subdomain ? stand.subdomain : stand.name;

        AppContext.history.push("/"+ n);
    }

    render() {
        const {standsData, hlStandId} = this.state;

        if(!standsData)
            return AppContext.r["preloader"];

        return (
            <Container fluid className="home">
                <World stands={standsData} hlStandId={hlStandId} onStandClick={this.onStandClick} />
                
                {!AppContext.isLocalhost && AppContext.s["facebook-app-id"] &&
                    <MessengerCustomerChat htmlRef={window.location.pathname}
                        pageId={AppContext.s["facebook-page-id"]} appId={AppContext.s["facebook-app-id"]} /> }
            </Container>
        );
    }
}

export default (withOverlay(withModal(Home)));