import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import AppContext from 'app/AppContext';
import Auth from 'api/Auth';

export default class ChangePassword extends React.Component {

    state = {
        modalBusy: false,
        modalMessage: null
    };

    changePassword = async (password, newPassword) => {
        this.setState({
            modalBusy: true
        });

        const response = await Auth.changePassword(password, newPassword);
        console.log(response);

        let message = "";

        if(response) {
            if (response.status === 200) {
                this.setModalFree();

                if(this.props.onPasswordChanged)
                    this.props.onPasswordChanged(response);
            } else {
                if(response.status === 401) { // Authorization Failed
                    message = <div>
                        <p><i className="far fa-frown"></i> {AppContext.r["wrong-password"]}</p>
                        <Button onClick={this.setModalFree}>{AppContext.r['close']}</Button>
                    </div>;
                }

                if(response.status === 400 || response.status === 500) // Bad Request or Internal Server Error
                    message = response.data;
            }
        } else {
            message = AppContext.r['server-not-working'];
        }

        this.setState({
            modalBusy: false,
            modalMessage: message
        });
    }

    setModalFree = () => {
        this.setState({
            modalBusy: false,
            modalMessage: null
        });
    }

    modalOverlay = () => {
        const overlayStyle = (this.state.modalBusy || this.state.modalMessage != null) ? { display: "block" } : { display: "none" };
        return (
            <div className="modal-overlay" style={overlayStyle}>
                <div style={this.state.modalBusy === true ? { display: "block" } : { display: "none" }}>{AppContext.r['preloader']}</div>
                <div className="centered">
                    {this.state.modalMessage}
                </div>
            </div>);
    }

    render() {
        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required']),
            new_password: Yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required']),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref('new_password'), null], AppContext.r['passwords-must-match'])
                .required(AppContext.r['password-required'])
        });

        const t = this;

        return <div className="auth-module change-password">
            {/* <header className="modal-title">
                <i className="fas fa-key"></i>
                <h1>{AppContext.r["change-password"]}</h1>
            </header> */}

            <p>{AppContext.r["all-fields-required"]}</p>
            <p>{AppContext.r["password-desc"]}</p>

            <Formik
                initialValues={{
                    password: '',
                    new_password: "",
                    password_confirmation: "",
                }}
                validationSchema={validationSchema}
                onSubmit={fields => {
                    console.log(fields)

                    t.changePassword(fields.password, fields.new_password);
                }}
                render={({ errors, status, touched }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="new_password">{AppContext.r["new-password"]}</label>
                            <Field name="new_password" type="password" className={'form-control' + (errors.new_password && touched.new_password ? ' is-invalid' : '')} />
                            <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password_confirmation">{AppContext.r["new-password-confirmation"]}</label>
                            <Field name="password_confirmation" type="password" className={'form-control' + (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')} />
                            <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary mr-2">{AppContext.r["change-password"]}</button>
                        </div>
                    </Form>
                )}
            />

            {this.modalOverlay()}
        </div>;
    }
}
