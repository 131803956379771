import React from 'react';
import { Container, Form, Col, Row, Form as BSForm } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { connect } from "react-redux";
import * as Yup from 'yup';
import { FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Switch } from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';

import * as auth from "../_redux/authRedux";
import AppContext from 'app/AppContext';
import FormImage from 'framework/FormImage';
import ChangePassword from '../partials/ChangePassword';
import DeleteAccount from '../partials/DeleteAccount';
import { withModal } from 'framework/Modal';
import withOverlay from 'framework/withOverlay';
import CoreApi from "api/Core";
import RemoteSelect from 'framework/RemoteSelect';

AppContext.yupAddPhoneTest(Yup);

class MyAccount extends React.Component {
    state = {
        countryOptions: []
    }
    
    async componentDidMount() {
        this.setState({
            countryOptions: await CoreApi.fetchCountryOptions()
        });
    }

    validationSchema = Yup.object().shape({
        name: Yup.string().required(AppContext.r["required-field"]),
        surname: Yup.string().required(AppContext.r["required-field"]),
        email: Yup.string().email(),
        allow_newsletter: Yup.bool(),
        mobile_phone: Yup.string().phone().nullable()
    });

    formImageRef = React.createRef();

    updateUser = async (values) => {
        this.props.overlay.setView("");
        this.props.overlay.showBusy();
        
        // Update user
        const user = {
            id: this.props.auth.user.id,
            ...values
        };
        delete user["email"];

        // console.log(user);

        await CoreApi.EditUser(this.props.auth.user.id, user);

        const updatedUser = { ...this.props.auth.user };

        for(var key in user) {
            updatedUser[key] = user[key];
            // console.log(key + " = " + user[key] )
        }

        // TODO: Check if response is ok
        
        // Update cached user data
        this.props.fulfillUser(updatedUser);

        const view = (
            <>
                <i className="far fa-check-circle icon"></i>
                <p className="message">{AppContext.r["profile-saved"]}</p>
            </>);

        this.props.overlay.setView(view, true, true);
    }

    onImageFileChanged = async (file, attributeName = 'image') => {
        console.log(AppContext.s["api-url"] + "/users/media")
        const response = await CoreApi.uploadImageFile(file, AppContext.s["api-url"] + "/users/media", attributeName);
        // await CoreApi.EditUser(user.id, user);
        console.log(response);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                image_name: file.name
            };

            item[attributeName] = response.data.name;
            // console.log(item);

            await CoreApi.EditUser(this.props.auth.user.id, item);
            // await t.update(item, attributeName === 'image', attributeName === 'cover');
        }
    }

    onDeleteImage = async () => {
        await CoreApi.EditUser(this.props.auth.user.id, {image: "-1"});
        
        this.formImageRef.current.resetImage();
    }
    
    onChangePassword = () => {
        this.props.modal.setView(<><i className="fas fa-key"></i> {AppContext.r["change-password"]}</>, <ChangePassword modal={this.props.modal} onPasswordChanged={this.onPasswordChanged} />, true);
    }

    onPasswordChanged = (response) => {
        console.log(response);
        this.props.modal.hide();
    }

    onDeleteAccount = () => {
        this.props.modal.setView(<><i className="fas fa-user-alt-slash"></i> {AppContext.r["delete-account"]}</>, <DeleteAccount modal={this.props.modal} onAccountDeleted={this.onAccountDeleted} />, true);
    }

    onAccountDeleted = async (response) => {
        // alert(AppContext.r['account-deleted']);
        //console.log(response);
        //AppContext.Auth.logout();

        // this.setState({
        //     redirectTo: "/",
        // });
    }

    render() {
        let view = <p className="text-center">{AppContext.r["must-be-logged"]}</p>;

        if(this.props.auth.user && this.state.countryOptions.length > 0) {
            const t = this;

            const cachedUser = { 
                ...this.props.auth.user, 
                allow_newsletter: this.props.auth.user.allow_newsletter ? true : false
            };

            view = (
                <Formik 
                    validationSchema={this.validationSchema} enableReinitialize
                    initialValues={cachedUser}
                    onSubmit={fields => {
                        console.log(fields);
                        this.updateUser(fields);
                    }}
                    render={({ errors, handleChange, touched, setFieldValue, values, handleSubmit }) => (
                        <FormikForm onSubmit={handleSubmit}>
                            <Row className="justify-content-md-center">
                                <Form.Group as={Col} md="auto">
                                    <FormImage ref={t.formImageRef}
                                        rounded type="avatar" height={192} maxWidth={192}
                                        imageUrl={(this.props.auth.user) ? AppContext.s['api-url'] + "/users/" + this.props.auth.user.id + "/image" : ""}
                                        onDelete={t.onDeleteImage}
                                        onImageFileChanged={t.onImageFileChanged} />
                                </Form.Group>
                            </Row>
 
                            {/* <Row className="justify-content-center">
                                <Form.Group as={Col} md="6">
                                    <Form.Label className="small-uppercase-title">{AppContext.r["game-name"]}</Form.Label>
                                    <p><small>{AppContext.r["game-name-desc"]}</small></p>
                                    <Field name="gamename" type="text" onChange={handleChange}
                                        placeholder={AppContext.r["game-name"]} className={'form-control' + (errors.gamename && touched.gamename ? ' is-invalid' : '')} />
                                    <ErrorMessage name="gamename" component="div" className="invalid-feedback" />
                                </Form.Group>
                            </Row> */}

                            <br />
                            <p className="small-uppercase-title text-center">{AppContext.r['about-you-desc']}</p>

                            <Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["first-name"]}</Form.Label>
                                    <Field name="name" type="text" onChange={handleChange}
                                        placeholder={AppContext.r["name"]} className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["surname"]}</Form.Label>
                                    <Field name="surname" type="text" onChange={handleChange}
                                        placeholder={AppContext.r["surname"]} className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                                    <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["mobile-phone"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["mobile-phone"]} name="mobile_phone"
                                                value={values.mobile_phone} onChange={handleChange}
                                                isValid={touched.mobile_phone && !errors.mobile_phone} isInvalid={!!errors.mobile_phone} />
                                    <ErrorMessage name="mobile_phone" component="div" className="invalid-feedback" />
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["email"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["email"]} name="email" disabled
                                                value={values.email} onChange={handleChange}
                                                isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                                </Form.Group>
                            </Row>

                            <Row>
                                <BSForm.Group as={Col} md="6">
                                    <BSForm.Label>{AppContext.r["company"]}</BSForm.Label>
                                    <Field name="company" type="text" placeholder={AppContext.r["company"]}  value={values.company}
                                        className={'form-control' + (errors.company && touched.company ? ' is-invalid' : '')} />
                                    <ErrorMessage name="company" component="div" className="invalid-feedback" />
                                </BSForm.Group>

                                <BSForm.Group as={Col} md="6">
                                    <BSForm.Label>{AppContext.r["title"]}</BSForm.Label>
                                    <Field name="title" type="text" placeholder={AppContext.r["title"]} 
                                        className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                                    <ErrorMessage name="title" component="div" className="invalid-feedback" />
                                </BSForm.Group>
                            </Row>

                            <div className="form-group">
                                <label htmlFor="address1">{AppContext.r["address"]}</label>
                                <Field name="address1" type="text" className={'form-control' + (errors.address1 && touched.address1 ? ' is-invalid' : '')} />
                                <ErrorMessage name="address1" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <Field name="address2" type="text" className={'form-control' + (errors.address2 && touched.address2 ? ' is-invalid' : '')} />
                                <ErrorMessage name="address2" component="div" className="invalid-feedback" />
                            </div>

                            <Row>
                                <BSForm.Group as={Col} md="3">
                                    <BSForm.Label>{AppContext.r["post-code"]}</BSForm.Label>
                                    <Field name="post_code" type="text" placeholder={AppContext.r["post-code"]} 
                                        className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')} />
                                    <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
                                </BSForm.Group>

                                <BSForm.Group as={Col} md="5">
                                    <BSForm.Label>{AppContext.r["city"]}</BSForm.Label>
                                    <Field name="city" type="text" placeholder={AppContext.r["city"]} className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')} />
                                    <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                </BSForm.Group>
                                
                                <BSForm.Group as={Col} md="4">
                                    <BSForm.Label>{AppContext.r["country"]}</BSForm.Label>

                                    <RemoteSelect name="country_id" value={values.country_id}
                                        onChange={(e, value) => {
                                            setFieldValue("country_id", value);
                                        }}
                                        options={this.state.countryOptions} />
                                </BSForm.Group>
                            </Row>
                            

                            <Row>
                                <Form.Group as={Col} md="6">
                                    {/* <div className="checkbox-wrapper">-

                                        <Field name="allow_newsletter" type="checkbox" className={"checkbox" + (errors.allow_newsletter && touched.allow_newsletter ? ' is-invalid' : '')} 
                                            value={values.allow_newsletter} onChange={(e) => {
                                                console.log(e.target.value, values);
                                                //setFieldValue("allow_newsletter", e.target.value);
                                                //handleChange();
                                              }} />
                                        <label htmlFor="allow_newsletter">{AppContext.r['allow-newsletter']}</label>
                                    </div> */}

                                    <Box margin={1} className="accept-data-treatment-box">
                                        <FormControlLabel control={<Field component={Switch} type="checkbox" name="allow_newsletter" disabled={false} />} 
                                            label={AppContext.r['allow-newsletter']} />
                                    </Box>
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="display-flex justify-content-center">
                                    <Button type="submit" variant="contained" color="primary" size="normal" startIcon={<SaveIcon />}>{AppContext.r["save"]}</Button>
                                </Form.Group>
                            </Row>

                            <hr />

                            <Row className="justify-content-center">
                                <Button onClick={this.onChangePassword}>
                                    <i className="fas fa-key"></i> &nbsp;{AppContext.r['change-password']}
                                </Button>

                                <Button onClick={this.onDeleteAccount}>
                                    <i className="fas fa-user-alt-slash"></i> &nbsp;{AppContext.r['delete-account']}
                                </Button>

                                <br /><br />
                            </Row>
                        </FormikForm>
                    )} />
                );
        }

        return (
            <Container className="page">
                <h2 className="text-center">{AppContext.r["my-account"]}</h2>

                <div className="myaccount">
                    {view}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, auth.actions)(withModal(withOverlay(MyAccount)));