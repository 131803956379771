import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MobileStepper from '@material-ui/core/MobileStepper';

import Logo from "components/Logo";

export default class StartupInfo extends React.Component {
    state = {
        activeStep: 0
    };

    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1
        });
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        });
    };

    onComplete = () => {
        if(this.props.onComplete)
            this.props.onComplete();
    }
    
    render() {
        const { activeStep } = this.state;

        const steps = [
            {
                label: 'Welcome',
                view: (
                    <>
                        <br />

                        <Logo />

                        <Row>
                            <Col sm={8}>
                                {/* <h1>Welcome to WoolSafe Grand Expo</h1> */}
                                <p className="text-center">GrandExpos.com is a 3D tradeshow, networking and educational platform for all cleaning and flooring industry stakeholders. Exhibitors are WoolSafe, CleanSeal and EnviroSeal Approved product manufacturers and WoolSafe Associate Carpet Manufacturers. </p>
                                <p className="small-center-caption"><small>The following information can also be downloaded from the Information Stand to the right of the stage.</small></p>
                            </Col>

                            <Col sm={4}>
                                <img className="info-desk" src="/assets/images/info-desk.jpg" alt="Info Desk" />
                            </Col>
                        </Row>
                    </>
                )
            },
            {
                label: 'Navigation',
                view: (
                    <>
                        <h2>How to navigate the tradeshow</h2>
                        <p><b>On a PC or Laptop</b> click anywhere on the map and move the mouse to move around the exhibition floor or click on the marker flags to jump to that area. 
                            You can zoom in and out with the mouse wheel if you have one or with the – and + buttons bottom left of the screen. Next to them is the reset view button. </p>
                        
                        <p><b>On smart phones and tablets</b> touch anywhere on the map and move your finger to move around the exhibition floor or click on the marker flags to jump to that area. 
                            Zoom in and out by pinching and un-pinching or with the – and + buttons bottom left of the screen. Next to them is the reset view button. </p>
                    
                        <h2>Side Bar</h2>
                        <p>A list of exhibitors can also be found in the side bar on the right, click on the three bars in the top right of the screen to make the list visible and again to hide.</p>
                    </>
                )
            },
            {
                label: 'Action',
                view: (
                    <>
                        <h2>Visit an Exhibitor</h2>
                        <p>Double click on any stand to visit and view products, download brochures, watch video demonstrations, contact a member of the team and join live meeting and demos (during live expos). 
                            Swipe left or right to move through the exhibitor stands or return to the map or sidebar list to select your next exhibitor. </p>

                        <h2>Attend a Seminar</h2>
                        <p>Click on the Stage to view the upcoming seminars and the countdown clock to the start of an event. 
                            You can also check out what events are coming up by clicking on the calendar icon in the top left corner of the screen. </p>
                        
                        <h2>Networking with other visitors</h2>
                        <p>Double-click on the café stand, to the left of the stage, and click the ‘take a seat on Zoom’ button in the bottom left corner of the screen (during live events). You will then enter a zoom meeting with other visitors and exhibitors. 
                            To see future networking dates, click on the products tab from within the cafe. Take a magazine from the rack, enjoy a chat and relax. </p>

                        <p><small>For more information about Grand Expos, upcoming events and training courses please visit the Info stand to the right of the stage.</small></p>
                    </>
                )
            }
        ];

        return (
            <div className="info-modal">
                <Stepper activeStep={activeStep}>
                    {steps.map(({ label }) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                {steps.map((step, index) => {
                    return activeStep === index ? step.view : "";
                })}

                <MobileStepper steps={steps.length} position="static" variant="text" activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={this.handleNext} disabled={activeStep === steps.length - 1}>
                            Next <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft /> Back
                        </Button>
                    } />

                <div className="hide-at-startup">
                    <Button onClick={this.onComplete}
                        endIcon={<CheckCircleOutlineIcon />}>Do not show at startup</Button>
    
                    <p className="small text-center">
                        By saving this preference you accept the usage of cookies.<br />
                        {/* You can always access this view by clicking on the info desk on the map. */}
                    </p>
                </div>
            </div>
        );
    }
}