import React from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withModal } from '../framework/Modal';
import { format } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz';

import AppContext from 'app/AppContext';
import WoolSafeApi from 'api/WoolSafe';
import Countdown from '../framework/Countdown';
import BuyTicket from 'components/BuyTicket';

class EventCards extends React.Component {
    state = {
        events: this.props.events
    };

    async componentDidMount() {
        if(!this.props.events) {
            const eventsResponse = await WoolSafeApi.Events(1, 1, this.props.typeId);

            if(eventsResponse && eventsResponse.status === 200) {
                this.setState({
                    events: eventsResponse.data.data
                });
            }
        }
    }

    buyTicket = (event) => {
        this.props.modal.setView(AppContext.r["buy-ticket"], 
            <BuyTicket modal={this.props.modal} event={event} store={this.props.store} authActions={this.props.authActions} />, true);
    }

    card(event, past = false) {
        const imageUrl = AppContext.s['api-url'] + "/events/"+event.id+"/image/thumb";

        console.log(event)

        return (
            <Card className="card">
                <Link to={"/event/"+event.id} onClick={() => { if(this.props.modal) this.props.modal.hide(); }}>
                    <CardActionArea>
                        <div className="image-box">
                            <CardMedia component="img" alt={event.title} height="140"
                                className={event.image ? "" : "placeholder"}
                                image={event.image ? imageUrl : AppContext.s["placeholder"]}
                                title={event.title} />
                        </div>
                        {console.log(event.date_time, event.date_time.substr(0, 19))}
                        <CardContent>
                            {event.type && <h4>{event.type.type}</h4>}
                            {/* <h3>{event.date_time && <>{format(new Date(event.date_time.substr(0, 19)), "d MMM yyyy - H:mm")} GMT</>}</h3> */}
                            {/* <h3>{event.date_time && <>{format(new Date(event.date_time), "d MMM yyyy - H:mm")} GMT</>}</h3> */}
                            {/* <h3>{event.date_time && format(new Date(event.date_time.substr(0, 19)), "d MMM yyyy - H:mm")}</h3> */}
                            <h3>{event.date_time && <>{formatInTimeZone(new Date(event.date_time), "Europe/London", "d MMM yyyy - H:mm")} GMT</>}</h3>
                            
                            <Typography gutterBottom variant="h5" component="h2">
                                {event.title}
                            </Typography>
                            
                            <Countdown timespan={event.countdown} minutesLabel="min" secondsLabel="sec" />
                            
                            <Typography variant="body2" color="textSecondary" component="p">
                                {event.description && event.description.replace(/<[^>]*>?/gm, '').substr(0, 100) + "..."}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Link>

                <CardActions className="actions">
                    <Button size="small" color="primary" className="view-button"
                        onClick={() => AppContext.history.push("/event/"+event.id)}>VIEW</Button>

                    { !past && event.price > 0 &&
                        <Button size="small" color="primary" 
                            onClick={(e) => {
                                this.buyTicket(event);
                                e.stopPropagation();    
                            }}>{AppContext.r["buy-ticket"]}</Button> }
                </CardActions>

                {/* <CardActions>
                    {(game && game.game_status > GameStatusEnum.NotStarted) &&
                        <span className="live">LIVE</span> }

                    {(!past && store.licence_id === LicencesEnum.Standard && 
                        (!game || game.game_status < GameStatusEnum.Completed)) &&
                            <Button size="small" color="primary" 
                                onClick={() => this.buyTicket(event)}>{AppContext.r["buy-ticket"]}</Button> }
                </CardActions> */}
            </Card>
        );
    }

    render() {
        const { events } = this.state;

        if(!events)
            return AppContext.r["preloader"];

        return (
            <div className="events">
                <div className="list">
                    {events.length === 0 && <p>{AppContext.r["no-upcoming-events"]}</p>}
                    {events.map(event => this.card(event))}
                </div>
            </div>);
    }
}

export default withModal(connect(null, null)(EventCards));