// Zoom v2.0
import React from "react";
import { ZoomMtg } from "@zoomus/websdk";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import AppContext from 'app/AppContext';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.3.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

export class MeetingStatusEnum {
    static get Dismissed () { return -1; }
    static get NotStarted () { return 0; }
    static get Starting () { return 1; }
    static get Started () { return 2; }
}

// The Zoom React Component returns the UI to join a meeting and
// implements the code to init Zoom web sdk
export default class Zoom extends React.Component {
    DEFAULT_CONFIG = {
        apiKey: AppContext.s["zoom-api-key"],
        apiSecret: AppContext.s["zoom-api-secret"],
        meetingNumber: null,
        userName: "Anonymous",
        passWord: null,
        userEmail: "",
        leaveUrl: window.location.href,
        role: 0, // 0 => Participant, 1 => Host
        lang: "en-US"
    };

    meetingConfig = null;

    state = {
        status: (AppContext.zoomEvent && AppContext.zoomEvent.started) ? MeetingStatusEnum.Started : MeetingStatusEnum.NotStarted
    };

    constructor(props) {
        super(props);
        
        this.meetingConfig = Object.assign({}, this.DEFAULT_CONFIG, props.config ? props.config : {});
        this.meetingConfig.meetingNumber = this.meetingConfig.meetingNumber.replace(/ /g, "");

        // console.log(this.meetingConfig);
    }

    getSignature = () => {
        ZoomMtg.generateSignature({
            meetingNumber: this.meetingConfig.meetingNumber,
            apiKey: this.meetingConfig.apiKey,
            apiSecret: this.meetingConfig.apiSecret,
            role: this.meetingConfig.role,
            success: (res) => {
              console.log(res.result);

              this.meetingConfig.signature = res.result;
            //   //this.meetingConfig.apiKey = API_KEY;
            //   const joinUrl = "/meeting.html?" + testTool.serialize(this.meetingConfig);
            //   console.log(joinUrl);
            //   window.open(joinUrl, "_blank");

                //setTimeout(() => {
                    this.beginJoin(res.result);
                //}, 100);
            },
          });
    }

    beginJoin(signature) {
        document.getElementById('zmmtg-root').style.display = 'block';

        ZoomMtg.init({
          leaveUrl: this.meetingConfig.leaveUrl,
          disableCORP: !window.crossOriginIsolated, // default true
          // disablePreview: false, // default false
          success: () => {
            console.log(this.meetingConfig);
            console.log("signature", signature);
            //ZoomMtg.i18n.load(this.meetingConfig.lang);
            //ZoomMtg.i18n.reload(this.meetingConfig.lang);

            ZoomMtg.join({
              meetingNumber: this.meetingConfig.meetingNumber,
              userName: this.meetingConfig.userName,
              signature: signature,
              apiKey: this.meetingConfig.apiKey,
              userEmail: this.meetingConfig.userEmail,
              passWord: this.meetingConfig.passWord,

              success: (res) => {
                console.log("join meeting success");

                ZoomMtg.getAttendeeslist({});

                ZoomMtg.getCurrentUser({
                  success: function (res) {
                    //console.log("success getCurrentUser", res.result.currentUser);
                  },
                });
              },
              error: (res) => {
                console.log(res);
              },
            });
          },
          error: (res) => {
            console.log(res);
          },
        });
      
        // ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
        //   console.log('inMeetingServiceListener onUserJoin', data);
        // });
      
        ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
          console.log('inMeetingServiceListener onUserLeave', data);
        });
      
        ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
          console.log('inMeetingServiceListener onUserIsInWaitingRoom', data);
        });
      
        ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
          console.log('inMeetingServiceListener onMeetingStatus', data);
        });
    }

    dismiss = () => {
        this.setState({
            status: MeetingStatusEnum.Dismissed
        });
    }

    onClickJoin = (e) => {
        if(e) e.preventDefault();

        if(this.state.status >= MeetingStatusEnum.Started)
            return;

        if(this.props.onJoin)
            this.props.onJoin();

        this.getSignature(e);
    }

    render() {
        const { status } = this.state;
        const { dismissable, autoJoin, showDirectLink, disableMeetingInApp, onJoin } = this.props;
        const { meetingNumber } = this.meetingConfig;
        
        return (
            <div id="joinZoom" className="zoom">
                {(!autoJoin && status === MeetingStatusEnum.NotStarted) && dismissable && 
                    <Chip label={AppContext.r['join-zoom-event']} 
                        onClick={this.onClickJoin} onDelete={this.dismiss} /> }

                {(!autoJoin && status === MeetingStatusEnum.NotStarted) && !dismissable && 
                    <>
                        { !disableMeetingInApp && 
                            <Button variant="contained" onClick={this.onClickJoin}>{AppContext.r['join-zoom-event']}</Button>
                        }

                        { showDirectLink && 
                            <>
                                { !disableMeetingInApp && <small>{AppContext.r['or']}</small> }
                                <a href={"https://us02web.zoom.us/j/"+meetingNumber}
                                    target="_blank">{AppContext.r['by-zoom-link']}</a>
                            </> }
                    </> }
            </div>
        );
    }
}