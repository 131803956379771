import React from 'react';
import Button from '@material-ui/core/Button';
// import ReactPlayer from 'react-player';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Cookie from 'react-cookies';

import VideoGallery from "components/VideoGallery";

export default class WelcomeVideo extends React.Component {

    constructor(props) {
        super(props);
        
        this.settings = Cookie.load("settings");
    }

    onComplete = () => {
        if(this.props.onComplete)
            this.props.onComplete();
    }
 
    render() {
        const { settings } = this;

        return (
            <div className="info-modal">
                {/* <ReactPlayer controls={true} playing={playing} muted={true} 
                            width={videoWidth} height={videoHeight}
                            url={stream.url} onEnded={this.onEnded} 
                            light={false} /> */}

                <VideoGallery column hideList videoWidth={738} videoHeight={460}
                    streams={[
                        { title: "", url: "https://vimeo.com/702954291" }
                    ]} />

                {(!settings || !settings.hideInfoAtStartup) &&
                    <div className="hide-at-startup">
                        <Button onClick={this.onComplete}
                            endIcon={<CheckCircleOutlineIcon />}>Do not show at startup</Button>
        
                        <p className="small text-center">
                            By saving this preference you accept the usage of cookies.<br />
                            {/* You can always access this view by clicking on the info desk on the map. */}
                        </p>
                    </div> }
            </div>
        );
    }
}