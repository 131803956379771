import React from 'react';
import { Container } from 'react-bootstrap';

import Login from "../modules/Auth/pages/Login";
import withOverlay from "framework/withOverlay";

class Registration extends React.Component {
    state = {
        event: null
    };
    
    async componentDidMount() { 
        // 
    }

    setBusy(busy) {
        console.log(busy)
        this.props.overlay.setBusy(busy)
    }

    setMessage(message) {
        this.props.overlay.setView(message, true)
    }

    render() {
        return (
            <Container className="page">
                <h1>Pre-register to WoolSafe GrandExpos</h1>
                <br /><br />

                <Login registering disableLogin modal={this} inline />
            </Container>
        );
    }
}

export default withOverlay(Registration);