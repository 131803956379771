import React from "react";
import * as THREE from "three";
import { Sky } from './Sky.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { OrbitControls } from "./OrbitControls";
import Hammer from "hammerjs";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import IconButton from '@material-ui/core/IconButton';

import AppContext from "app/AppContext";
import WoolSafeApi from "api/WoolSafe";
import Model from "./Model";
import {ModelEventsEnum} from "./HiModel";
import HiModel from "./HiModel";
import Stand from "./Stand";
import { withModal } from 'framework/Modal';

const enableOrbit = false;

class ViewsEnum {
    static get Main () { return 0; }
    static get Auditorium () { return 1; }
    static get WoolSafeDesk () { return 2; }
    static get Cafe () { return 3; }
    static get LeftWing () { return 4; }
    static get RightWing () { return 5; }
    static get WoolSafeStand () { return 6; }
    static get UderlyStand () { return 7; }
}

// Index in standsData
class SpecialStandsEnum {
    static get WoolSafe () { return { index: 0, id: 1 }; }
    static get Info () { return { index: 1, id: 2 }; }
    static get Cafe () { return { index: 2, id: 3 }; }
    static get Uderly () { return { index: 3, id: 4 }; }
    static get CleanSeal () { return { index: 4, id: 5 }; }
    static get EnviroSeal () { return { index: 5, id: 6 }; }
    static get WowWarranty () { return { index: 6, id: 7 }; }
    static get WoolSafeAcademy () { return { index: 7, id: 8 }; }
}

class World extends React.Component {
    state = {
        loading: true,
    };

    static viewIndex = ViewsEnum.Main;

    static _scene = null;
    static get scene () { return World._scene; }

    static _camera = new THREE.PerspectiveCamera( 50, window.innerWidth/window.innerHeight, 0.1, 1000 );
    static get camera () { return World._camera; }

    static _renderer = new THREE.WebGLRenderer({ antialias: true, alpha: false });
    static get renderer () { return World._renderer; }

    raycaster = new THREE.Raycaster();
    mouse   = new THREE.Vector2();

    enableRaycaster = true;

    centers = [];

    standsData = null;
    stands = [];
    interactiveObjs = [];

    hiModels = [];
    intersectedHiModel = null;

    startIndex = 8; // Non-specials start from index "startIndex" in standsData

    r = 120; // Camera's Distance (z-axis)
    // aRange = { min: 30, max: 150 };
    rRange = { min: 60, max: 150 };
    a = -0.5; // Camera's Angle
    y = 100; // Camera's y-axis
    yRange = { min: 30, max: 150 };


    constructor(props) {
        super(props);

        this.centers.center = new THREE.Vector3(0, 0, 0);
        this.centers.cafe = new THREE.Vector3(-77, 0, -48);
        this.centers.woolsafeDesk = new THREE.Vector3(77, 0, -48);
        this.centers.auditorium = new THREE.Vector3(0, 0, -110);
        this.centers.leftWing = new THREE.Vector3(-135, 0.5, 21);
        this.centers.rightWing = new THREE.Vector3(135, 0.5, 21);
        this.centers.woolsafeStand = new THREE.Vector3(-68, 1, 15);
        this.centers.uderlyStand = new THREE.Vector3(68, 1, 15);
        
        this.center = this.centers.center;
    }

    addSkyBox = () => {
        const { scene } = World;

        let materialArray = [];
        let texture_ft = new THREE.TextureLoader().load( '/assets/textures/city-skybox/negx.jpg');
        let texture_bk = new THREE.TextureLoader().load( '/assets/textures/city-skybox/negy.jpg');
        let texture_up = new THREE.TextureLoader().load( '/assets/textures/city-skybox/negz.jpg');
        let texture_dn = new THREE.TextureLoader().load( '/assets/textures/city-skybox/posx.jpg');
        let texture_rt = new THREE.TextureLoader().load( '/assets/textures/city-skybox/posy.jpg');
        let texture_lf = new THREE.TextureLoader().load( '/assets/textures/city-skybox/posz.jpg');
        
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_ft }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_bk }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_up }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_dn }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_rt }));
        materialArray.push(new THREE.MeshBasicMaterial( { map: texture_lf }));
        
        for (let i = 0; i < 6; i++)
            materialArray[i].side = THREE.BackSide;
        
        let skyboxGeo = new THREE.BoxGeometry( 10000, 10000, 10000);
        let skybox = new THREE.Mesh( skyboxGeo, materialArray );
        scene.add( skybox );
    }

    initSky = () => {
        const { renderer, camera, scene } = World;

        // Add Sky
        const sky = new Sky();
        sky.scale.setScalar( 450000 );
        scene.add( sky );

        const sun = new THREE.Vector3();

        /// GUI

        const effectController = {
            turbidity: 0.005,
            rayleigh: 0.25,
            mieCoefficient: 0.005,
            mieDirectionalG: 0.7,
            elevation: 20,
            azimuth: -100,
            exposure: 0.005//renderer.toneMappingExposure
        };

        function guiChanged() {
            const uniforms = sky.material.uniforms;
            uniforms[ 'turbidity' ].value = effectController.turbidity;
            uniforms[ 'rayleigh' ].value = effectController.rayleigh;
            uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
            uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

            const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
            const theta = THREE.MathUtils.degToRad( effectController.azimuth );

            sun.setFromSphericalCoords( 1, phi, theta );

            uniforms[ 'sunPosition' ].value.copy( sun );

            renderer.toneMappingExposure = effectController.exposure;
            renderer.render( scene, camera );
        }

        // const gui = new GUI();

        // gui.add( effectController, 'turbidity', 0.0, 20.0, 0.1 ).onChange( guiChanged );
        // gui.add( effectController, 'rayleigh', 0.0, 4, 0.001 ).onChange( guiChanged );
        // gui.add( effectController, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( guiChanged );
        // gui.add( effectController, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( guiChanged );
        // gui.add( effectController, 'elevation', 0, 90, 0.1 ).onChange( guiChanged );
        // gui.add( effectController, 'azimuth', - 180, 180, 0.1 ).onChange( guiChanged );
        // gui.add( effectController, 'exposure', 0, 1, 0.0001 ).onChange( guiChanged );

        guiChanged();
    }

    async componentDidMount() {
        const { renderer, camera } = World;
        const { r, a, y } = this;

        World._scene = new THREE.Scene();
        const { scene } = World;

        this.initSky();

        // // Add Sky
        // const sky = new Sky();
        // sky.scale.setScalar( 450000 );
        // scene.add( sky );

        //     const effectController = {
        //         turbidity: 10,
        //         rayleigh: 3,
        //         mieCoefficient: 0.005,
        //         mieDirectionalG: 0.7,
        //         elevation: 2,
        //         azimuth: 180,
        //         exposure: renderer.toneMappingExposure
        //     };

        //     const uniforms = sky.material.uniforms;
        //     uniforms[ 'turbidity' ].value = effectController.turbidity;
        //     uniforms[ 'rayleigh' ].value = effectController.rayleigh;
        //     uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
        //     uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

        // scene.background = new THREE.Color().setHSL( 0.6, 0.5, 1.0 );
        // scene.background = new THREE.Color(0xd1e0e8);
        // scene.fog = new THREE.Fog( 0xeeeeee, 200, 300 );

        // const loader = new THREE.CubeTextureLoader();
        // const texture = loader.load([
        //   '/assets/textures/city-skybox/negx.jpg',
        //   '/assets/textures/city-skybox/negy.jpg',
        //   '/assets/textures/city-skybox/negz.jpg',
        //   '/assets/textures/city-skybox/posx.jpg',
        //   '/assets/textures/city-skybox/posy.jpg',
        //   '/assets/textures/city-skybox/posz.jpg',
        // ]);
        //scene.background = texture;

        // ground
        //const groundMesh = new THREE.Mesh(new THREE.CircleGeometry( 200, 80 ), new THREE.MeshPhongMaterial({ color: 0xffffff, depthWrite: false }));
        //groundMesh.rotation.x = - Math.PI / 2;
        //groundMesh.receiveShadow = true;
        //scene.add( groundMesh );

        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFShadowMap;//THREE.PCFSoftShadowMap;
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.setClearColor(0x0, 0);

        camera.position.y = y;
        camera.position.z = this.center.z + r * Math.cos(a);
        camera.position.x = this.center.x + r * Math.sin(a);
        camera.lookAt(this.center);

        if(enableOrbit) {
            this.orbitControls = new OrbitControls(camera, renderer.domElement);
            this.orbitControls.target.set( 0, 0, 0 );
            this.orbitControls.update();
        }

        const response = await WoolSafeApi.Stands();
        console.log(response);

        this.standsData = (response && response.data) ? 
            response.data.data
            .filter(x => x.category.id > 0)
            .sort(function(a, b) { return a.category.sort_index - b.category.sort_index; })
            : null;

        this.appendEvents();
        this.loadScene();

        // const geometry = new THREE.SphereGeometry( 5, 32, 32 );
        // const material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
        // const sphere = new THREE.Mesh( geometry, material );
        // sphere.position.set(-19, 3, 40 );
        // scene.add( sphere );
        // this.toMove = sphere;

        this.setupLights();

        this.setState({
            loading: false
        });

        // this.screenConsole("Initialized - Hello world");

        this.mount.appendChild(renderer.domElement);
    
        const animate = () => {
            this.animationFrameId = requestAnimationFrame(animate);

            // Required if controls.enableDamping or controls.autoRotate are set to true
            if(enableOrbit)
                this.orbitControls.update();

            this.render3DScene();
        };

        animate();
    }

    setupLights = () => {
        const { scene } = World;

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
        scene.add(ambientLight);

        const dirLight = new THREE.DirectionalLight(0xffffff, 0.6, 100);

        if(!this.isMobile)
            dirLight.castShadow = true; // default false
        
        dirLight.position.set(-20, 15, 20);
        dirLight.position.multiplyScalar(2);

        scene.add(dirLight);

        // setTimeout(() => {
        //     const interval = setInterval(() => {
        //         if(dirLight.intensity < 0.5)
        //             dirLight.intensity += 0.03;
        //         else
        //             clearInterval(interval);
        //     }, 50);
        // }, 2000);

        // dirLight.shadow.mapSize.width = 2048;
        // dirLight.shadow.mapSize.height = 2048;

        const d = 50;

        dirLight.shadow.camera.left = - d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = - d;

        dirLight.shadow.camera.far = 500;

        // Set up shadow properties for the light
        dirLight.shadow.mapSize.width = 512; // default
        dirLight.shadow.mapSize.height = 512; // default
        dirLight.shadow.camera.near = 0.5; // default
        dirLight.shadow.camera.far = 500; // default

        // //Create a sphere that cast shadows (but does not receive them)
        // const sphereGeometry = new THREE.SphereGeometry( 5, 32, 32 );
        // const sphereMaterial = new THREE.MeshStandardMaterial( { color: 0xff0000 } );
        // const sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
        // sphere.position.y = 10;
        // sphere.castShadow = true; //default is false
        // sphere.receiveShadow = false; //default
        // scene.add( sphere );

        // dirLight.shadow.bias = -0.0001;

        // const dirLightHelper = new THREE.DirectionalLightHelper( dirLight, 10 );
        // scene.add( dirLightHelper );
    }

    componentWillUnmount() {
        if(this.animationFrameId)
            cancelAnimationFrame(this.animationFrameId);

        World._scene = null;
    }

    onHiModelLoaded = (model) => {
        const { scene } = World;

        scene.add(model.object);

        if(model.boundingBox)
            scene.add(model.boundingBox);

        this.hiModels.push(model);
    }

    onClickMarker = (marker) => {
        World.viewIndex =  marker.data.viewIndex;

        this.center = marker.data.center;
        //this.pan.y = marker.data.y;
        this.r = 120;
        
        this.setCameraAngle(this.a, marker.data.y);
    }

    generateMarkers () {
        const markers = [
            // Auditorium
            {
                data: {
                    center: this.centers.auditorium,
                    y: this.yRange.min
                },
                transform: {
                    scale: 0.03,
                    position: { x: 0, y: 5, z: -64 },
                    rotation: { x: 0, y: 0, z: 0 },
                },
                viewIndex: ViewsEnum.Auditorium
            },
            // WoolSafe Desk
            {
                data: {
                    center: this.centers.woolsafeDesk,
                    y: 50
                },
                transform: {
                    scale: 0.03,
                    position: { x: 58, y: 5, z: -26 },
                    rotation: { x: 0, y: -0.5, z: 0 },
                },
                viewIndex: ViewsEnum.WoolSafeDesk
            },
            // Cafe
            {
                data: {
                    center: this.centers.cafe,
                    y: 50
                },
                transform: {
                    scale: 0.03,
                    position: { x: -58, y: 5, z: -26 },
                    rotation: { x: 0, y: 0.5, z: 0 },
                },
                viewIndex: ViewsEnum.Cafe
            },
            // WoolSafe Stand
            {
                data: {
                    center: this.centers.woolsafeStand,
                    y: 50
                },
                transform: {
                    scale: 0.03,
                    position: { x: -58, y: 5, z: 6 },
                    rotation: { x: 0, y: 0.5, z: 0 },
                },
                viewIndex: ViewsEnum.WoolSafeStand
            },
            // Left Wing
            {
                data: {
                    center: this.centers.leftWing,
                    y: 80
                },
                transform: {
                    scale: 0.03,
                    position: { x: -128, y: 5, z: 15 },
                    rotation: { x: 0, y: 0.5, z: 0 },
                },
                viewIndex: ViewsEnum.LeftWing
            },
            // Right Wing
            {
                data: {
                    center: this.centers.rightWing,
                    y: 80
                },
                transform: {
                    scale: 0.03,
                    position: { x: 128, y: 5, z: 15 },
                    rotation: { x: 0, y: 0.5, z: 0 },
                },
                viewIndex: ViewsEnum.RightWing
            },
            // Uderly Stand
            {
                data: {
                    center: this.centers.uderlyStand,
                    y: 50
                },
                transform: {
                    scale: 0.03,
                    position: { x: 58, y: 5, z: 6 },
                    rotation: { x: 0, y: -0.5, z: 0 },
                },
                viewIndex: ViewsEnum.UderlyStand
            },
        ];

        let lastModel = null;

        for (let marker of markers) {
            const m = new HiModel(marker.data);
            m.addEventListener(ModelEventsEnum.Loaded, this.onHiModelLoaded);
            m.load("/assets/models/marker.fbx", marker.transform);
            m.setBoundingBox();
            m.onClick = this.onClickMarker;

            lastModel = m;
        }

        setTimeout(() => {
            this.toMove = lastModel.object;
        }, 4000);
    }

    onClickInfoDesk = () => {
        const view = (
            <div className="info-modal">
                {AppContext.r["info"]}
            </div>);

        this.props.modal.setView("Info Point", view, true);
    }

    loadScene = () => {
        new Model(this.add).load("/assets/models/arena.fbx", 0.0153, { x: 0, y: 0, z: -80 }, { x: 0, y: 0, z: 0 });

        this.generateMarkers();
        
        // Cafe
        // const cafe = new HiModel();
        // cafe.addEventListener(ModelEventsEnum.Loaded, this.onHiModelLoaded);
        // cafe.load("/assets/models/cafe.fbx", {
        //     scale: 0.05,
        //     position: { x: -75, y: 1, z: -48 },
        //     rotation: { x: 0, y: 0.88, z: 0 }
        // });
        
        // WoolSafe Stand
        const woolsafe = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.WoolSafe.index]);
        woolsafe.load("/assets/models/kiosk1.fbx", 0.03, { x: -68, y: 1, z: 14.5 }, { x: 0, y: -0.0, z: 0 });

        // WoolSafe Info
        const info = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.Info.index]);
        info.load("/assets/models/kiosk0.fbx", 0.045, { x: 77, y: 1, z: -48 }, { x: 0, y: -0.68, z: 0 });

        // Cafe
        const cafe = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.Cafe.index]);
        cafe.load("/assets/models/cafe.fbx", 0.05, { x: -75, y: 1, z: -48 }, { x: 0, y: 0.88, z: 0 });

        if(this.standsData[SpecialStandsEnum.WoolSafeAcademy.index].active === 1) {
            const m1 = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.WoolSafeAcademy.index]);
            m1.load("/assets/models/kiosk2.fbx", 0.03, { x: -20, y: 1, z: 4 }, { x: 0, y: -Math.PI/2, z: 0 });
        }

        if(this.standsData[SpecialStandsEnum.EnviroSeal.index].active === 1) {
            const m2 = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.EnviroSeal.index]);
            m2.load("/assets/models/kiosk3.fbx", 0.03, { x: 20, y: 1, z: 4 }, { x: 0, y: -Math.PI/2, z: 0 });
        }

        if(this.standsData[SpecialStandsEnum.CleanSeal.index].active === 1) {
            const m3 = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.CleanSeal.index]);
            m3.load("/assets/models/kiosk4.fbx", 0.03, { x: 20, y: 1, z: 45 }, { x: 0, y: -Math.PI/2, z: 0 });
        }

        if(this.standsData[SpecialStandsEnum.WowWarranty.index].active === 1) {
            const m4 = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.WowWarranty.index]);
            m4.load("/assets/models/kiosk5.fbx", 0.03, { x: -20, y: 1, z: 45 }, { x: 0, y: -Math.PI/2, z: 0 });
        }

        if(this.standsData[SpecialStandsEnum.Uderly.index].active === 1) {
            const mUderly = new Model(this.onStandModelLoaded, this.standsData[SpecialStandsEnum.Uderly.index]);
            mUderly.load("/assets/models/uderly-kiosk.fbx", 0.03, { x: 68, y: 1, z: 14.5 }, { x: 0, y: -0.0, z: 0 });
            //mUderly.load("/assets/models/uderly-kiosk.glb", 3, { x: 68, y: 1, z: 14.5 }, { x: 0, y: -0.0, z: 0 });
            
            // setTimeout(() => {
            //     this.toMove = mUderly.object;
            // }, 5000);
        }

        // Info Desk
        // const infoDesk = new HiModel();
        // infoDesk.addEventListener(ModelEventsEnum.Loaded, this.onHiModelLoaded);
        // infoDesk.load("/assets/models/kiosk0.fbx", {
        //     scale: 0.05,
        //     position: { x: 77, y: 1, z: -48 },
        //     rotation: { x: 0, y: -0.68, z: 0 },
        // });
        // infoDesk.setBoundingBox(new THREE.CylinderGeometry(14, 14, 26, 32), {
        //     position: { x: 77, y: 12, z: -48 },
        // });
        // infoDesk.onClick = this.onClickInfoDesk;

        // setTimeout(() => {
        //     this.toMove = m0.object;
        // }, 5000);

        this.addBanners();

        this.generateStandBlocks();
        this.initVideo();
    }

    initVideo = () => {
        const { scene } = World;

        const v = {
            src: "assets/videos/woolsafe.mp4",
            width: 640,
            height: 360,
            scale: { x: 16*4, y: 9*4, z: 0 },
            position: { x: 0, y: 22.2, z: -95.8 }
        };

        const video = new HiModel();
        video.video(v, 1);
        video.onClick = () => AppContext.history.push("/stage");
        video.onOver = () => {
            // console.log("video over");
            if(this.stageTexts && this.stageTexts.length > 1) {
                this.stageTexts[0].visible = true;
                this.stageTexts[1].visible = true;
            }
        };
        video.onOut = () => {
            // console.log("video out");
            if(this.stageTexts && this.stageTexts.length > 1) {
                this.stageTexts[0].visible = false;
                this.stageTexts[1].visible = false;
            }
        };

        this.hiModels.push(video);

        scene.add(video.group);

        this.stageTexts = [];

        this.addText("CLICK TO ACCESS", { x: 0, y: 24, z: -95 }, (text) => { this.stageTexts.push(text); text.visible = false; });
        this.addText("THE STAGE", { x: 0, y: 15.5, z: -95 }, (text) => { this.stageTexts.push(text); text.visible = false; });
    }

    addText = (text = "Hello", position = { x: 0, y: 0, z: 0 }, onLoaded = null) => {
        const { scene } = World;

        let textMesh1, textGeo;

        const material = new THREE.MeshPhongMaterial({
            color: new THREE.Color(0xffffff), //0xffc000
            opacity: 1,
            transparent: false,
        });
    
        const thickness = 0.3,
            size = 4,
            curveSegments = 2;

        const loader = new THREE.FontLoader();

        loader.load('assets/fonts/helvetiker_regular.typeface.json', (font) => {
            textGeo = new THREE.TextGeometry( text, {
                font: font,
                size: size,
                height: thickness,
                curveSegments: curveSegments,
            } );

            textGeo.computeBoundingBox();

            textMesh1 = new THREE.Mesh(textGeo, material);

            // textMesh1.rotation.x = Math.PI/2;
            // textMesh1.rotation.y = -Math.PI;
            // textMesh1.rotation.z = -Math.PI/2;

            if(!position)
                position = { x: 0, y: 0, z: 0 }

            textMesh1.position.x = position.x - ((textGeo.boundingBox.max.x - textGeo.boundingBox.min.x) / 2);
            textMesh1.position.y = position.y;
            textMesh1.position.z = position.z;

            scene.add(textMesh1);

            if(onLoaded)
                onLoaded(textMesh1);
        });
    }

    addBanners = () => {
        // Front Banner 0
        const b0 = {
            position: {x: -56, y: 15, z: -90},
            rotation: {x: 0, y: 0.42, z: 0},
            url: "https://www.woolsafe.org",
            // image: "/assets/textures/uderly-logo512.png"
        };
        this.addBanner(b0)

        // Front Banner 1
        const b1 = {
            position: {x: 56, y: 15, z: -90},
            rotation: {x: 0, y: -0.42, z: 0},
            url: "https://www.cleansealapproved.com/",
            image: "/assets/banners/cleanseal.jpg"
        };
        this.addBanner(b1);

        const rBanners = [];

        rBanners.push({
            position: { x: 123, y: 15, z: -37 },
            rotation: { x: 0, y: -0.9, z: 0 },
            scale: { x: 24, y: 18, z: 1 },
            url: "https://www.enviroseal.eu",
            image: "/assets/banners/enviroseal.jpg"
        });
        this.addBanner(rBanners[0]);

        rBanners.push({
            position: { x: 144, y: 15, z: -10 },
            rotation: { x: 0, y: -0.9, z: 0 },
            scale: { x: 36, y: 18, z: 1 },
            url: "https://www.woolsafeacademy.org",
            image: "/assets/banners/woolsafe-academy.jpg"
        });
        this.addBanner(rBanners[1]);
    }

    addBanner = (bData) => {
        const { scene } = World;

        const banner = new HiModel(bData);
        banner.banner(bData, bData.image);
        banner.onClick = () => window.open(bData.url, '_blank').focus();
        //banner.over = () => console.log("over Banner", bData);
        // scene.add(banner.object);
        this.hiModels.push(banner);

        scene.add(banner.group);

        return banner;
    }

    onStandModelLoaded = (model) => {
        const { scene } = World;
        
        const stand = new Stand(null, this.onStandLoaded);
        this.stands.push(stand);

        stand.generateFromModel(model);
        scene.add(model.object);
        if(stand.boundingBox)
            scene.add(stand.boundingBox);
            
        // Info Point Logo yOffset
        if(model && model.data && model.data.id === SpecialStandsEnum.Info.id)
            stand.logo.position.y += 14;
        // Cafe Logo yOffset
        if(model && model.data && model.data.id === SpecialStandsEnum.Cafe.id)
            stand.logo.position.y += 6;

        scene.add(stand.logo);
        scene.add(stand.cover);
        
        this.interactiveObjs.push(stand.boundingBox);
    }

    appendEvents = () => {
        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener("mousemove", this.onMouseMove, false);
        document.addEventListener("keydown", this.onDocumentKeyDown, false);
        document.addEventListener('wheel', this.onWheel);

        // get a reference to an element
        const stage = document.getElementById('stage');
        
        // create a manager for that element
        const mc = new Hammer.Manager(stage);
        
        // Recognizers
        const pan = new Hammer.Pan();

        const doubleTap = new Hammer.Tap({
            event: 'doubletap',
            taps: 2
        });

        const pinch = new Hammer.Pinch();
        pinch.recognizeWith([pan]);
        mc.add([pan, doubleTap, pinch]);
        
        mc.on('doubletap', (e) => {
            this.onSelect();
        });

        if(!enableOrbit) {
            // Calculate and clamp Camera's y from delta
            const currentY = (delta) => {
                return THREE.MathUtils.clamp((this.iY + (delta / 6)), this.yRange.min, this.yRange.max);
            }

            // Limit the camera's angle
            const thetaRange = { min: -1.15, max: 1.15 };

            mc.on('panstart', (e) => {
                this.iTheta = this.a;
                this.iY = this.y;
            });

            mc.on('pan', (e) => {
                const theta = THREE.MathUtils.clamp(this.iTheta + (-e.deltaX / 100), thetaRange.min, thetaRange.max); // Relative Theta for this panning
                const vPos = currentY(e.deltaY); // Relative camera vertical position change for this panning

                this.setCameraAngle(theta, vPos);
            });

            mc.on('pinchstart', (e) => {
                this.iTheta = this.a;
                this.iY = this.y;
                this.iR = this.r;
            });

            mc.on('pinchmove', (e) => {
                // this.screenConsole(JSON.stringify(e));

                // Relative angle, vertical pos and distance for camera
                const theta = THREE.MathUtils.clamp(this.iTheta + (-e.deltaX / 100), thetaRange.min, thetaRange.max);
                const vPos = currentY(e.deltaY);
                const r = THREE.MathUtils.clamp(this.iR * (1 / e.scale), this.rRange.min, this.rRange.max);

                this.setCameraAngle(theta, vPos, r);
            });
        }
    }

    screenConsole = (text) => {
        const console = document.getElementById( 'console' );
        
        if(console)
            console.value += text;
    }

    updateCamera = () => {
        this.setCameraAngle(this.a);
    }

    setCameraVerticalPosition = (y) => {
        const { camera } = World;

        camera.position.y = y;
        camera.lookAt(this.center);
    }
    
    setCameraAngle = (theta = null, y = null, radius = null, lookAt = null) => {
        const { camera } = World;
        const { a, r } = this;

        if(!theta) theta = a; else this.a = theta;
        if(!lookAt) lookAt = this.center;
        if(!radius) radius = r; else this.r = radius;

        if(y) {
            camera.position.y = y;
            this.y = y;
        }
        camera.position.z = this.center.z + radius * Math.cos(theta);
        camera.position.x = this.center.x + radius * Math.sin(theta);

        camera.lookAt(lookAt);
    }

    // Set camera position at a certain "r" from "lookAt"
    setR = (r, lookAt) => {
        const { camera } = World;
        const { a } = this;

        this.r = r;

        camera.position.z = this.center.z + r * Math.cos(a);
        camera.position.x = this.center.x + r * Math.sin(a)

        if(!lookAt)
            lookAt = this.center;

        camera.lookAt(lookAt);
    }

    zoom = (times = 1, lookAt = null) => {
        const { r, rRange } = this;

        const zoomSpeed = times;

        if((r + zoomSpeed) >= rRange.min && (r + zoomSpeed) <= rRange.max) {
            // this.setR((r + zoomSpeed), lookAt);
            this.setCameraAngle(null, null, (r + zoomSpeed), lookAt);
        }
    }

    onWheel = (e) => {
        if(e && e.target.localName === "canvas")
            this.zoom(e.deltaY / 100 * 3)
    }

    generateStandBlocks = () => {
        this.groups = [];

        // Main Block
        const approvedSuppliersStands = this.standsData.filter(x => x.category.id === 3);

        const mg0 = this.generateStandsGroup(approvedSuppliersStands, 0, -34, 12, 2);
        mg0.position.x = 1;
        mg0.position.y = 0;
        mg0.position.z = -20;
        
        const mg1 = this.generateStandsGroup(approvedSuppliersStands,  24, 6, 12, 2);
        mg1.position.z = -20;

        // Left Block
        const lb0 = this.generateStandsGroup(approvedSuppliersStands, (24 + 24), 0, 16, 2, { x: 14, y: 0, z: 0.1 });
        lb0.position.x = -99;
        lb0.position.y = 0;
        lb0.position.z = -37;
        lb0.rotation.y = -0.69;

        // Right Block
        const associateStands = this.standsData.filter(x => x.category.id === 2);

        const rb = this.generateStandsGroup(associateStands, 0, 0, 16, 2);
        rb.position.x = 82;
        rb.position.y = 0;
        rb.position.z = -23;
        rb.rotation.y = 0.69;

        this.groups.push(mg0);
        this.groups.push(mg1);
        this.groups.push(lb0);
        this.groups.push(rb);

        // this.toMove = mg0;
    }

    generateStandsGroup = (standsData, startFromIndex = 0, xPos = 0, countZ = 10, countX = 1, margins = null) => {
        const { scene } = World;

        const group = new THREE.Group();

        const zPos = Stand.dimensions.m.z / 2; // Initial z pos given by first stand dimensions

        const position = { x: xPos, y: 2, z: 0 };
        const dimensions = { x: 0, y: 0, z: 0 };

        if(!margins)
            margins = { x: 20, y: 0, z: 0.1 };

        const sDimensions = Stand.dimensions.m;

        var counter = 0;

        for(let x = 0; x < countX; x++) {
            for(let i = 0; i < countZ; i++) {
                position.x = xPos + x * (sDimensions.x + margins.x);
                position.z = zPos + (sDimensions.z + margins.z) * i;

                // Calculate global dimensions
                if(x === 0) {
                    dimensions.x += sDimensions.x;
                    dimensions.y += sDimensions.y;
                    dimensions.z += (sDimensions.z + margins.z);
                }

                const stand = new Stand(group, this.onStandLoaded);
                this.stands.push(stand);

                const o = stand.generate("m", position, standsData[startFromIndex + counter++], x % 2); // Map state stands map with actual 3D map items
                this.interactiveObjs.push(o);
            }
        }

        group.position.z = 0;
        group.position.z = -dimensions.z/2;

        // const groupLength = sDimensions.z * countZ;
        const lightsCount = 3;

        for(let i=0; i<lightsCount; i++) {
            // const light = new THREE.PointLight(0xffffff, 0.1, 0, 2);
            // light.position.set(xPos + (sDimensions.x + margins.x)/2, 0, (groupLength / lightsCount * i) + (groupLength / lightsCount / 2));
            // group.add(light);

            // Sphere Helper
            // const geometry = new THREE.SphereGeometry( 5, 32, 32 );
            // const material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
            // const sphere = new THREE.Mesh( geometry, material );
            // sphere.position.set(xPos + (sDimensions.x + margins.x)/2, 0, (groupLength / lightsCount * i) + (groupLength / lightsCount / 2));
            // group.add( sphere );
        }

        scene.add( group );

        return group;
    }

    onStandLoaded = (stand) => {
        if(stand.group) {
            stand.group.add(stand.object);
            stand.group.add(stand.logo);
            stand.group.add(stand.cover);
        }
        
        if(stand.text)
            if(stand.group)
                stand.group.add(stand.text);
    }

    get isMobile () {
        return window.innerWidth < 800;
    }

    onWindowResize = () => {
        const {  renderer, camera } = World;
        
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize( window.innerWidth, window.innerHeight );
    }

    onDocumentKeyDown = (event) => {
        const keyCode = event.which;
        
        if(this.toMove) {
            const rotSpeed = 0.01;
            const posSpeed = 1;

            if(event.shiftKey) {
                // Left Arrow
                if (keyCode == 37)
                    this.toMove.rotation.y -= rotSpeed;
                // Up Arrow
                if (keyCode == 38)
                    this.toMove.rotation.z -= rotSpeed;
                // Right Arrow
                if (keyCode == 39)
                    this.toMove.rotation.y += rotSpeed;
                // Down Arrow
                if (keyCode == 40)
                    this.toMove.rotation.z += rotSpeed;
            } else {
                // Left Arrow
                if (keyCode == 37)
                    this.toMove.position.x -= posSpeed;
                // Up Arrow
                if (keyCode == 38)
                    this.toMove.position.z -= posSpeed;
                // Right Arrow
                if (keyCode == 39)
                    this.toMove.position.x += posSpeed;
                // Down Arrow
                if (keyCode == 40)
                    this.toMove.position.z += posSpeed;
            }

            console.log(event, this.toMove.position, this.toMove.rotation);
        }

        if(event.key === "+") {
            if((this.r - 1) > this.rRange.min) {
                this.r -= 1;
                this.updateCamera();
            }
        }

        if(event.key === "-") {
            if((this.r + 1) < this.rRange.max) {
                this.r += 1;

                this.updateCamera();
            }
        }
    };

    onMouseMove = (event) => {
        const { mouse } = this;

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    }

    add = (model) => {
        const { scene } = World;

        scene.add(model.object);
    }

    highlightedStand = null;
    intersectedStand = null;

    unlightIntersectedStand = () => {
        if(this.intersectedStand) {
            this.intersectedStand.highlight(false);
            this.intersectedStand = null;
        }
    }

    unlightHighlightedStand = () => {
        if(this.highlightedStand) {
            this.highlightedStand.highlight(false);
            this.highlightedStand = null;
        }
    }
    
    componentWillReceiveProps = (nextProps) => {
        const { stands } = this;

        // console.log(stands, nextProps.hlStandId);

        if(nextProps.hlStandId !== this.props.hlStandId) {
            if(nextProps.hlStandId === null) {
                this.unlightIntersectedStand();
                this.unlightHighlightedStand();
            } else {
                const i = nextProps.hlStandId - 10; // TODO: Consider/map special stands

                if(stands[i]) {
                    this.unlightIntersectedStand();

                    this.highlightedStand = stands[i];
                    stands[i].onOver();
                }
            }
        }
    }

    onSelect = () => {
        if(!this.intersectedHiModel && // Give priority to the new HiModels
            this.intersectedStand && this.props.onStandClick) {
            // console.log(this.intersectedStand.stand);
            this.props.onStandClick(this.intersectedStand.stand);
        }

        // if(this.intersectedHiModel && this.intersectedHiModel.onClick)
        //     this.intersectedHiModel.click();
    }

    onMouseDown = (e) => {
        // deprecated for stands

        if(this.intersectedHiModel && this.intersectedHiModel.onClick)
            this.intersectedHiModel.click();
    }

    render3DScene = () => {
        const { scene, renderer, camera } = World;
        const { mouse, raycaster, stands, interactiveObjs, hiModels, enableRaycaster } = this;
        
    	raycaster.setFromCamera(mouse, camera);

        if(enableRaycaster) {
            if(this.highlightedStand === null && interactiveObjs && interactiveObjs.length > 0) {
                const intersects = raycaster.intersectObjects(interactiveObjs);
            
                if(intersects.length > 0) {
                    const o = intersects[0];
                    for(let j=0; j<stands.length; j++) {
                        if(o.object && o.object.uuid === stands[j].uuid
                            && this.intersectedStand !== stands[j]) {
                            this.unlightIntersectedStand();
        
                            stands[j].onOver();
                            
                            this.intersectedStand = stands[j];
                        }
                    }
                } else {
                    this.unlightIntersectedStand();
                }
            }
    
            if(hiModels.length > 0) {
                const interactiveObjects = hiModels.map(m => m.boundingBox ? m.boundingBox : m.object);
                const intersects = raycaster.intersectObjects(interactiveObjects);
                
                if(intersects.length > 0) {
                    // Only consider first intersected element
                    const intersected = intersects[0];
                    let intHiModel = null;
    
                    // Find our intersected HiModel
                    for(let hiModel of hiModels) {
                        if((hiModel.boundingBox && hiModel.boundingBox.uuid === intersected.object.uuid) ||
                            (hiModel.object && hiModel.object === intersected.object.uuid) && hiModel.over) {
                            // Found!
                            intHiModel = hiModel;
                            break;
                        }
                    };
    
                    if(!intHiModel) {
                        if(this.intersectedHiModel)
                            this.intersectedHiModel.out();
                        
                        this.intersectedHiModel = null;
                    }
    
                    if(!this.intersectedHiModel || this.intersectedHiModel !== intHiModel && intHiModel) {
                        this.intersectedHiModel = intHiModel;
    
                        if(intHiModel && intHiModel.over)
                            intHiModel.over();
                    }
                } else {
                    if(this.intersectedHiModel)
                        this.intersectedHiModel.out();

                    this.intersectedHiModel = null;
                }
            }
        }
        
        // Play Video(s)
        for(let m of hiModels) {
            if(m.type === "video" && m.video.readyState === m.video.HAVE_ENOUGH_DATA) {
                m.videoImageContext.drawImage(m.video, 0, 0);
    
                if (m.videoTexture)
                    m.videoTexture.needsUpdate = true;
            }
        }

        renderer.render( scene, camera );
    }

    resetView = (e) => {
        if(e) e.stopPropagation();

        World.viewIndex = ViewsEnum.Main;

        this.center = this.centers.center;
        // this.pan.x = 0;
        // this.pan.y = 100;
        this.r = 120;
        this.a = -0.5;

        this.setCameraAngle(this.a, 100);

        this.unlightHighlightedStand();
        this.unlightIntersectedStand();
    }

    zoomIn = (e) => {
        if(e) e.stopPropagation();

        const v = 5;

        if((this.r - v) > this.rRange.min) {
            this.r -= v;
            this.updateCamera();
        }

        this.unlightHighlightedStand();
        this.unlightIntersectedStand();
    }

    zoomOut = (e) => {
        if(e) e.stopPropagation();
        
        const v = 5;

        if((this.r + v) < this.rRange.max) {
            this.r += v;
            this.updateCamera();
        }

        this.unlightHighlightedStand();
        this.unlightIntersectedStand();
    }

    render() {
        return (
            <>
                <div id="stage" ref={ref => (this.mount = ref)} onMouseDown={this.onMouseDown}>
                    <div className="zoom-buttons" 
                        onMouseOver={() => this.enableRaycaster = false } 
                        onMouseLeave={() => this.enableRaycaster = true }>
                        <IconButton aria-label="zoom out" onClick={this.resetView}>
                            <RotateLeftIcon />
                        </IconButton>

                        <IconButton aria-label="zoom out" onClick={this.zoomOut}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                        
                        <IconButton aria-label="zoom in" onClick={this.zoomIn}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </div>
                </div>

                {this.state.loading && AppContext.r["preloader"]}
            </>
        )
    }
}

export default withModal(World);