import Axios from 'axios';
import Cookie from 'react-cookies';

import AppContext from '../app/AppContext';
import CoreApi from './Core';

export default class Auth {
    static async fetchAsync (url, fieldToMap) {
        const res = await Axios.get(url, { headers: { Authorization: AppContext.Auth.bearer } });
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        if(fieldToMap !== undefined && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }

        return data;
    };

    static user = null;

    static c = {
        "token_type": "Bearer",
        "expires_in": -1,
        "access_token": "",
        "refresh_token": ""
      };

    static get bearer () { return 'Bearer '.concat(Auth.c["access_token"]); }

    static async fetchUser() {
        return await Axios.get(AppContext.s["api-url"] + '/user')
            .catch(function (error) {
                return CoreApi.errorLog(error);
            });
    }

    static logout() {
        Auth.user = null;
        // Cookie.remove('refresh-token');
        Cookie.remove('auth');
    }
    
    // static async loginSync(username, password) {
    //     console.log("login sync => URL: " + AppContext.s["api-url"] + '/login', {
    //         "grant_type": "password",
    //         "client_id": AppContext.s["client-id"],
    //         "client_secret": AppContext.s["client-secret"],
    //         "username": username,
    //         "password": password
    //     });

    //     const response = Axios.post(AppContext.s["api-url"] + '/login', {
    //         "grant_type": "password",
    //         "client_id": AppContext.s["client-id"],
    //         "client_secret": AppContext.s["client-secret"],
    //         "username": username,
    //         "password": password
    //     }).catch(function (error) {
    //         return CoreApi.errorLog(error);
    //     });

    //     return response;
    // }

    /**
     * @api {post} /login Authenticate User
     * @apiName Login
     * @apiGroup Auth
     *
     * @apiParam {Number} client_id
     * @apiParam {String} client_secret
     * @apiParam {String} username
     * @apiParam {String} email
     *
     * @apiSuccess {Object} data Response Object Data
     * 
     * @apiSuccessExample {json} Success
     *    HTTP/1.1 200 OK
     *    "data": {
     *      "token_type": "Bearer",
     *      "expires_in": 31536000,
     *      "access_token": "[ACCESS TOKEN]",
     *      "refresh_token": "[REFRESH TOKEN]",
     *      "user": [USER OBJECT],
     *    }
     * @apiErrorExample {json} Error
     *    HTTP/1.1 500 Internal Server Error
     * 
     * @apiSuccessExample Success-Response:
     *     HTTP/1.1 200 OK
     * @apiSampleRequest off
     */
    static async login(username, password, grantType = "password", provider = "", accessToken = "", name = "", surname = "") {
        const data = {
            "grant_type": grantType,
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "username": username,
            "password": password,
            "provider": provider,
            "access_token": accessToken,
            "name": name,
            "surname": surname,
            "shop_id": AppContext.s["shop-id"]
        };
        console.log(AppContext.s["api-url"] + '/login', data);

        const response = await Axios.post(AppContext.s["api-url"] + '/login', data).catch(function (error) {
            return CoreApi.errorLog(error);
        });

        console.log(response);
        
        this.cacheTokenFromResponse(response);

        return response;
    }

    static cacheTokenFromResponse(response) {
        if(response && response.status === 200) {
            // Save access token configuration
            this.c = response.data;

            const cache = {
                access_token: response.data.access_token,
                expires_in: response.data.expires_in,
                refresh_token: response.data.refresh_token,
            };

            Cookie.save('auth', cache, { path: '/' });
            this.user = this.c.user;
        }
    }

    static async login2(username, password) {
        // Get Access Token /oauth/token
        const response = await Axios.post(AppContext.s["host-url"] + '/oauth/token', {
            "grant_type": "password",
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "username": username,
            "password": password
        }).catch(function (error) {
            return CoreApi.errorLog(error);
          });
          
        if(response && response.status === 200) {
            // Save access token configuration
            this.c = response.data;

            Cookie.save('auth', response.data, { path: '/' });

            // Retrieve User
            //console.log("Retrieve user");
            this.user = await Auth.fetchUser();
            //console.log(this.user);
        }

        return response;
    }

    static async refresh(refreshToken) {
        // Refresh Access Token /oauth/token
        const response = await Axios.post(AppContext.s["host-url"] + '/oauth/token', {
            "grant_type": "refresh_token",
            "refresh_token": refreshToken,
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "scope": ""
        }).catch(function (error) {
            return CoreApi.errorLog(error);
        });

        if(response) {
            // console.log(response.data);
            // Save access token configuration
            this.c = response.data;

            // Retrieve User
            this.user = await Auth.fetchUser();
        }

        return response;
    }
    
    /**
     * @api {post} /register User Registration
     * @apiName User Registration
     * @apiGroup Auth
     *
     * @apiParam {String} name
     * @apiParam {String} surname
     * @apiParam {String} email
     * @apiParam {String} password
     * @apiParam {String} mobile_phone
     * @apiParam {Boolean} allow_newsletter
     *
     * @apiSuccessExample Success-Response:
     *     HTTP/1.1 200 OK
     * 
     * @apiSampleRequest off
     */
    // static async signup(shopId, name, surname, username, email, password, language_id, mobilePhone = "", allowNewsletter = false) {
    //     const url = AppContext.s["api-url"] + "/register";

    //     const data = {
    //         "shop_id": shopId,
    //         "name": name,
    //         "surname": surname,
    //         "username": username,
    //         "email": email,
    //         "password": password,
    //         "language_id": language_id,
    //         "mobile_phone": mobilePhone,
    //         "allow_newsletter": allowNewsletter
    //     };

    //     console.log(data);

    //     const response = await Axios.post(url, data).catch(function (error) {
    //         console.log(error);
    //         return CoreApi.errorLog(error);
    //     });

    //     return response;
    // }

    static async signup(userRequest) {
        const url = AppContext.s["api-url"] + "/register";

        // const data = {
        //     "shop_id": shopId,
        //     "name": name,
        //     "surname": surname,
        //     "username": username,
        //     "email": email,
        //     "password": password,
        //     "language_id": language_id,
        //     "mobile_phone": mobilePhone,
        //     "allow_newsletter": allowNewsletter
        // };

        if(!userRequest["mobile_phone"]) userRequest["mobile_phone"] = "";
        if(!userRequest["allow_newsletter"]) userRequest["allow_newsletter"] = false;

        console.log(userRequest);

        const response = await Axios.post(url, userRequest).catch(function (error) {
            console.log(error);
            return CoreApi.errorLog(error);
        });

        return response;
    }
    
    static async verify(token) {
        const url = AppContext.s["api-url"] + "/email/verify/"+token;

        const data = {
        //    "token": token,
        };

        console.log(data);

        const response = await Axios.post(url, data).catch(function (error) {
            console.log(error);
            return CoreApi.errorLog(error);
        });

        return response;
    }

    /**
     * @api {put} /changepassword Change Password
     * @apiName Change Password
     * @apiGroup Auth
     *
     * @apiParam {String} old_password
     * @apiParam {String} new_password
     *
     * @apiSuccessExample Success-Response:
     *     HTTP/1.1 200 OK
     * 
     * @apiSampleRequest off
     */
    static async changePassword(password, newPassword) {
        const response = await Axios.put(AppContext.s["api-url"] + '/changepassword', {
            old_password: password,
            new_password: newPassword
        }).catch(function (error) {
            return CoreApi.errorLog(error);
        });

        return response;
    }

    /**
     * @api {put} /deleteaccount Delete Account
     * @apiName Delete Account
     * @apiGroup Auth
     *
     * @apiParam {String} password
     *
     * @apiSuccessExample Success-Response:
     *     HTTP/1.1 200 OK
     * 
     * @apiSampleRequest off
     */
    static async deleteAccount(password) {
        const response = await Axios.put(AppContext.s["api-url"] + '/deleteaccount', {
            password: password
        }).catch(function (error) {            
            return CoreApi.errorLog(error);
        });

        return response;
    }

    /**
     * @api {post} /password/email Reset Password
     * @apiName Reset Password
     * @apiGroup Auth
     *
     * @apiParam {String} email
     *
     * @apiSuccessExample Success-Response:
     *     HTTP/1.1 200 OK
     * 
     * @apiSampleRequest off
     */
    static async requestResetPassword(email, redirectUrl, language) {
        const response = await Axios.post(AppContext.s["api-url"] + '/password/email', {
            "email": email,
            "redirectUrl": redirectUrl,
            "language": language,
        }).catch(function (error) {
            console.log(error);
            return CoreApi.errorLog(error);
        });
        
        return response;
    }

    static async resetPassword(token, email, password, passwordConfirmation) {
        const response = await Axios.post(AppContext.s["api-url"] + '/password/reset', {
            "token": token,
            "email": email,
            "password": password,
            "password_confirmation": passwordConfirmation,
        }).catch(function (error) {
            console.log(error);
            return CoreApi.errorLog(error);
        });
        
        return response;
    }
}