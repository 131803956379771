import React from 'react';
import { Container, Row } from 'react-bootstrap';

import AppContext from 'app/AppContext.js';

export default class Refunds extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <Container fluid className="page legal-container">
                <Row className="justify-content-md-center">
                    {AppContext.r["refunds-doc"]}
                </Row>
            </Container>
        );
    }
}