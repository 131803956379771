import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button, Col, Row, Form as BSForm } from 'react-bootstrap';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { connect } from "react-redux";
import * as Yup from 'yup';

import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
import RemoteSelect from 'framework/RemoteSelect';

class Register extends React.Component {
    async componentDidMount() {
        this.countryOptions = await CoreApi.fetchCountryOptions();
    }

    // signUp = async (shopId, name, surname, gamename, email, password, mobilePhone = "", allowNewsletter = false) => {
    //     this.props.modal.setBusy(true);
        
    //     //console.log(shopId, name, surname, gamename, email, password, mobilePhone, AppContext.s["language"] === 'it' ? 2 : 1, allowNewsletter);
    //     // const response = await register(shopId, name, surname, gamename, email, password, mobilePhone, AppContext.s["language"] === 'it' ? 2 : 1, allowNewsletter);
    //     const userRequest = {
    //         name: name,
    //         surname: surname,
    //         gamename: gamename,
    //         email: email,
    //         password: password,
    //         mobile_phone: mobilePhone,
    //         language_id: AppContext.s["language"] === 'it' ? 2 : 1,
    //         allow_newsletter: allowNewsletter
    //     }
    //     console.log(userRequest);

    signUp = async (userRequest) => {
        const response = await register(userRequest);
        console.log(response);
        //this.props.register(accessToken);

        let modalMessage = "";

        if (response && (response.status === 200 || response.status === 201)) {
            const user = response.data.data;
            user["password"] = userRequest.password; // needed to autologin

            if(this.props.onRegistered)
                this.props.onRegistered(user)

            // Account Created

            modalMessage = (<div>
                <i className="far fa-thumbs-up icon"></i>
                <p className="message">{AppContext.r['account-created']}</p>
                {!this.props.inline &&
                    <Button onClick={() => { this.props.modal.setFree(); this.props.modal.hide() }}>{AppContext.r['close']}</Button>
                }
                {/* <Button onClick={async () => {
                        // Try to log in
                        await Auth.login(email, password);

                        if (this.props.onRegistered)
                            this.props.onRegistered(response);
                        this.setModalFree();
                    }}>{AppContext.r['close']}</Button> */}
            </div>);
        } else {
            // Authorization Failed
            modalMessage = (
                <div>
                    <i className="far fa-frown icon"></i>
                    <p className="message">{AppContext.r['account-not-created']}</p>
                    {!this.props.inline &&
                        <button onClick={this.props.modal.setFree}>{AppContext.r['close']}</button> }
                </div>
            );
        }

        this.props.modal.setBusy(false);
        this.props.modal.setMessage(modalMessage);
    }

    render() {
        const validationSchema = Yup.object().shape({
            name: Yup.string()
                .required(AppContext.r['name-required']),
            surname: Yup.string()
                .required(AppContext.r['surname-required']),
            email: Yup.string()
                .email(AppContext.r['invalid-email'])
                .required(AppContext.r['email-required']),
            password: Yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required']),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], AppContext.r['password-must-math'])
                .required(AppContext.r['confirm-password-required']),
             mobile_phone: Yup.string().matches(AppContext.s['phone-regex'], AppContext.r['phone-not-valid']),
        });

        return <div className="signup">
            {(this.props.enableFacebookLogin && AppContext.s["facebook-app-id"]) &&
            <>
                <div className="facebook-button">
                    <FacebookProvider appId={AppContext.s["facebook-app-id"]}>
                        <LoginButton 
                            scope="email"
                            onLoading={this.onLoadingFacebookLogin}
                            onCompleted={this.props.handleFacebookResponse}
                            onError={this.handleError}>
                            <span><i className="fab fa-facebook-f"></i> {AppContext.r['access-with-facebook']}</span>
                        </LoginButton>
                    </FacebookProvider>
                </div>
                
                <p className="text-center">{AppContext.r['or-signup-via-email']}</p>
            </>}

            <Formik
                initialValues={{
                    name: '',
                    surname: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    mobile_phone: '',
                    allow_newsletter: true
                }}
                validationSchema={validationSchema}
                onSubmit={fields => {
                    console.log(fields);
                    this.signUp(fields);
                    //this.signUp(AppContext.s["shop-id"], fields.name, fields.surname, fields.gamename, fields.email, fields.password, fields.mobile_phone, fields.allow_newsletter);
                }}
                render={({ errors, status, touched, setFieldValue }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="name">{AppContext.r['name']}</label>
                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="surname">{AppContext.r['surname']}</label>
                            <Field name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="gamename">{AppContext.r['game-name']}</label>
                            <Field name="gamename" type="text" maxLength={30}
                                className={'form-control' + (errors.gamename && touched.gamename ? ' is-invalid' : '')} />
                            <ErrorMessage name="gamename" component="div" className="invalid-feedback" />
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile_phone">{AppContext.r['mobile-phone']}</label>
                            <Field name="mobile_phone" type="text" className={'form-control' + (errors.mobile_phone && touched.mobile_phone ? ' is-invalid' : '')} />
                            <ErrorMessage name="mobile_phone" component="div" className="invalid-feedback" />
                        </div>

                        <Row>
                            <BSForm.Group as={Col} md="6">
                                <BSForm.Label>{AppContext.r["company"]}</BSForm.Label>
                                <Field name="company" type="text" placeholder={AppContext.r["company"]} 
                                    className={'form-control' + (errors.company && touched.company ? ' is-invalid' : '')} />
                                <ErrorMessage name="company" component="div" className="invalid-feedback" />
                            </BSForm.Group>

                            <BSForm.Group as={Col} md="6">
                                <BSForm.Label>{AppContext.r["title"]}</BSForm.Label>
                                <Field name="title" type="text" placeholder={AppContext.r["title"]} 
                                    className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                                <ErrorMessage name="title" component="div" className="invalid-feedback" />
                            </BSForm.Group>
                        </Row>

                        <div className="form-group">
                            <label htmlFor="address1">{AppContext.r["address"]}</label>
                            <Field name="address1" type="text" className={'form-control' + (errors.address1 && touched.address1 ? ' is-invalid' : '')} />
                            <ErrorMessage name="address1" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group">
                            <Field name="address2" type="text" className={'form-control' + (errors.address2 && touched.address2 ? ' is-invalid' : '')} />
                            <ErrorMessage name="address2" component="div" className="invalid-feedback" />
                        </div>

                        <Row>
                            <BSForm.Group as={Col} md="3">
                                <BSForm.Label>{AppContext.r["post-code"]}</BSForm.Label>
                                <Field name="post_code" type="text" placeholder={AppContext.r["post-code"]} 
                                    className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')} />
                                <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
                            </BSForm.Group>

                            <BSForm.Group as={Col} md="5">
                                <BSForm.Label>{AppContext.r["city"]}</BSForm.Label>
                                <Field name="city" type="text" placeholder={AppContext.r["city"]} className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')} />
                                <ErrorMessage name="city" component="div" className="invalid-feedback" />
                            </BSForm.Group>
                            
                            <BSForm.Group as={Col} md="4">
                                <BSForm.Label>{AppContext.r["country"]}</BSForm.Label>

                                <RemoteSelect name="country_id" value={228}
                                    onChange={(e, value) => {
                                        setFieldValue("country_id", value);
                                      }}
                                    options={this.countryOptions} />
                            </BSForm.Group>
                        </Row>
                        
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">{AppContext.r['confirm-password']}</label>
                            <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <div className="checkbox-wrapper">
                                <Field name="allow_newsletter" type="checkbox" className={"checkbox" + (errors.allow_newsletter && touched.allow_newsletter ? ' is-invalid' : '')} />
                                <label htmlFor="allow_newsletter">{AppContext.r['allow-newsletter']}</label>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary mr-2">{AppContext.r["signup"]}</button>
                        </div>
                    </Form>
                )} />
        </div>;
    }
}

export default connect(null, auth.actions) (Register);