import React, { Children, isValidElement, cloneElement } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import Isle, { Archipelago } from "react-isle";

import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import AppContext from './AppContext';
import Login from "app/modules/Auth/pages/Login";
import Auth from '../api/Auth';
import { withModal } from '../framework/Modal';
import Contacts from '../framework/Contacts';
import Footer from "./Footer.js";
import CookiesNotification from "framework/CookiesNotification";
import Cookie from 'react-cookies';

import Header from "components/Header.js";
import EventsNotification from "components/EventsNotification";
import ZoomWidget from 'framework/ZoomWidget';
import Legend from "components/Legend";
// import WelcomeVideo from "components/WelcomeVideo";
import StartupInfo from 'components/StartupInfo';
import WelcomeVideo from 'components/WelcomeVideo';

// const qs = require('querystring');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Layout extends React.Component {
    state = {
        isApp: false,
        notification: null,
        drawerOpen: false,
        standsData: this.props.standsData,
        // hlStandId: null
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            notification: null
        })
    };

    hideInfoAtStartup = () => {
        this.props.modal.hide();

        const settings = {
            hideInfoAtStartup: true
        };

        Cookie.save('settings', settings, { path: '/' });
    }

    showWelcomeVideo = () => {
        this.props.modal.setView("Welcome to WoolSafe Grand Expo", 
            <WelcomeVideo onComplete={this.hideInfoAtStartup} />, true, "wide");
    }

    async componentDidMount() {
        if(window.location.pathname === "/") {
            // Cookie.remove('settings');
            const settings = Cookie.load("settings");

            if(!settings || !settings.hideInfoAtStartup)
                this.showWelcomeVideo();
        }
        
        // window.addEventListener('scroll', this.onScroll, true);
        // if(this.props.token) {
        //     const response = await Auth.verify(this.props.token);
            
        //     if(response && response.status === 200) {
        //         this.setState({
        //             notification: AppContext.r["account-verified"]
        //         });
        //         AppContext.history.push("/");
        //     }
        // }
        // this.isApp = false;
        // This should be moved "higher"
        // const searchQuery = qs.parse(AppContext.history.location.search.replace("?", ""));
        // this.isApp = searchQuery.app && searchQuery.app.length > 0;
        // if(isApp && searchQuery.a && searchQuery.a.length > 0) {
        //     user = await Auth.appLogin(JSON.parse(searchQuery.a));
        // } else {
        //     // Try to autologin
        //     user = await Auth.autologin()
        // }
        // const { user } = this.props.auth;
    }

    onLogin = (registering = false) => {
        this.props.modal.setView(<><i className="fas fa-user"></i> {AppContext.r["login"]}</>, 
            <Login modal={this.props.modal} isApp={this.state.isApp} registering={registering} enableFacebookLogin
                onLoggedIn={this.onLoggedIn} onResetPasswordRequested={this.props.modal.hide} />, true);
    }

    onLoggedIn = async (response) => {
        this.setState({
                user: Auth.user
            }
        );

        if(this.onObserverAccessed)
            this.onObserverAccessed(Auth.user);
    }

    // Invoked by the app
    onLoggedWithFacebook = async (data) => {
        const response = await AppContext.Auth.login(data.email, null, "social", "facebook", data.token, data.first_name, data.last_name);

        if(response) {
            if (response.status === 200) {
                this.props.modal.hide();

                if(this.onObserverAccessed)
                    this.onObserverAccessed(AppContext.Auth.user);
            } else {
                // Authorization Failed
                this.setState({
                    modalBusy: false,
                    modalMessage: <div>
                        <i className="far fa-frown icon"></i>
                        <p className="message">{AppContext.r["l-wrong-credentials"]}</p>
                        <Button onClick={this.setModalFree}>{AppContext.r['close']}</Button>
                    </div>
                });
            }
        }
    }

    onManageContacts = () => {
        this.props.modal.setView(AppContext.r["contacts"], <Contacts />, true);
    }

    onLoggedOut = () => {
        Auth.logout();

        this.setState({
            user: null
        })

        if(this.onObserverLoggedOut)
            this.onObserverLoggedOut();
    }

    onToggleUserSettings = (toggleOpen) => {
        this.setState({
            showUserSettings: toggleOpen ? toggleOpen : !this.state.showUserSettings
        });
    }
    
    setNotification = (notification) => {
        this.setState({
            notification: notification
        });
    };

    // setHighlightedStand = (id) => {
    //     if(this.hlStandId !== id) {
    //         this.setState({
    //             hlStandId: id
    //         });
    //     }
    // }

    onStandClick = (stand) => {
        const route = "/" + (stand ? (stand.subdomain ? stand.subdomain : stand.name) : "");
        console.log(route, stand)

        AppContext.history.push(route);

        this.setState({
            drawerOpen: false
        });

        if(stand && this.props.onStandClick)
            this.props.onStandClick(stand);
    }

    handleDrawerToggle = (open = null) => {
        this.setState({
            drawerOpen: (open !== null) ? open : !this.state.drawerOpen
        });
    }

    handleDrawerOpen = () => this.handleDrawerToggle(true);

    handleDrawerClose = () => this.handleDrawerToggle(false);
    
    render() {
        const { standsData } = this.state;
        const { auth } = this.props;

        // const setNotification = (notification) => this.setState({notification: notification});

        const authActions = {
            onLogin: this.onLogin,
            onRegister: () => { this.onLogin(true); },
        };

        const childrenWithProps = Children.map(this.props.children, child => {
            // Checking isValidElement is the safe way and avoids a TS error too.
            if (isValidElement(child)) {
                return cloneElement(child, { authActions: authActions, setNotification: this.setNotification })
            }

            return child;
        });

        // const onMaximizeVideo = () => {
        //     const isleDiv = document.querySelector(".isle");
        //     console.log(isleDiv.firstChild)
        // }

        // const now = new Date();
        // const launchDate = new Date("2022-03-01").setHours(0,0,0,0);

        return (
            <>
                <CookiesNotification />
                
                {!this.props.hideHeader &&
                    <Header authActions={authActions} onWelcomeVideoClick={this.showWelcomeVideo} /> }

                {!this.props.disableEventsNotification && 
                    <EventsNotification /> }

                {/* {(now > launchDate || (auth.user && auth.user.roles.length > 0)) && */}
                    <>
                        <IconButton className="legend-menu-toggle" color="inherit" edge="start" aria-label="open drawer"
                            onClick={this.handleDrawerOpen}>
                            <MenuIcon style={{color: "#333"}} />
                        </IconButton>

                        <Hidden smUp implementation="css">
                            <Drawer variant="persistent" anchor={'right'}
                                open={this.state.drawerOpen}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}>
                                <div>
                                    <IconButton onClick={this.handleDrawerClose}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </div>

                                {(!this.props.hideHeader && standsData) && 
                                    <Legend stands={standsData} onOver={this.props.setHighlightedStand} onClick={this.onStandClick} /> }
                            </Drawer>
                        </Hidden>

                        <Hidden xsDown implementation="css">
                            <Drawer variant="persistent" anchor={'right'}
                                open={this.state.drawerOpen}>
                                <div>
                                    <IconButton onClick={this.handleDrawerClose}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </div>

                                {(!this.props.hideHeader && standsData) && 
                                    <Legend stands={standsData} onOver={this.props.setHighlightedStand} onClick={this.onStandClick} /> }
                            </Drawer>
                        </Hidden>
                    </> 
                    {/* } */}
                <ZoomWidget />

                <div className={"layout " + this.props.className}>
                    {childrenWithProps}

                    <Footer hide={this.props.hideFooter} />
                </div>
                
                <Snackbar open={this.state.notification} autoHideDuration={6000} onClose={this.handleCloseNotification}>
                    <Alert onClose={this.handleCloseNotification} severity="success">
                        {this.state.notification}
                    </Alert>
                </Snackbar>
{/* 
                <Isle title="Agnes" params={{ scale: 1, maxScale: 3, x: window.innerWidth / 2, y: window.innerHeight / 2 }}>
                    <WelcomeVideo />
                </Isle> */}
            </>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => {
    return {
        resetCart: () => {
            dispatch(cart.actions.resetCart());
        }
    }
};

export default withModal(connect(mapStateToProps, mapDispatchToProps)(Layout));