import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import IconButton from '@material-ui/core/IconButton';

// import AppContext from "app/AppContext";

export default class Footer extends React.Component {
    item(text, path) {
        return (
            <ListItem>
                <ListItemIcon>
                    <ChevronRightIcon />
                </ListItemIcon>

                {path.indexOf("http") === 0 ?
                    <ListItemText
                        primary={<a href={path} target="_blank" rel="noreferrer">{text}</a>} /> :
                    <ListItemText
                        primary={<Link to={path}>{text}</Link>} /> }
            </ListItem>);
    }

    render() {
        if(this.props.hide)
            return "";

        return (
            <footer>
                {/* <Row className="justify-content-center">
                    <Col md={4}>
                        <h2>{AppContext.s["project-name"]}</h2>

                        <List dense={true}>
                            {this.item(AppContext.r["who-we-are"], "https://www.uderly.com")}
                            {this.item(AppContext.r["contact-us"], "https://www.uderly.com/#contact")}
                        </List>
                    </Col>

                    <Col md={4}>
                        <h2>Info</h2>

                        <List dense={true}>
                            {this.item(AppContext.r["privacy-policy"], "/privacy")}
                            {this.item(AppContext.r["terms"], "/terms")}
                        </List>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <img className="logo-footer" src={AppContext.s["logo-grey"]} alt="IQ Space" />
                </Row> 
                    
                <Row className="justify-content-center socials">
                    {AppContext.s["facebook-page-url"] && 
                        <IconButton color="transparent" target="_blank" rel="noreferrer"
                            href={AppContext.s["facebook-page-url"]}>
                            <i className={"fab fa-facebook"} />
                        </IconButton>}

                    {AppContext.s["twitter-page-url"] && 
                        <IconButton color="transparent" target="_blank" rel="noreferrer"
                            href={AppContext.s["twitter-page-url"]}>
                            <i className={"fab fa-twitter"} />
                        </IconButton>}

                    {AppContext.s["instagram-page-url"] && 
                        <IconButton color="transparent" target="_blank" rel="noreferrer"
                            href={AppContext.s["instagram-page-url"]}>
                            <i className={"fab fa-instagram"} />
                        </IconButton>}

                    {AppContext.s["linkedin-page-url"] && 
                        <IconButton color="transparent" target="_blank" rel="noreferrer"
                            href={AppContext.s["linkedin-page-url"]}>
                            <i className={"fab fa-linkedin"} />
                        </IconButton>}
                </Row>*/}
 
                <Row className="justify-content-center">
                    {/* <div className="flags">
                        {AppContext.s["language"] === "it" && <a href="https://www.iqspace.co.uk"><img src="/assets/images/uk-flag.svg" alt="English" /> English's Website</a>}
                        {AppContext.s["language"] !== "it" && <a href="https://www.iqspace.it"><img src="/assets/images/italy-flag.svg" alt="Italiano" /> Sito in Italiano</a>}
                    </div> */}
                    <div className="signature">
                        <p>Copyright © <b>WoolSafe Ltd</b> (No: 4543752. Registered in England and Wales.)</p>
                    </div> 
                </Row>
            </footer>
            );
    }
}
