import React from 'react';
import { Row, Col } from 'react-bootstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ReactPlayer from 'react-player';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default class VideoGallery extends React.Component {
    state = {
        streams: this.props.streams ? this.props.streams : [],
        stream: (this.props.streams && this.props.streams.length > 0) ? this.props.streams[0] : null,
        autoPlay: this.props.autoPlay === true,
        playing: this.props.autoPlay === true,
        videoWidth: this.props.videoWidth ? this.props.videoWidth : 640,
        videoHeight: this.props.videoHeight ? this.props.videoHeight : 360,
    };

    async componentDidMount() {
        window.addEventListener("resize", this.onResize);

        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.playing !== this.props.playing) {
            this.setState({
                playing: nextProps.playing
            });
        }
    }

    onResize = () => {
        setTimeout(() => {
            const { videoWidth, videoHeight } = this.state;

            const ratio = videoWidth / videoHeight;
            const width = document.getElementById('playerBox').clientWidth;
            const height = width / ratio;

            this.setState({
                videoHeight: height
            });
        }, 100);
    }

    onEnded = () => {
        const {streams, stream, autoPlay} = this.state;

        if(autoPlay) {
            const index = streams.indexOf(stream);
            console.log(stream, index);
    
            const nextStream = (streams[index + 1]) ? streams[index + 1] : streams[0];
    
            this.setState({
                stream: nextStream,
                playing: true
            });
        }
    }

    onPause = () => {
        const {playing} = this.state;

        if(playing)
            this.setState({playing: false});
    }

    render() {
        const { streams, stream, playing, videoWidth, videoHeight } = this.state;
        const { column, autoPlay, hideList } = this.props;

        var i = 0;

        return (
            <Row className="video-gallery">
                {!hideList && 
                    <Col sm={column ? 12 : 3}>
                        <List component="nav" className="list" aria-label="secondary" 
                            style={{maxHeight: videoHeight}}>
                            {streams.map(stream => (
                                <div key={i++ + "video-list-item"}>
                                    <ListItem button onClick={() => this.setState({stream: stream})}
                                        selected={this.state.stream === stream}>
                                        <ListItemText primary={stream.title} />
                                    </ListItem>
                                    
                                    <Divider />
                                </div>))}
                        </List>
                    </Col> }

                <Col sm={column ? 12 : 9} className="player-box" id="playerBox">
                    {stream &&
                        <ReactPlayer controls={true} playing={playing} muted={autoPlay === true} 
                            width={videoWidth} height={videoHeight}
                            url={stream.url} onEnded={this.onEnded} onPause={this.onPause}
                            light={false} /> }
                </Col>
            </Row>);
    }
}