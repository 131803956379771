import { actionTypes } from "./cartRedux";

export const actions = {
    addToCart: (product, quantity, extras, note) => {
        let totalExtras = 0;
        if(extras)
            for(let extra of extras) {
                totalExtras += extra.price;
            }

        const price = product.price + totalExtras;
        
        return { type: actionTypes.AddToCart, payload: { product, quantity, extras, note, price } };
    },
    removeFromCart: cartItemId => ({ type: actionTypes.RemoveFromCart, payload: { cartItemId } }),
    updateCartQuantity: (cartItemId, quantity) => (
        {type: actionTypes.UpdateCartQuantity, payload: { cartItemId, quantity } }),
    resetCart: () => ({ type: actionTypes.ResetCart, payload: { } }),
};