import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import AppContext from "../../../../AppContext";

export const actionTypes = {
    AddToCart: "[Add To Cart] Action",
    UpdateCartQuantity: "[Update Cart Quantity] Action",
    RemoveFromCart: "[Remove From Cart] Action",
    ResetCart: "[Reset Cart] Action"
};

const initialCartState = {
    cart: []
};

let storeProductsId = null;

function itemHasNotExtraInfo(item) {
    return ((!item.extras || item.extras.length == 0) && (!item.note || item.note === ""));
}

export const reducer = persistReducer(
  { storage, key: AppContext.s["redux-cart-key"], whitelist: ["cart"] },
  (state = initialCartState, action) => {
    switch (action.type) {
        case actionTypes.AddToCart: {
            const item = action.payload;
            let counted = false;

            const cart = [...state.cart];

            console.log("AddToCart", cart, item);

            // Attempt to gather items 
            if(itemHasNotExtraInfo(item)) {
                for(var i of cart) {
                    if(i.product.id === item.product.id && itemHasNotExtraInfo(i)) {
                        i.quantity += item.quantity;
                        counted = true;
                        break;
                    }
                }
            }

            if(storeProductsId === null)
                if(cart.length > 0)
                    storeProductsId = cart[cart.length-1].cartItemId;
                else
                    storeProductsId = 0;
            
            if (!counted) {
                cart.push({
                    product: item.product,
                    cartItemId: ++storeProductsId,
                    quantity: item.quantity,
                    extras: item.extras,
                    note: item.note,
                    price: item.price
                });
            }

            return {
                ...state,
                cart: cart
            };
        }

        case actionTypes.UpdateCartQuantity: {
            const cart = [...state.cart];
            const item = cart.find(item => item.cartItemId === action.payload.cartItemId);

            if(item) {
                item.quantity = action.payload.quantity;

                return {
                    ...state,
                    cart: cart
                };
            }

            return {
                ...state
            };
        }

        case actionTypes.RemoveFromCart: {
            let cart = [...state.cart];
            const item = cart.find(item => item.cartItemId === action.payload.cartItemId);
        
            if(item) {
                // TODO: Find the last inserted item and remove THAT one

                // Reduce quantity or remove the cart item getting to zero
                if(item.quantity > 1)
                    item.quantity--;
                else
                    cart = cart.filter(item => item.cartItemId !== action.payload.cartItemId)
            }

            return {
                ...state,
                cart: cart
            };
        }

        case actionTypes.ResetCart: {
            return {
                ...state,
                note: "",
                cart: []
            }
        }

        default:
            return state;
    }
  }
);