// Modal v1.2

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import AppContext from 'app/AppContext';

export function withModal(OriginalComponent) {
    return class extends React.Component {
        state = {
            className: "",
            busy: false,
            message: null,
            show: false,
            title: null,
            header: null,
            view: "",
            footer: null
        }

        onCloseModal = () => {
            this.setState({
                show: false,
            });
        }

        setModalMessage = (message, isBusy = false) => {
            this.setState({
                busy: isBusy,
                message: message
            });
        }

        setFree = () => {
            this.setState({
                busy: false,
                message: null
            });
        }

        setModalBusy = (busy = true) => {
            this.setState({
                busy: busy,
            });
        }
            
        overlay = () => {
            if(this.state.busy || this.state.message != null)
                return (
                    <div className="modal-overlay"
                        ref={el => {
                            if (!el) return;
                            //console.log(el.getBoundingClientRect());
                        }}>
                        {this.state.busy && AppContext.r['preloader']}

                        <div className="message">
                            {this.state.message}
                        </div>
                    </div>);

            return "";
        }

        setModalTitle = (title) => {
            this.setState({
                title: title,
            })
        }

        setModalView = (title, view, show = null, className = null, footer = null) => {
            this.setState({
                title: title,
                view: view,
                show: show ? show : this.state.show,
                className: className ? className : this.state.className,
                footer: footer
            })
        }

        confirm = (title, text, callback) => {
            this.setState({
                title: title,
                view: text,
                footer: (
                    <>
                        <Button variant="secondary" onClick={this.onCloseModal}>
                            {AppContext.r["no"]}
                        </Button>
                        
                        <Button variant="danger" onClick={() => callback()}>
                            {AppContext.r["yes"]}
                        </Button>
                    </>
                ),
                show: true
            })
        }

        show = (show = true) => {
            this.setState({
                show: show
            })
        }

        hide = () => {
            this.setState({
                show: false,
                busy: false
            })
        }

        render() {
            const newProps = {
                modal: {
                    confirm: this.confirm,
                    setMessage: this.setModalMessage,
                    setTitle: this.setModalTitle,
                    setView: this.setModalView,
                    setFree: this.setFree,
                    setBusy: this.setModalBusy,
                    show: this.show,
                    hide: this.hide,
                }
            }

            // Return original component with additional props
            return <div>
                    <OriginalComponent {...this.props} {...newProps} />

                    <Modal show={this.state.show} onHide={this.onCloseModal} className={this.state.className}>
                        <Modal.Header closeButton>
                            { this.state.header && this.state.header }
                            { this.state.title && <Modal.Title>{this.state.title}</Modal.Title> }
                        </Modal.Header>
                        
                        <Modal.Body>
                            {this.state.view}
                        </Modal.Body>

                        { this.state.footer &&
                            <Modal.Footer>
                                { this.state.footer }
                            </Modal.Footer>
                        }

                        {this.overlay()}
                    </Modal>
                </div>
        }
    }
}