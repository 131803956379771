import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import AppContext from 'app/AppContext';
import AuthApi from 'api/Auth';
import { withModal } from '../../../../framework/Modal';
import withOverlay from '../../../../framework/withOverlay';
import { Redirect } from 'react-router';

const qs = require('querystring');

class ResetPassword extends React.Component {
    state = {
        redirectTo: null,
        message: null
    }

    resetPassword = async (email, password, newPassword) => {
        const searchQuery = qs.parse(AppContext.history.location.search.replace("?", ""));

        const response = await AuthApi.resetPassword(searchQuery.token, email, password, newPassword);
        console.log(response);

        let message = "";

        if(response) {
            if (response.status === 200) {
                message = <div>
                        <p><i className="far fa-smile icon"></i> {AppContext.r["password-resetted"]}</p>
                        <Button onClick={() => this.setState({redirectTo: "/"})}>{AppContext.r['close']}</Button>
                    </div>;
            } else {
                //if(response.status === 422) { // Authorization Failed
                    message = <div>
                        <p><i className="far fa-frown icon"></i> {AppContext.r["unprocessable-reset"]}</p>
                        <Button onClick={() => this.props.overlay.hide()}>{AppContext.r['close']}</Button>
                    </div>;
                //}
            }
        } else {
            message = AppContext.r['server-not-working'];
        }

        this.props.overlay.setView(message, true);
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect push to={this.state.redirectTo} />;
        }

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(AppContext.r['invalid-email'])
                .required(AppContext.r['email-required']),
            password: Yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required']),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], AppContext.r['passwords-must-match'])
        });

        const t = this;

        return (
            <Container className="page">
                <h2 className="text-center"><i className="fas fa-key"></i> {AppContext.r["reset-password"]}</h2>

                <Row className="justify-content-center">
                    <Col sm={6}>
                        <div className="reset-password">
                            <p>{AppContext.r["all-fields-required"]}</p>
                            <p>{AppContext.r["password-desc"]}</p>

                            <Formik
                                initialValues={{
                                    email: "",
                                    password: "",
                                    password_confirmation: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={fields => {
                                    console.log(fields)

                                    t.resetPassword(fields.email, fields.password, fields.new_password);
                                }}
                                render={({ errors, touched }) => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">{AppContext.r["new-password"]}</label>
                                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password_confirmation">{AppContext.r["new-password-confirmation"]}</label>
                                            <Field name="password_confirmation" type="password" className={'form-control' + (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')} />
                                            <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary mr-2">{AppContext.r["change-password"]}</button>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withModal(withOverlay(ResetPassword))