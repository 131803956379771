import React from 'react';
import { connect } from 'react-redux';

import WoolSafeApi from "api/WoolSafe";
import { withModal } from '../../framework/Modal';
import EventCards from '../../components/EventCards';
import AppContext from 'app/AppContext';

class Events extends React.Component {
    state = {
        store: null
    };

    async componentDidMount() {
        const response = await WoolSafeApi.Stand(1);

        if(response && response.status === 200 && response.data) {
            console.log(response.data.data)
            this.setState({
                store: response.data.data
            })
        }
    }

    render() {
        const { store } = this.state;

        return (
            <div className="page events">
                <h1>Our Next Events</h1><br />

                {!store ? AppContext.r["preloader"] : <EventCards store={store} authActions={this.props.authActions} />}
            </div>);
    }
}

export default withModal(connect(null, null)(Events));