import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FacebookProvider, LoginButton } from 'react-facebook';
import * as Yup from 'yup';

import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login, getUserByToken } from "../_redux/authCrud";

import AppContext from 'app/AppContext';
import AuthApi from "api/Auth";
import Register from '../partials/Register';

class AccessViewModeEnum {
    static get Login () { return 1; }
    static get PasswordForgotten () { return 2; }
    static get Register () { return 3; }
}

class Login extends React.Component {
    state = {
        modalBusy: false,
        modalMessage: null,
        viewMode: this.props.registering === true ? AccessViewModeEnum.Register : AccessViewModeEnum.Login
    };

    login = async (email, password, grantType = "password", provider = "", accessToken = "", name = "", surname = "") => {
        this.props.modal.setBusy(true);

        const response = await login(email, password, grantType, provider, accessToken, name, surname);
        console.log(response);
        
        if(response) {
            if (response.status === 200) {
                const response2 = await getUserByToken();
        
                console.log(response2);
                //this.props.modal.setFree();

                AuthApi.cacheTokenFromResponse(response);
                console.log(this.props.login(response.data.access_token));

                if(this.props.onLoggedIn)
                    this.props.onLoggedIn(response);
            } else {
                // Authorization Failed
                this.props.modal.setBusy(false);
                this.props.modal.setMessage(<>
                    <i className="far fa-frown icon"></i>
                    <p className="message">{AppContext.r["l-wrong-credentials"]}</p>
                    <Button onClick={this.props.modal.setFree}>{AppContext.r['close']}</Button>
                </>);
            }

            return response;
        } else {
            return AppContext.r['server-not-working'];
        }
    }

    onResetPasswordRequested = () => {
        this.props.modal.setBusy(false);

        if(this.props.onResetPasswordRequested)
            this.props.onResetPasswordRequested();
    }

    requestResetPassword = async (email) => {
        this.props.modal.setBusy(true);

        const response = await AuthApi.requestResetPassword(
            email, "https://www." + AppContext.s.domain + "/resetpassword", AppContext.s["language"]);

        if(response) {
            let modalMessage = "";

            if (response.status === 200) {
                modalMessage = (
                    <>
                        <i className="fas fa-thumbs-up icon"></i>
                        <p className="message">{AppContext.r["password-reset-requested"]}</p>
                        <Button onClick={this.onResetPasswordRequested}>{AppContext.r['close']}</Button>
                    </>);
            } else {
                // Authorization Failed
                modalMessage = (
                    <>
                        <i className="far fa-frown icon"></i>
                        <p className="message">{AppContext.r["something-wrong"]}</p>
                        <Button onClick={this.props.modal.setFree}>{AppContext.r['close']}</Button>
                    </>);
            }

            this.props.modal.setBusy(false);
            this.props.modal.setMessage(modalMessage);

            return response;
        } else {
            return AppContext.r['server-not-working'];
        }
    }

    onRegistered = async (user) => {
        console.log("onRegistered", user);
        await this.login(user.email, user.password);
    } 

    handleFacebookResponse = async (data) => {
        console.log("handleFacebookResponse", data);
        const response = await this.login(data.profile.email, null, "social", "facebook", data.tokenDetail.accessToken, data.profile.first_name, data.profile.last_name);
        if(response) {
            if(response.status === 200)
                this.props.modal.hide();
                
            if(response.status === 400)
                this.props.modal.setMessage(<>
                    <i className="far fa-frown icon"></i>
                    <p className="message">{AppContext.r["social-login-failed"]}</p>
                    <Button onClick={this.props.modal.setFree}>{AppContext.r['close']}</Button>
                </>);
        }
    }

    handleError = (error) => {
        console.log(error);
    }

    onLoadingFacebookLogin = () => {
        console.log("loading")
    }

    // FB Login - Duplicated code in Access.js [End]

    inAppFacebookLogin = () => {
        const a = {
            action: "login-with-facebook",
            data: null
        };

        window.invokeCSCode(JSON.stringify(a));
    }

    render() {
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(AppContext.r['invalid-email'])
                .required(AppContext.r['email-required']),
            password: Yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required'])
        });

        const forgottenPasswordValidationSchema = Yup.object().shape({
            email: Yup.string()
                .email(AppContext.r['invalid-email'])
                .required(AppContext.r['email-required']),
        });

        const facebookLoginButton = !this.props.isApp ?  (
            <FacebookProvider appId={AppContext.s["facebook-app-id"]}>
                <LoginButton 
                    scope="email"
                    onLoading={this.onLoadingFacebookLogin}
                    onCompleted={this.handleFacebookResponse}
                    onError={this.handleError}>
                    <span><i className="fab fa-facebook-f"></i> {AppContext.r['access-with-facebook']}</span>
                </LoginButton>
            </FacebookProvider>
        ) : (
            <Button variant="link" onClick={this.inAppFacebookLogin}>
                <span><i className="fab fa-facebook-f"></i> {AppContext.r['access-with-facebook']}</span>
            </Button>
        );

        const t = this;

        return (
            <div className="login-modal-body">
                { this.state.viewMode === AccessViewModeEnum.Login &&
                    <>
                        {(this.props.enableFacebookLogin && AppContext.s["facebook-app-id"]) &&
                            <>
                                <div className="facebook-button">
                                    {AppContext.s["facebook-app-id"] && facebookLoginButton}
                                </div>
                                
                                <p className="text-center">{AppContext.r['or-signin-via-email']}</p>
                            </>}

                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (fields) => {
                                console.log(fields)

                                const response = await t.login(fields.email, fields.password);

                                if(response && response.status === 200)
                                t.props.modal.hide();
                            }}
                            render={({ errors, status, touched }) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>

                                    <Row className="justify-content-center">
                                        <Button type="submit" variant="primary" className="login-button rounded-button">{AppContext.r["login"]}</Button>
                                    </Row>
                                </Form>
                            )}
                        />
                        
                        <hr/>

                        <div className="register-invitation">
                            <p>{AppContext.r['dont-you-have-an-account']}</p>
                            <Button variant="link" onClick={() => this.setState({ viewMode: AccessViewModeEnum.Register })}>{AppContext.r["signup"]}</Button>
                        </div>

                        <Button className="forgotten-password-button" variant="link" onClick={() => this.setState({ viewMode: AccessViewModeEnum.PasswordForgotten })}>{AppContext.r["password-forgotten"]}</Button>
                    </>
                }

                { this.state.viewMode === AccessViewModeEnum.PasswordForgotten &&
                    <>
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={forgottenPasswordValidationSchema}
                            onSubmit={fields => {
                                console.log(fields)

                                t.requestResetPassword(fields.email);
                            }}
                            render={({ errors, touched }) => (
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>

                                    <div className="form-group">
                                        <Button type="submit">{AppContext.r["request-reset-password"]}</Button>
                                    </div>
                                </Form>
                            )} />

                        <Button variant="link" onClick={() => this.setState({ viewMode: AccessViewModeEnum.Login })}>{AppContext.r["cancel"]}</Button>
                    </>
                }

                { this.state.viewMode === AccessViewModeEnum.Register &&
                    <>
                        <Register modal={this.props.modal} onRegistered={this.onRegistered} inline={this.props.inline}
                            enableFacebookLogin handleFacebookResponse={this.handleFacebookResponse} />

                        {!this.props.disableLogin &&
                            <Button variant="link" onClick={() => this.setState({ viewMode: AccessViewModeEnum.Login })}>{AppContext.r["cancel"]}</Button> }
                    </>
                }
            </div>);
    }
}

export default connect(null, auth.actions)(Login);