import React from 'react';
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

import Settings from '../../settings';

export default function loadClientResources(resources) {
    resources['tag-line'] = <>IQ Space is the new <b>live quiz</b> that will skyrocket your entertainment business.</>;
    
    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connecting...";
    resources["rts-connected"] = "Connected. I'm subscribing to the server...";
    resources["rts-disconnected"] = "Disconnected. :/ Attempting reconnection...";
    resources["rts-couldnt-connect"] = "The game is not available right now due to a connection issue to the server. Please make sure you are connected to the internet and try refreshing the page in a bit.";
    resources["rts-subscription-failed"] = "Invocation of subscribeViewer failed. Error: ";
    
    resources['access-the-game'] = "Access the game";
    resources['access-the-game-desc'] = "Would you like to join the game as {name}?";
    // resources['access-the-game-change-name'] = <;
    resources['ranking'] = "Leaderboard";
    resources['partial-ranking'] = "Partial Leaderboard Last Question";
    resources['invite-players'] = "Hey there! To play the game, access ";
    resources['last-events'] = "Last Events";
    resources['upcoming-events'] = "UPCOMING EVENTS";
    resources['upcoming-event'] = "UPCOMING EVENT";
    resources['game-started'] = "GAME STARTED";
    resources['game-completed'] = "GAME COMPLETED";
    resources['new-game-starting-soon'] = "A new game is starting soon.";
    resources['congrats-winners'] = "Congrats Winners";
    resources['join-the-game'] = "JOIN THE GAME";
    resources['game-not-existing'] = "This game does not exist or has not been started yet.";
    resources['event-not-existing'] = "This event does not exist. :/";
    resources['game-expired'] = "This game has expired.";
    resources['game-stopped'] = "This game has been stopped by the quizmaster.";
    resources['game-stopped'] = "";
    resources['ranking-position'] = "You finished in position {0} with a score of {1}.";
    resources['click-here'] = "click here";
    resources['browse-to-event-page'] = " to see the final leaderboard and the event page.";
    resources['show-leaderboard'] = "Show Leaderboard";

    resources["keep-up-to-date"] = "Keep your profile's public information up-to-date";
    resources["upcoming-events"] = "Your upcoming events at a glance";
    resources["no-upcoming-events"] = "No upcoming events";
        
    resources["events"] = "Events";
    resources["event"] = "Event";
    resources["title"] = "Title";
    resources["datetime"] = "Date & Time";
    resources["timezone"] = "Timezone";
    resources["youtube-url"] = "YouTube Url";
    resources["facebook-url"] = "Facebook Url";
    resources["instagram-url"] = "Instagram Url";
    resources["twitter-url"] = "Twitter Url";
    resources["event-link"] = "Event Link";
        
    resources["packages"] = "Packages";
    resources["package"] = "Package";

    resources["games"] = "Games";
    resources["game"] = "Game";
    resources["event-id"] = "Event Id";
    resources["started"] = "Started";
    resources["stopped"] = "Stopped";
    resources["duration"] = "Duration";
    resources["paused"] = "Paused";
    resources["status"] = "Status";

    resources["categories"] = "Categories";
    resources["category"] = "Category";
    resources["color"] = "Color";
    resources["points"] = "Points";
    
    resources["questions"] = "Questions";
    resources["question"] = "Question";
    resources["text"] = "Text";
    resources["note"] = "Note";
    resources["answers"] = "Answers";
    resources["answer"] = "Answer";
    resources["image-duration"] = "Image Duration";
    resources["collection-not-editable"] = "This collection is not editable.";

    resources['exact'] = "Exact";
    resources['wrong'] = "Wrong";
    resources['not-answered'] = "Not Answered";
    resources['players'] = "Players";
    resources['ranking'] = "Leaderboard";
    resources['final-ranking'] = "Final Leaderboard";
    resources['sorry'] = "Sorry!";

    resources['game-started-full'] = (
        <div>
            <h1>Take a seat</h1>
            <p>The game is about to start</p>
        </div>);

    resources['play'] = "Play";
    resources['join-the-game'] = "JOIN THE GAME";
    resources['cookie-terms'] = "By joining the game you authorize us to the usage of cookies, which are necessary for the game functionality.";
    resources['quit'] = "Quit Game";
    resources['insert-name'] = "Enter your name";

    resources['welcome-enabled'] = (<div>
        <p>Welcome on board, you joined the game.</p>
        <p>You are gonna play since next question.</p>
        <p className='internet-connection-box'>This game needs an internet connection. To reduce data usage, connect your phone to Wi-Fi.</p>
        <p>Have fun!"</p>
    </div>);
    resources['welcome-pending'] = "<p>Hey <span className=\"teamName\">&nbsp;</span>, we are almost done!</p><p>Please inform the quizmaster that you are keen to play to get approved.</p>";
    resources['name-taken'] = "Couldn't join this game with the given team name since it is already taken.";
    resources['max-players-count'] = "Couldn't join this game because the maximum number of players has been reached.";
    resources['name-error'] = "In order to join the game you need to specify your name.";
    resources['you-scored'] = "You scored ";
    resources['confirm-quit-game'] = "Exiting the game you are gonna lose all your progress and your score. Are you sure you wanna leave?";

    resources['quiz-rules'] = "Quiz Rules";
    resources['on-air-in'] = "On air in...";
    resources['logged-on-other-device'] = "TriviaPro is open in another window. Click \"play\" to rejoin the game in this window.";
    resources['kicked-out'] = "You have been removed from this game by the administration.";

    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connecting...";
    resources["rts-connected"] = "Connected. I'm subscribing to the server...";
    resources["rts-disconnected"] = "Disconnected. :/ Attempting reconnection...";
    resources["rts-couldnt-connect"] = "The game is not available right now due to a connection issue to the server. Please make sure you are connected to the internet and try refreshing the page in a bit.";
    resources["rts-subscription-failed"] = "Invocation of subscribeViewer failed. Error: ";
    resources["user-area"] = "User Area";
    resources["quizmaster-portal"] = "IQ Space Portal";

    resources["already-joined"] = <>
        <p>It looks like you are already a member of our community. Go to <a href="https://portal.iqspace.co.uk">{resources["quizmaster-portal"]}</a> to manage your game events.</p></>
    resources["wants-to-join-community"] = "Would you like to join our community?";
    resources["fill-the-form-to-join"] = "Fill the form to become an " + Settings["project-name"] + " star.";
    resources["joined-congrats"] = <>
        <p>Awesome!</p>
        <p>You have successfully joined our community.</p>
        <p>Go to <a href="https://portal.iqspace.co.uk">{resources["quizmaster-portal"]}</a> to create your first game event.</p>
    </>
    resources["joined-failed"] = "Request Failed";
    resources["joined-failed-description"] = <><p>Something went work when submitting your request.</p><p><Link to="/contact">Contact us</Link> and we will be happy to help you and set up your account with us.</p></>;
    resources["submit-request"] = "Submit Request";
    resources["pagename"] = "Page Name";
    resources["promo-code"] = "Promo Code";
    resources["can-be-creative"] = "You can be creative! 💡";
    resources["your-space"] = "Your Space";
    resources["your-space-desc"] = <><small>As an affiliated entertainer, you'll have your own page on IQ Space, displaying your events and other nice info about you.</small>
        <br /><small>How cool is that? 😎</small></>;
    // resources["another-country"] = "";
    resources["your-events-will-be"] = <>Yep! That works!<br />Your events will be presented at the page</>;
    resources["nickname-validation"] = "The page name must be at least 6 characters and can contain only lowercase letters, numbers and dashes.";
    resources["organization-name"] = "Name of your organization";
    resources["must-reconnect"] = "There has been a problem and we couldn't connect to the game.";
    resources["reload-game"] = "Reload the game";
    resources["login-to-play"] = "to play.";
    resources["game-option-quit-tooltip"] = "Quit The Game";
    resources["game-option-sounds-on-tooltip"] = "Switch Sounds On";
    resources["game-option-sounds-off-tooltip"] = "Switch Sounds Off";
    resources["game-option-players-tooltip"] = "Show Players";

    resources["past-events"] = "Past Events";
    resources["no-past-events"] = "No Past Events";
    resources["upcoming-events"] = "Upcoming Events";
    resources["no-upcoming-events"] = "No Upcoming Events";
    resources["enable-audio"] = "Enable Audio";
    resources["disable-audio"] = "Disable Audio";
    resources["powered-by"] = "powered by";
    resources["like-us-desc"] = <>Follow us to keep up with<br/>our next events.</>;
    resources["like-this-game"] = <><p>Did you like this game?</p><p>{resources["like-us-desc"]}</p></>;
    resources["no-chat-messages"] = "No one has written in the chat yet. Be the first to type something.";
    resources["access-to-chat"] = "Access the game to chat";
    resources["game-name"] = "Game Name";
    resources["game-name-desc"] = "This is your public name to play with.";

    resources["home-banner"] = 
    (<>
        <p>Dear entertainers...</p>
        <h1>IQ Space is coming</h1>
        <p>IQ Space is the new <b>live quiz</b> to skyrocket your entertainment business.</p> {/*🚀 */}
        <Icon className="fas fa-rocket" />
        <p>IQ Space embeds <b>Zoom</b> for real-time face-to-face engagement with the players and a <b>social chat</b> for instant interaction.</p>
        <p>Start to sell your <b>event tickets</b> directly from the IQ Space ticketing system.</p>
        <p><Link to="workwithus">Start to <s>work</s> play with us now.</Link></p>
    </>);
    resources["buy-ticket"] = "Buy Ticket";
    resources["your-tickets"] = "Your Tickets";
    resources["purchased-on"] = "Purchased On";
    resources["ticket-id"] = "Ticket Id";
    resources["voucher-code"] = "Voucher Code";
    resources["paid"] = "Paid";
    resources["acquired"] = "Acquired";
    resources["acquired-with"] = "Acquired with ";
    resources["refunded"] = "Refunded";
    resources["refunds"] = "Refunds";
    resources["start-button"] = <span>Start (for free) to <s>work</s> play with us now</span>;
    resources["not-found"] = <>Page not found.<br/>Please check you are browsing the correct url.</>;
    resources["voucher"] = "Voucher";
    resources["have-voucher"] = "Do you have a voucher?";
    resources["apply"] = "Apply";
    resources["voucher-used"] = "This voucher has already been used.";
    resources["voucher-not-valid"] = "This voucher code is not valid.";
    resources["got-free-ticket"] = "Congrats! You got a free ticket!";
    resources["following"] = "Following";
    resources["follow"] = "Follow";
    resources["unfollow"] = "Unfollow";
    resources["not-following-anyone"] = "You are not following anyone yet.";
    resources["work-from-x"] = ["the pub", "home", "the bar", "the beach (why not?)", "your TV studio", "anywhere 😍"];
    resources["allow-discoverable"] = "Would you like to be sitewide promoted or contacted by IQ Space and its close partners for work opportunities?";
    resources["go-to-event"] = "View event";
}