import React from 'react';
import ReactDOM from 'react-dom';
import { Row } from 'react-bootstrap';
import { connect } from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import AppContext from 'app/AppContext.js';
import WoolSafeApi from 'api/WoolSafe';
import {withModal} from 'framework/Modal';
import VideoGallery from 'components/VideoGallery';
import Socials from 'framework/Socials';
import Products from 'components/Products';
import BookStand from 'components/BookStand';
import StoreContacts from 'components/StoreContacts';
import StoreTeam from 'components/StoreTeam';

var HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class Stand extends React.Component {
    state = {
        store: null,
        loading: false,
        popoverOpen: false,
        anchorEl: null,
        totemTab: 0
    };

    fetchStore = async (storeToFetch = null) => {
        const {loading} = this.state;

        if(!loading) {
            this.setState({ loading: true });

            if(!storeToFetch)
                storeToFetch = this.state.store;
    
            const response = await WoolSafeApi.Stand(storeToFetch.id);
            
            const store = (response && response.data) ? response.data.data : null;
    
            if(response && response.status === 200 && store) {
                this.setState({
                    store: store
                });
    
                // setTimeout(this.renderDescription, 100);
            }
    
            this.setState({ loading: false });
        }
    }

    renderDescription = () => {
        const { store } = this.state;
        const el = document.querySelector(".modal #stand-desc-"+store.id);

        if(el) {
            const reactElement = htmlToReactParser.parse(store.description);
            ReactDOM.render(reactElement, el);
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

        const {stand} = this.props;

        if(stand) {
            this.companyStyle = [];
            
            this.companyStyle["h1"] = { };
            this.companyStyle["h2"] = { };
            this.companyStyle["h3"] = { };
            this.companyStyle["hr"] = { };

            if(stand.color && stand.color.length > 0) {
                this.companyStyle["h1"]["color"] = stand.color;
                this.companyStyle["h2"]["color"] = stand.color;
                this.companyStyle["h3"]["color"] = stand.color;
                this.companyStyle["hr"]["borderColor"] = stand.color;
            }

            if(stand.active) {
                this.setState({
                    store: stand
                });
    
                this.fetchStore(stand);
            } else {
                this.setState({
                    disabled: true
                });
            }
        }
    }

    async componentWillReceiveProps(nextProps) {
        // if (nextProps.auth.user !== this.props.auth.user)
        //     this.fetchStoreAndEvents();

        if(nextProps.currentStand && nextProps.currentStand !== this.props.currentStand 
            && this.state.store && nextProps.currentStand.id === this.state.store.id) {
            await this.fetchStore();
        }
    }

    onReadMore = (view) => {
        const { store } = this.state;

        this.props.modal.setView(store.name, view, true);

        setTimeout(this.renderDescription, 100);
    }

    render() {
        const { store, loading, disabled, totemTab } = this.state;

        const websiteUrl = (store && store.website_url) ? store.website_url.replace("https://", "") : null;
        let description = "";

        if(store && store.description) {
            const d = store.description.replace(/(<([^>]+)>)/gi, "");

            if(d.indexOf(".") > 0)
                description = d.substr(0, d.indexOf(".") + 1);
        }

        const aboutUs = !store ? "" : (
            <div className="about-us">
                {!store.image && <Avatar className="avatar">{store.name.substr(0,1)}</Avatar> }
                {store.image && <img className="avatar" src={AppContext.s['api-url'] + "/stores/"+store.id+"/image"} />}
                {/* <Avatar variant="square" className="avatar" 
                    src={AppContext.s['api-url'] + "/stores/"+store.id+"/image"}>{store.name.substr(0,1)}</Avatar> */}

                {store.socials &&
                    <Row className="justify-content-center">
                        <Socials small dark socials={store.socials} />
                    </Row> }
                
                {websiteUrl &&
                    <a className="website text-center" href={store.website_url} target="_blank">{websiteUrl}</a>}

                <div id={"stand-desc-"+store.id} className="description">{description}</div>
            </div>);

        return (
            <div className="stand-container">
                <div className="stand">

                    {(!disabled && store) && 
                        <>
                            <div className="display-wall">
                                <div className="lamps">
                                    <div>
                                        <img src="assets/images/lamp.png" alt="lamp" />
                                    </div>

                                    <div>
                                        <img src="assets/images/lamp.png" alt="lamp" />
                                    </div>

                                    <div>
                                        <img src="assets/images/lamp.png" alt="lamp" />
                                    </div>
                                </div>

                                <div className="cover-container">
                                    <div className={(store.cover_orientation === 0 ? "horizontal" : "vertical") + " cover"}>
                                        {store.cover &&
                                            <img className="background" src={AppContext.s["api-url"] + "/stores/" + store.id + "/cover"} /> }
                                    </div>
                                </div>
                            </div>

                            {(store.assets && store.assets.length > 0) &&
                                <BookStand books={store.assets} enableSliding /> }

                            {store && store.zoom_url &&
                                <div className="above-totem">
                                    <div className="take-a-seat">
                                        <a href={store.zoom_url} target="_blank">
                                            Take a seat on<img src="/assets/images/zoom-logo.png" alt="Zoom" />
                                        </a>
                                    </div>
                                </div> }

                            <div className="totem">
                                <h1 style={this.companyStyle["h1"]}>{store.name}</h1>
                                
                                {(store.streams && store.streams.length > 0) &&
                                    <VideoGallery streams={store.streams} autoPlay column /> }

                                    <div className="tabs">
                                        {totemTab === 0 && <>
                                            {/* <h3 style={this.companyStyle["h3"]} className="our-products">Our Products</h3> */}
                                            <Products store={store} />
                                        </> }
                                        
                                        {totemTab === 1 && 
                                            <>
                                                {aboutUs}

                                                { description.length > 0 &&
                                                    <Button onClick={() => this.onReadMore(aboutUs)}>Read More</Button> }
                                            </> }

                                        {totemTab === 2 && <>
                                            <StoreContacts store={store} companyStyle={this.companyStyle} />
                                        </> }
                                    </div>

                                <Paper square>
                                    <Tabs className="tabs" centered indicatorColor="secondary"
                                        textColor="secondary" aria-label="icon label tabs example"
                                        value={this.state.totemTab}
                                        onChange={(event, newValue) => {
                                                this.setState({totemTab: newValue});
                                                if(newValue === 0)
                                                    setTimeout(this.renderDescription, 100);
                                            }}>
                                        {(store.products && store.products.length > 0) &&
                                            <Tab icon={<img className="tab-icon" src={AppContext.s["products-tab-icon"]} />} label="PRODUCTS" /> }
                                        <Tab icon={<HomeIcon className="tab-icon" />} label="ABOUT US" />
                                        {(store.contacts && store.contacts.length > 0) &&
                                            <Tab icon={<PersonPinIcon className="tab-icon" />} label="CONTACTS" /> }
                                    </Tabs>
                                </Paper>
                            </div>

                            <StoreTeam store={store} companyStyle={this.companyStyle} />
                        </> }

                    {disabled && <h1>This stand is not active.</h1>}

                    {loading && AppContext.r["preloader"]}
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(withModal(Stand));