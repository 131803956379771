export default function loadClientResources(resources) {
    resources["admin-portal"] = "WoolSafe Admin Portal";
    resources["like-us-desc"] = <>Follow us to keep up with<br/>our next events.</>;
    resources["must-be-logged-event"] = "You must be logged in to attend this event.";
    resources["info"] = <>
        <h1 className="text-center">Welcome to WoolSafe Expo</h1>
        <img className="info-desk" src="/assets/images/info-desk.jpg" alt="Info Desk" />
        <p className="text-center">Here you are gonna find some info.</p>
        <p className="text-center">Find and click the marker icons on the map to change your point of view.</p>
    </>;
    resources["company"] = "Company";
}