import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Product from './Product';

class ProductList extends Component {
    noResultsMessage = this.props.noResultsText ? this.props.noResultsText : <h4>{AppContext.r["no-results"]}</h4>;

    addToCart = (product) => {
        console.log("ADD TO CART", product);
        this.props.addToCart(product);
    }

    componentWillReceiveProps(nextProps) {
        // if(nextProps.store !== this.props.store) {
        //     this.props.updateProducts(null, nextProps.store.products);
        // }
    }

    render() {
        const products = this.props.products;

        if(!products)
            return (
                <div className="preloader-box">
                    {AppContext.r["preloader"]}
                </div>);

        const items = !products ? [] : products.map(product => {
            return (<Product key={product.id} product={product} onClickProduct={this.props.onClickProduct}
                addToCart={this.addToCart} enableCart={false}
                inCart={this.props.cart.length > 0 && this.props.cart.filter(e => e.product.id === product.id).length > 0 } />);
        });

        return (
            <div className="product-list">
                { items.length > 0 ? items : this.noResultsMessage }
                {/* { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage }
                { items.length > 0 ? items : this.noResultsMessage } */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products.entities,
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};

export default connect(mapStateToProps, null)(ProductList);