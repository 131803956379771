import React from 'react';

export default function loadClientResources (resources) {
    resources["who-we-are"] = "Chi Siamo";
    resources["our-services"] = "I Nostri Servizi";
    resources["work-with-us"] = <><s>Lavora</s> Gioca Con Noi</>;
    resources['next-step'] = "Prossimo Step";
    resources["your-name"] = "Il tuo Nome";
    resources["order-to-table"] = "Ordine al tavolo";
    resources["click-and-collect"] = "Take Away";
    resources["your-memberships"] ="Le tue affiliazioni";
    resources["memberships"] ="Affiliazioni";
}