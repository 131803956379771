import React from 'react';

export default function loadClientResources (resources) {
    resources["remove"] = "Remove";
    resources["add-to-cart"] = "Add to Cart";
    resources["checkout"] = "Checkout";
    resources["empty-cart"] = "The cart is empty.";
    resources["my-shopping-cart"] = "My Order";
    resources["shop"] = "Shop";
    resources["shops"] = "Shops";
    resources["shop-item-categories"] = "Categories";
    resources["shop-item-category"] = "Category";
    resources["shop-item-sub-category"] = "Sub-Category";
    resources["shop-item-sub-categories"] = "Sub-Categories";
    resources["highlighted"] = "Highlighted";
    resources["percent-discount"] = "% Discount";
    resources["placed-at"] = "Placed At";
    resources["requested-date"] = "Requested Date";
    resources["requested-date-time"] = "Requested Date and Time";
    resources["order"] = "Order";
    resources["orders"] = "Orders";
    resources["order-details"] = "Order Details";
    resources["brand"] = "Brand";
    resources["shop-item-brand"] = "Brand";
    resources["shop-item-brands"] = "Brands";
    resources["customer"] = "Customer";
    resources["customers"] = "Customers";
    resources["pending-orders"] = "Pending Orders";
    resources["no-pending-orders"] = "There are no pending orders.";
    resources["payment"] = "Payment";
    resources["order-to-table"] = "Order To Table";
    resources["delivery"] = "Delivery";
    resources["takeaway"] = "Take Away";
    resources["order-placed"] = <>
        <p>Thank you! Your order has been placed.</p>
        <p>A confirmation has been sent to your registered email, please check your spam folder if you can’t see it.</p>
        <p>You can view all of your tickets in your Grand Expos account, just click on the icon with your initials in the top left corner of the screen.</p>
    </>;
    resources["your-addresses"] = "Your Addresses";
    resources["no-addresses"] = "You haven't inserted any address yet.";
    resources["your-orders"] = "Your Orders";
    resources["no-orders"] = "No orders here :(.";
    resources["deliver-to"] = "Deliver to";
    resources["confirm-delete-order"] = "Are you sure you want to cancel this order?";
    resources["confirm-delete-order-heading"] = "Delete Order";
    resources["cannot-delete-order"] = (
        <div>
            <p>Your order has already been processing and it cannot be cancelled automatically or we got an internal error.</p>
            <p>Get in touch with us by phone or e-mail please.</p>
        </div>);
    resources["specify-order-type"] = "Please specify the order type.";
    resources["specify-requested-date-time"] = "Specify requested date and time for collection.";
    resources["store-closed-message"] = (
        <>
            <h3>We are closed today.</h3>
            <p className="text-center">Check the calendar for the next available date for collection.</p>
        </>
    );
    resources["allow-newsletter"] = "Would you like to be informed about news and promotions from us or our close partners?"
    resources["account-verified"] = "Perfect! Your account has been verified.";
    resources["my-orders"] = "Your Orders";

    resources["payment-types"] = [];
    resources["payment-types"][2] = [
        "",
        "Bank Transfer",
        "Paypal",
        "Cash on delivery",
        "Card over the phone",
    ];
    resources["payment-types"][3] = [
        "",
        "Bank Transfer",
        "Paypal",
        "Cash on collection",
        "Card over the phone",
    ];
    resources['min-order-is'] = "The minimum order is ";

    resources["no-results"] = "No items have been found.";
    resources["reviews"] = "Reviews";
    resources["pay"] = "Pay";
    resources["pay-by-card"] = "Pay by card";
    resources["delete-payment-method-confirm"] = "Would you like to delete this payment method?";
    resources["pay-by-new-card"] = "Pay with another card";
}