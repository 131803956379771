import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';

import AppContext from 'app/AppContext';

export default class StoreTeam extends React.Component {
    state = {
        member: null
    };

    render() {
        const { member } = this.state;
        const { store } = this.props;

        return (store && store.team && store.team.length > 0) ? (
            <div className="team">
                <h3 style={this.props.companyStyle["h3"]}>Meet The Team</h3>

                { member && (
                    <Popover id={member.id}
                        anchorEl={this.state.anchorEl}
                        open={this.state.popoverOpen}
                        onClose={() => { this.setState({popoverOpen: false}); }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left', }}>

                        <Paper>
                            <Row className="business-card">
                                <Col xs={3} sm={4}>
                                    <Avatar className="avatar" src={AppContext.s["api-url"] + "/stores/team/" + this.state.member.id + "/image/thumb"}>{this.state.member.name.substr(0,1)}</Avatar>
                                </Col>

                                <Col xs={9} sm={8} className="profile">
                                    <h1>{member.name}</h1>
                                    <h2 style={this.props.companyStyle["h1"]}>{member.title}</h2>

                                    <div className="contacts">
                                        { member.email && 
                                            <div className="contact">
                                                <div className="icon"><MailOutlineIcon /></div>
                                                <div className="field"><a href={"mailto:"+member.email}>{member.email}</a></div>
                                            </div> }
                                        { member.phone && 
                                            <div className="contact">
                                                <div className="icon"><PhoneIcon /></div>
                                                <div className="field"><a href={"tel:"+member.phone}>{member.phone}</a></div>
                                            </div> }

                                        { member.linkedin_url && 
                                            <div className="social contact">
                                                <div className="icon"><a href={member.linkedin_url} target="_blank"><LinkedInIcon /></a></div>
                                            </div> }

                                        { member.twitter_url && 
                                            <div className="social contact">
                                                <div className="icon"><a href={member.twitter_url} target="_blank"><TwitterIcon /></a></div>
                                            </div> }

                                        { member.facebook_url && 
                                            <div className="social contact">
                                                <div className="icon"><a href={member.facebook_url} target="_blank"><FacebookIcon /></a></div>
                                            </div> }

                                        { member.instagram_url && 
                                            <div className="social contact">
                                                <div className="icon"><a href={member.instagram_url} target="_blank"><InstagramIcon /></a></div>
                                            </div> }
                                    </div>

                                    <p>{member.description}</p>
                                </Col>
                            </Row>
                        </Paper>
                    </Popover>)
                }
                
                <div className="members">
                    {store.team.map(member => (
                        <div className="member" aria-describedby={member.id} key={member.id}
                            onClick={(e) => {
                                this.setState({
                                    popoverOpen: !this.state.popoverOpen,
                                    anchorEl: e.currentTarget,
                                    member: member
                                });
                            }}>

                            <Avatar className="avatar" src={AppContext.s["api-url"] + "/stores/team/" + member.id + "/image/thumb"}>{member.name.substr(0,1)}</Avatar>
                        </div>)
                    )}
                 
                </div>
            </div>) : "";
    }
}