import React from 'react';

export default function loadClientResources (resources) {
    resources["who-we-are"] = "Who We Are";
    resources["work-with-us"] = <><s>Work</s> Play With Us</>;
    resources["next-step"] = "Next Step";
    resources["your-name"] = "Your Name";
    resources["order-to-table"] = "Order to table";
    resources["click-and-collect"] = "Click & Collect";
    resources["your-memberships"] ="Your memberships";
    resources["memberships"] ="Memberships";
}