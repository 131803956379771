import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AppContext from '../app/AppContext';
import StripeCheckout from "framework/StripeCheckout";
import WoolSafeApi from "api/WoolSafe";

let yup = require('yup');

export class EventAttendanceStatusesEnum {
    static get interested() { return 1; }
    static get paid() { return 2; }
    static get paymentIntent() { return 3; }
    static get refunded() { return 4; }
}

class BuyTicket extends React.Component {
    state = {
        attendance: null,
        isCheckoutVoucherCollapsed: true,
        voucher_code: null,
        total: null,
        submittingVoucher: null
    };

    fee = 0.50; // Boooking Fee

    onCheckedOut = () => {
        this.props.modal.setFree();
        this.props.modal.hide();
    }

    onResetVoucher = () => {
        this.props.modal.setFree();
    }

    checkout = () => {
        const modalMessage = (
            <div className="checked-out-message">
                <i className="far fa-check-circle icon"></i>
                <p className="message">{AppContext.r["order-placed"]}</p>
                <Button onClick={this.onCheckedOut}>{AppContext.r['close']}</Button>
            </div>);

        this.props.modal.setMessage(modalMessage, false);
    }

    fetchAttendance = async () => {
        const response = await WoolSafeApi.AttendEvent(this.props.event, EventAttendanceStatusesEnum.paymentIntent);
        // console.log(response);

        if(response && response.status === 200) {
            const attendance = response.data;

            this.setState({
                attendance: attendance
            });
        }
    }

    async componentDidMount() {
        const { event, store } = this.props;
        const { user } = this.props.auth;
        const { voucher_code } = this.state;

        if(user) {
            await this.fetchAttendance();
        }

        const key = store.stripe_test === 1 ? AppContext.s["test-stripe-key"] : AppContext.s["stripe-key"];

        // Calc total. Apply VAT and deduct discount

        this.setState({
            promise: loadStripe(key/*, {
                stripeAccount: store.stripe_id
              }*/),
            total: (event.price + this.fee - (voucher_code ? ((event.price * voucher_code.amount_perc / 100) - voucher_code.amount) : 0)) * 1.2
            
        })
    }
        
    async componentWillReceiveProps(nextProps) {
        if(!this.state.attendance)
            await this.fetchAttendance();
        console.log(nextProps);
        // this.setState({ email: nextProps.email });
    }

    applyDiscountCode = async (values) => {
        this.setState({
            submittingVoucher: true
        });

        const { event } = this.props;
        const { attendance } = this.state;
        console.log("applyDiscountCode", values);
        const response = await WoolSafeApi.ApplyDiscountCode(attendance, values.discountCode);
        console.log(response);

        let message = null;

        if(response.status === 410) {
            message = AppContext.r["voucher-used"];
        } else if(response.status !== 202) {
            message = AppContext.r["voucher-not-valid"];
        }

        // Free ticket, just congratulate and close the dialog!
        if(message || response.data.amount === 100) {
            const modalMessage = message ? (
                <>
                    <i className="icon far fa-frown"></i>
                    <p className="message">{message}</p>
                    <Button onClick={this.onResetVoucher}>{AppContext.r['close']}</Button>
                </>) : (
                <>
                    <i className="icon far fa-check-circle"></i>
                    <p className="message">{AppContext.r["got-free-ticket"]}</p>
                    <Button onClick={this.onCheckedOut}>{AppContext.r['close']}</Button>
                </>);

            this.props.modal.setMessage(modalMessage, false);
        }

        // Discount codes
        if(response.data) {
            const voucher_code = response.data;

            const s = {
                submittingVoucher: false
            };

            // If accepted
            if(response.status === 202) {
                s.voucher_code = response.data;
                s.total = ((event.price - voucher_code.amount) + this.fee - (voucher_code ? (((event.price - voucher_code.amount) * voucher_code.amount_perc / 100)) : 0)) * 1.2;
            }

            this.setState(s);
        }
    }

    render() {
        const { event, store } = this.props;
        const { user } = this.props.auth;
        const { total, attendance, isCheckoutVoucherCollapsed, voucher_code } = this.state;

        // const fee = 0.50; // Boooking Fee
        // Calc total. Apply VAT and deduct discount
        // const total = (event.price + fee - (voucher_code ? (event.price * voucher_code.amount / 100) : 0)) * 1.2; 

        let view = (
            <Row>
                <Col xs={12} className="display-flex justify-content-center">
                    <p>{AppContext.r["must-be-logged"]}</p>
                </Col>

                <Col xs={12} className="display-flex justify-content-center align-items-center">
                    <Button onClick={this.props.authActions.onRegister}>{AppContext.r["signup"]}</Button>{AppContext.r["or"]}<Button onClick={this.props.authActions.onLogin}>{AppContext.r["login"]}</Button>
                </Col>
            </Row>
        );

        if (user && !attendance)
            view = AppContext.r["preloader"];

        if(user && attendance) {
            const request = {
                event: event.id,
                quantity: 1,
                event_attendance_id: attendance.id
            }
    
            const imageUrl = AppContext.s['api-url'] + "/events/"+event.id+"/image";

            const { currency_symbol } = store.locale;

            view = (
                <>
                    <div className="image-box">
                        {event.image && 
                            <CardMedia component="img" alt={event.title} height="140"
                                image={imageUrl}
                                title={event.title} /> }
                    </div>

                    {(attendance.status_id === EventAttendanceStatusesEnum.paid) ?
                        <h3 className="text-center">You already own this ticket.</h3>
                        : <>
                            {/* <p className="price">{currency_symbol + " " + event.price.toFixed(2) + " + Booking Fee " + currency_symbol + " 0.50 + VAT (" + currency_symbol + " " + total + ")" }</p> */}
                            {total && 
                                <p className="price">{`${currency_symbol} ${event.price.toFixed(2)} + Booking Fee ${currency_symbol}  0.50 + VAT (${currency_symbol} ${total.toFixed(2)})`}</p> }
                            
                            {/* Apply Discount Code */}
                            {!voucher_code &&
                                <>
                                    <div className="toggle-voucher-checkout" onClick={() => {
                                            this.setState({ isCheckoutVoucherCollapsed: !isCheckoutVoucherCollapsed });
                                        }}>
                                        {AppContext.r["have-voucher"]}
                                        {!isCheckoutVoucherCollapsed ? <ExpandLess /> : <ExpandMore />}
                                    </div>

                                    <Collapse in={!isCheckoutVoucherCollapsed} timeout="auto" unmountOnExit>
                                        <Formik
                                            initialValues={{discountCode: ""}}
                                            validationSchema={yup.object({
                                                discountCode: yup.string().required().min(6)
                                            })}
                                            onSubmit={this.applyDiscountCode}>

                                            {({ isSubmitting }) => (
                                                <Form className="form">
                                                    <div className="voucher-application">
                                                        <Field className="field" component={TextField} name="discountCode" type="text"
                                                            label={AppContext.r["voucher"]} />
                                                        <Button type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["apply"]}</Button>
                                                    </div>
                                                                                    
                                                    {isSubmitting && <LinearProgress />}
                                                    <br />
                                                </Form>
                                            )}
                                        </Formik>
                                    </Collapse>
                                </> }

                            {voucher_code && 
                                <p className="voucher-applied">VOUCHER {voucher_code.title}</p>}

                            {(voucher_code && voucher_code.amount) ? 
                                <p className="voucher-value">{` £ ${voucher_code.amount} OFF`}</p> : "" }

                            {(voucher_code && voucher_code.amount_perc) ?
                                <p className="voucher-value">{`${voucher_code.amount_perc} % OFF`}</p> : "" }
                            <hr />
                            
                            {store.stripe_id && this.state.promise && !this.state.submittingVoucher &&
                                <div className="stripe">
                                    <Elements stripe={this.state.promise}>
                                        <StripeCheckout testMode={store.stripe_test} user={user}
                                            code={voucher_code ? voucher_code.title : null} request={request}
                                            amount={AppContext.s['currency'] + " " + total.toFixed(2)}
                                            onSucceeded={this.checkout} />
                                    </Elements>
                                </div>}
                        </> }
                </>
            )
        }
        
        return (
            <div className="ticket-card">
                <h2>{event.title}</h2>

                {view}
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps, null)(BuyTicket);