import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export default class Counter extends React.Component {
    state = {
        value: this.props.value ? this.props.value : 1
    }

    onIncrease = (e) => {
        if(e)
            e.stopPropagation();

        this.setState({
            value: this.state.value + 1
        });

        if(this.props.onIncrease)
            this.props.onIncrease(this.state.value);
    }

    onDecrease = (e) => {
        if(e)
            e.stopPropagation();
            
        if(this.state.value > 0)
            this.setState({
                value: this.state.value - 1
            });

        if(this.props.onDecrease)
            this.props.onDecrease(this.state.value);
    }

    componentWillReceiveProps(nextProps) {
        // Any time props.email changes, update state.
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    render() {
        return (
            <div className="counter">
                { !this.props.disableDecrease && 
                    <IconButton aria-label="delete" onClick={this.onDecrease}>
                        <RemoveCircleIcon className="primary-color" />
                    </IconButton> }

                <span className="value">{this.state.value}</span>

                { !this.props.disableEncrease && 
                    <IconButton aria-label="add" onClick={this.onIncrease}>
                        <AddCircleIcon className="primary-color" />
                    </IconButton> }
            </div>
        );
    }
};