import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EventIcon from '@material-ui/icons/Event';
import Tooltip from '@material-ui/core/Tooltip';

import AppContext from 'app/AppContext';
import WoolSafeApi from 'api/WoolSafe';
import { withModal } from '../framework/Modal';
import Events from './EventCards';

class EventsNotification extends React.Component {
    state = {
        tooltipOpen: false
    };

    async componentDidMount() {
        const upcomingEventsResponse = await WoolSafeApi.Events(1, 1);

        if(upcomingEventsResponse && upcomingEventsResponse.status === 200) {
            const events = upcomingEventsResponse.data.data;

            this.setState({
                upcomingEvents: events
            });

            if(events.length > 0 && window.innerWidth > 1000)
                setTimeout(() => this.setState({tooltipOpen: true}), 1500);
        }
    }

    toggleEventsModal = () => {
        const { upcomingEvents } = this.state;

        AppContext.history.push("/events");
        // this.props.modal.setView(AppContext.r["upcoming-events"], 
        //     <Events events={upcomingEvents} modal={this.props.modal} />, true, "woolsafe-wide");
    }

    render() {
        return (
            <Tooltip arrow placement="right"
                open={this.state.tooltipOpen} 
                onClose={() => this.setState({tooltipOpen: false})} 
                onOpen={() => this.setState({tooltipOpen: true})} 
                title="Check out our events">
                <div className="events-notification" onClick={this.toggleEventsModal}>
                    <IconButton color="primary" aria-label="Events">
                        <EventIcon />
                    </IconButton>
                </div>
            </Tooltip>);
    }
}

export default withModal(EventsNotification);