import * as THREE from "three";

import AppContext from "app/AppContext";
// import { ThreeDRotation } from "@material-ui/icons";
// import { RGB_PVRTC_2BPPV1_Format, Vector3 } from "three";

export class StandSizesEnum {
    static get s () { return "s"; }
    static get m () { return "m"; }
}

function hsl(h, s, l) {
    return (new THREE.Color()).setHSL(h, s, l);
}

export default class Stand {
    stand = null; // Stand Data Object
    group = null;
    object = null;
    logo = null;
    cover = null;
    text = null;
    interval = null;
    boundingBox = null;

    static dimensions = {
        "s": { x: 8, y: 4, z: 6 },
        "m": { x: 8, y: 4, z: 7.4 }
    };

    static material = new THREE.MeshLambertMaterial( {
        color: 0xeeeeee
    });

    static geometries = {
        "m": new THREE.BoxGeometry( Stand.dimensions.m.x, Stand.dimensions.m.y, Stand.dimensions.m.z )
    };

    constructor(group, onStandLoaded = null) {
        this.group = group;
        this.onStandLoaded = onStandLoaded;
    }

    get uuid () {
        if(this.boundingBox)
            return this.boundingBox.uuid;

        if(!this.object)
            return null;
            
        return this.object.uuid;
    }

    onOver = () => {
        // console.log(this.stand)
        this.highlight();
    }

    // onClick = () => {
    //     alert("Click")
    // }

    highlight = (active = true) => {
        // console.log( this.stand)
        this.object.material = active ? new THREE.MeshLambertMaterial({ color: 0x0000dd }) : this.material;

        if(this.logo) {
            this.logo.visible = active;

            if(active) {
                this.interval = setInterval(() => this.logo.rotation.y += 0.1, 100);
            } else {
                if(this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                }
            }
        }
    }

    generateLogo = (position, yOffset = 3) => {
        const placeholder = '/assets/textures/woolsafe.png';

        if(this.stand.active) {
            const imageUrl = (this.stand && this.stand.image) ? (AppContext.s["api-url"] + "/stores/" + this.stand.id + "/thumb") : placeholder;

            const textureBack = new THREE.TextureLoader().load(imageUrl);
            const textureFront = new THREE.TextureLoader().load(imageUrl);
            textureFront.flipY = false;
            
            textureBack.wrapS = THREE.RepeatWrapping;
            textureBack.repeat.x = - 1;
    
            const materials = [
                new THREE.MeshBasicMaterial( { color: 0xffffff } ),  // side
                new THREE.MeshBasicMaterial( { map: textureBack } ), // back
                new THREE.MeshBasicMaterial( { map: textureFront } ) // front
            ];
            const geometry = new THREE.CylinderGeometry( 4, 4, 0.5, 32 );
    
            this.logo = new THREE.Mesh(geometry, materials);
            this.logo.position.x = position.x;
            this.logo.position.y = position.y + yOffset;
            this.logo.position.z = position.z;
            this.logo.rotation.x = 0;
            this.logo.rotation.y = 0;
            this.logo.rotation.z = -Math.PI/2;
            this.logo.visible = false;
    
            this.logo.scale.x = -1;
        }
    }

    generateText = (position = null) => {
        let textMesh1, textGeo, materials;
    
        let text = (this.stand && this.stand.active) ? this.stand.name : ""; // Stand x
        if(text.length > 10)
            text = text.substr(0, 10) + "...";

        const height = 0.1,
            size = 0.8,
            curveSegments = 1;

        const loader = new THREE.FontLoader();

        loader.load( 'assets/fonts/helvetiker_regular.typeface.json', (font) => {
            textGeo = new THREE.TextGeometry( text, {
                font: font,
                size: size,
                height: height,
                curveSegments: curveSegments,
            } );

            textGeo.computeBoundingBox();

            const centerOffset = 0.5 * ( textGeo.boundingBox.max.x - textGeo.boundingBox.min.x );

            textMesh1 = new THREE.Mesh( textGeo, materials );

            textMesh1.rotation.x = Math.PI/2;
            textMesh1.rotation.y = -Math.PI;
            textMesh1.rotation.z = -Math.PI/2;

            this.text = textMesh1;

            if(position) {
                this.text.position.x = position.x;
                this.text.position.y = position.y + Stand.dimensions.m.y/2;
                this.text.position.z = centerOffset + position.z;
            }

            if(this.onStandLoaded)
                this.onStandLoaded(this);
        });
    }

    generateFromModel = (model, yOffset = 2) => {
        this.stand = model.data;

        // console.log(this.stand);

        this.object = model.object;

        // Bounding Box
        
        //const { geometries } = Stand;
        // const geometry = geometries.m;
        const geometry = new THREE.CylinderGeometry( 9, 9, 5, 32 );

        var cube = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({
            color: new THREE.Color(0xaa0000),//this.stand.color ? new THREE.Color(this.stand.color) : hsl(0 / 8, 1, .2),
            opacity: 0.0,
            transparent: true,
        }));

        cube.scale.x = 1;
        cube.scale.y = 3;
        cube.scale.z = 1;
        cube.position.x = model.object.position.x;
        cube.position.y = model.object.position.y + 7;
        cube.position.z = model.object.position.z;

        this.boundingBox = cube;

        const p = {
            x: cube.position.x,
            y: cube.position.y + 7,
            z: cube.position.z,
        }

        this.generateLogo(p, yOffset);
    }

    generateCover = (position, side = 0) => {
        const placeholder = '/assets/textures/woolsafe.png';
        const { stand } = this;

        if(stand.active) {
            const imageUrl = (stand && stand.cover) ? (AppContext.s["api-url"] + "/stores/" + stand.id + "/cover/mini") : placeholder;
            const isRounded = stand.cover ? false : true; // Rounded geometry for placeholder

            // const textureBack = new THREE.TextureLoader().load(imageUrl);
            const textureFront = new THREE.TextureLoader().load(imageUrl);
            textureFront.flipY = false;
            
            // textureBack.wrapS = THREE.RepeatWrapping;
            // textureBack.repeat.x = - 1;
    
            // const materials = [
            //     new THREE.MeshBasicMaterial( { color: 0xffffff } ),  // side
            //     new THREE.MeshBasicMaterial( { map: textureBack } ), // back
            //     new THREE.MeshBasicMaterial( { map: textureFront } ) // front
            // ];
            
            const geometry = isRounded ? new THREE.CylinderGeometry(2, 2, 0.01, 16) : new THREE.PlaneGeometry(4, 4, 1, 1);
    
            this.cover = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial( { map: textureFront } ));
            this.cover.position.x = position.x + (Stand.dimensions.m.x / 2 + 0.1);
            this.cover.position.y = position.y;
            this.cover.position.z = position.z;
            this.cover.rotation.x = 0;
            if(isRounded) {
                this.cover.rotation.y = Math.PI;
                this.cover.rotation.z = Math.PI/2;
            } else {
                this.cover.rotation.y = Math.PI/2;
                this.cover.rotation.z = Math.PI;
            }
            
            this.cover.scale.x = -1;

            this.cover.visible = true;

            // Right side
            if(side === 1) {
                this.cover.position.x = position.x - (Stand.dimensions.m.x / 2 + 0.1);

                if(isRounded)
                    this.cover.rotation.y = 0;
                else
                    this.cover.rotation.y = -Math.PI/2;
            }
        }
    }

    generate = (size = "m", position = null, stand = null, side = 0) => {
        this.stand = stand;
        //console.log(stand);

        const { geometries } = Stand;
        const geometry = geometries.m;

        this.material = (stand && stand.category && stand.category.color && stand.active) ?
            new THREE.MeshLambertMaterial({
                color: new THREE.Color(stand.category.color)
            }) : Stand.material;
    
        const cube = new THREE.Mesh(geometry, this.material);
        if(position) {
            cube.position.x = position.x;
            cube.position.y = position.y;
            cube.position.z = position.z;
        }

        this.object = cube;

        const p = {
            x: position.x,
            y: position.y,
            z: position.z
        };

        if(stand) {
            this.generateLogo(p, 8);
            this.generateText(p);
            this.generateCover(p, side);
        } else {
            if(this.onStandLoaded)
                this.onStandLoaded(this);
        }
        
        return cube;
    }
}