import React from 'react';

import Settings from '../../settings';

export default function loadResources (resources) {
    resources['first-name'] = "First Name";
    resources['about-you-desc'] = "Something About YOU";
    resources['hello'] = "Hello";
    resources['signup'] = "Sign Up";
    resources["dont-you-have-an-account"] = "Don't you have an account?";
    resources['choose-nickname'] = "Choose a nickname";
    resources['access-with-facebook'] = "Access with Facebook";
    resources['or-signup-via-email'] = "or sign up via e-mail";
    resources['or-signin-via-email'] = "or sign in via e-mail";
    resources['invalid-email'] = "Email is invalid.";
    resources['invalid-name'] = "Name is invalid.";
    resources['name-required'] = "Name is required.";
    resources['surname-required'] = "Surname is required.";
    resources['email-required'] = "Email is required.";
    resources['password-min-length'] = "Password must be at least " + Settings["password-min-length"] + " characters.";
    resources['password-required'] = "Password is required.";
    resources['password-must-math'] = "Passwords must match.";
    resources['phone-not-valid'] = "Phone number not valid.";
    resources['confirm-password-required'] = "Confirm the password.";
    resources['confirm-password'] = "Confirm the password";
    resources['login'] = "Log In";
    resources['logout'] = "Log Out";
    resources["forgot-password"] = "I forgot my password";
    resources["l-wrong-credentials"] = "The credentials you have inserted are not valid. Please check your e-mail and password and try again to log in.";
    resources['account-created'] = <div><p>Awesome. Your {Settings["project-name"]} account has successfully been created.</p><p>Welcome to <span>{Settings["project-name"]}</span>.</p></div>
    resources['account-not-created'] = <div><p>Something went wrong creating your account. The provided e-mail may already been registed to {Settings["project-name"]}. Try to log in or specify a different e-mail.</p></div>
    resources["something-wrong"] = "Something went wrong. If you cannot fix it, try and get in touch with us.";
    resources["social-login-failed"] = <><p>Something went wrong logging in by your social account.</p><p>We couldn't retrieve a valid associated email.</p></>;
    resources["server-not-working"] = "The server did not respond correctly. Try again in a bit or contact the administration.";
    resources["change-password"] = "Change Password";
    resources["delete-account"] = "Delete Account";
    resources["delete-account-desc"] = (<div>
        <p>This will <b>permanently</b> delete your account and all the related information.</p>
        <p>To confirm you wanna go ahead with this operation, type your password and click on "{resources["delete-account"]}" button.</p>
        <p>All your data will be removed from our servers pursuant to GDPR regulations and <b>won't be recoverable</b>.</p>
    </div>);
    resources["old-password"] = "Old Password";
    resources["new-password"] = "New Password";
    resources["new-password-confirmation"] = "Repeat New Password";
    resources["all-fields-required"] = "All the fields are required.";
    resources["password-desc"] = "The new password must be at least " + Settings["password-min-length"] + " characters long and must be typed twice.";
    resources["wrong-password"] = "The password you have inserted is not valid.";
    resources["passwords-must-match"] = "Passwords must match.";
    resources["sorry-you-leave"] = <div>
        <p><i className="far fa-frown"></i> We are sorry to see you leaving.</p>
        <p>Should you decide to sign up for another account we will be there for you.</p>
    </div>;
    resources["must-be-logged"] = "You must be logged to access this section.";
    resources['you-are-not-logged-in'] = "You are not logged in. Sign in or sign up now.";
    resources["my-account"] = "Your Account";
    resources["status"] = "Status";
    resources["account-deleted"] = "Your account has been deleted. Feel free to sign up again any time you want.";
    resources["password-forgotten"] = "Have you forgotten your password?";
    resources["request-reset-password"] = "Request Reset Password";
    resources["reset-password"] = "Reset Password";
    resources["password-reset-requested"] = <div>
        <b className="primary-color">Check your mail!</b><br/>
        <p>You have requested to reset your password. If the e-mail you entered is valid you will receive further instructions, which may take a few minutes.</p>
        <p>If you have not received anything, please check the spam folder.</p>
    </div>;
    resources["unprocessable-reset"] = "It couldn't be possible to reset your password. Please check you have inserted a valid e-mail or request again the reset password e-mail";
    resources["password-resetted"] = "Your password has been successfully reset.";
}